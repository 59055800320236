import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Last = (props) => (
  <SvgIcon {...props} viewBox="0 0 12 14">
    <svg width={12} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.038 6.222a1 1 0 0 1 0 1.556l-4.91 3.968a1 1 0 0 1-1.628-.778V3.032a1 1 0 0 1 1.629-.778l4.909 3.968Z"
        fill="currentColor"
      />
      <rect x={10} y={2} width={2} height={10} rx={1} fill="currentColor" />
    </svg>
  </SvgIcon>
);

export default Last;
