import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LoadingIcon from 'assets/icons/animated/loading';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  root: {
    color: ({ color }) => theme.palette[color] && theme.palette[color].main,
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: ({ color }) =>
        theme.palette[color] && theme.palette[color].light,
      color: ({ color }) => theme.palette[color] && theme.palette[color].main,
    },
  },
}));

const TertiaryButton = styled(
  ({
    className,
    children,
    color,
    loading = false,
    icon,
    size = 'medium',
    ...props
  }) => {
    const classes = useStyles({ color });
    if (color === 'primary' || color === 'secondary') {
      return (
        <Button
          variant="text"
          size={size}
          {...props}
          startIcon={loading ? <LoadingIcon /> : icon}
          className={className}
          color={color}
        >
          {children}
        </Button>
      );
    }
    return (
      <Button
        variant="text"
        classes={{
          root: classes.root,
        }}
        size={size}
        {...props}
        startIcon={loading ? <LoadingIcon /> : icon}
        className={className}
      >
        {children}
      </Button>
    );
  }
)``;

export default TertiaryButton;
