import React, { useState } from 'react';

import loadable from '@loadable/component';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PolygonIcon from 'assets/icons/misc/menu_polygon';
import clsx from 'clsx';
import { SmallTitle } from 'components/atoms';
import { AnimatePresence, motion } from 'framer-motion';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '0.5rem',
    ...theme.nav.mainButton.idle,
  },
  open: {
    ...theme.nav.mainButton.active,
  },
  active: {
    '&::before': {
      content: '""',
      backgroundColor: theme.palette.primary.main,
      width: '0.4rem',
      height: '0.4rem',
      position: 'absolute',
      left: '1rem',
      borderRadius: '50%',
    },
  },
  label: {
    padding: '3.4rem 4rem 3rem 4.1rem',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    height: '2.4rem',
    '& > p': {
      textTransform: 'uppercase',
      fontSize: '1.4rem',
    },
  },
  icon: {
    '& svg': {
      width: '2.4rem',
      height: '2.4rem',
    },
  },
  arrowIcon: {
    position: 'absolute',
    bottom: '0.6rem',
    width: '0.6rem',
    height: '0.5rem',
    color: theme.palette.shades.primary[300],
  },
}));

const Icon = loadable((props) => import(`assets/icons/${props.path}`), {
  cacheKey: (props) => props.path,
});

const ExpandIcon = motion(PolygonIcon);

const animationsHelper = {
  quickFadeOut: {
    scale: 0,
    zIndex: 0,
    opacity: 0,
    transition: {
      type: 'linear',
      duration: 0.1,
    },
  },
};

const MainMenuButton = ({
  isActive,
  isOpen,
  onMouseEnter,
  onClick,
  ...item
}) => {
  const { icon, label, disabled, id } = item;
  const classes = useStyles();
  const [isHovering, setIsHovering] = useState(false);
  return (
    <Button
      className={clsx(isOpen && classes.open, isActive && classes.active)}
      startIcon={<Icon path={icon} />}
      fullWidth
      classes={{
        root: classes.button,
        label: classes.label,
        startIcon: classes.icon,
      }}
      onClick={() => {
        if (onClick) onClick(item);
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      disabled={disabled}
      id={`${id}-menu-button`}
    >
      <SmallTitle>{label}</SmallTitle>
      <AnimatePresence>
        {isOpen && (
          <ExpandIcon
            key="expand-less-icon"
            initial={{ rotate: '-180deg', position: 'absolute' }}
            animate={{ rotate: 0, zIndex: 1 }}
            exit="quickFadeOut"
            variants={animationsHelper}
            transition={{ type: 'linear' }}
            className={classes.arrowIcon}
          />
        )}
        {isHovering && !isOpen && (
          <ExpandIcon
            key="expand-more-icon"
            initial={{
              rotate: '-180deg',
              y: -10,
              opacity: 0,
              position: 'absolute',
            }}
            animate={{ y: 0, opacity: 1, zIndex: 1 }}
            exit="quickFadeOut"
            transition={{ type: 'spring' }}
            className={classes.arrowIcon}
            variants={animationsHelper}
          />
        )}
      </AnimatePresence>
    </Button>
  );
};

export default MainMenuButton;
