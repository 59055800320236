import React from 'react';

import { VDX_CORE_API_ENVIRONMENTS } from 'consts';
import {
  TEAM_DASHBOARD_ROUTE,
  TEAM_SUBSCRIPTIONS_ROUTE,
  TEAM_SUBSCRIPTION_PAYMENT_ROUTE,
  TEAM_SUBSCRIPTION_RETRY_PAYMENT_ROUTE,
  TEAM_MANAGE_ROUTE,
  TEAM_PAYMENTS_ROUTE,
  TEAM_PAYMENTS_HISTORY_ROUTE,
  TEAM_PAYMENTS_PENDING_ROUTE,
  TEAM_PAYMENTS_PENDING_RETRY_ROUTE,
  TEAM_PAYMENTS_UPCOMING_ROUTE,
  TEAM_PAYMENT_ID_ROUTE,
  SERVICES_VDX_CORE_API_DASHBOARD_ROUTE,
  SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES,
  SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES,
  RESOURCES_FAQ_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  ACCOUNT_TEAM_CREATE_ROUTE,
} from 'routes/paths';

const loadable = (loader) => React.lazy(loader);

const TEAM_DASHBOARD_COMP = {
  key: 'team/dashboard',
  path: TEAM_DASHBOARD_ROUTE,
  Component: loadable(() => import('pages/team/dashboard')),
  exact: true,
};
const TEAM_SUBSCRIPTIONS_COMP = {
  key: 'team/subscriptions',
  path: TEAM_SUBSCRIPTIONS_ROUTE,
  Component: loadable(() => import('pages/team/subscriptions')),
  exact: true,
};
const TEAM_SUBSCRIPTION_PAYMENT_COMP = {
  key: 'team/subscriptions/payment',
  path: TEAM_SUBSCRIPTION_PAYMENT_ROUTE,
  Component: loadable(() =>
    import('pages/team/subscriptions/payment/SubscriptionPayment')
  ),
  exact: true,
};
const TEAM_SUBSCRIPTION_RETRY_PAYMENT_COMP = {
  key: 'team/subscriptions/retry-payment',
  path: TEAM_SUBSCRIPTION_RETRY_PAYMENT_ROUTE,
  Component: loadable(() =>
    import('pages/team/subscriptions/payment/RetrySubscriptionPayment')
  ),
  exact: true,
};
const TEAM_MANAGE_COMP = {
  key: 'team/manage',
  path: TEAM_MANAGE_ROUTE,
  Component: loadable(() => import('pages/team/manage')),
  exact: true,
};
const TEAM_PAYMENTS_COMP = {
  key: 'team/payments',
  path: TEAM_PAYMENTS_ROUTE,
  Component: loadable(() => import('pages/team/payments')),
  exact: true,
};
const TEAM_PAYMENTS_HISTORY_COMP = {
  key: 'team/payments/history',
  path: TEAM_PAYMENTS_HISTORY_ROUTE,
  Component: loadable(() => import('pages/team/payments/history')),
  exact: true,
};
const TEAM_PAYMENTS_PENDING_COMP = {
  key: 'team/payments/pending',
  path: TEAM_PAYMENTS_PENDING_ROUTE,
  Component: loadable(() => import('pages/team/payments/pending')),
  exact: true,
};
const TEAM_PAYMENTS_PENDING_RETRY_COMP = {
  key: 'team/payments/pending/retry-payment',
  path: TEAM_PAYMENTS_PENDING_RETRY_ROUTE,
  Component: loadable(() =>
    import('pages/team/payments/pending/RetryExtraCostsPayment')
  ),
  exact: true,
};
const TEAM_PAYMENTS_UPCOMING_COMP = {
  key: 'team/payments/upcoming',
  path: TEAM_PAYMENTS_UPCOMING_ROUTE,
  Component: loadable(() => import('pages/team/payments/upcoming')),
  exact: true,
};
const TEAM_PAYMENT_ID_COMP = {
  key: 'team/payment',
  path: TEAM_PAYMENT_ID_ROUTE,
  Component: loadable(() => import('pages/team/payments/history/detail')),
  exact: true,
};

const SERVICES_VDX_CORE_API_DASHBOARD_COMP = {
  key: 'services/vdx-core-api',
  path: SERVICES_VDX_CORE_API_DASHBOARD_ROUTE,
  Component: loadable(() => import('pages/services/vdx_core_api')),
  exact: true,
};
const SERVICES_VDX_CORE_API_DASHBOARD_PER_ENV_COMPS =
  VDX_CORE_API_ENVIRONMENTS.map((env) => ({
    key: `services/vdx-core-api/${env.value}/dashboard`,
    path: SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES[env.id],
    Component: loadable(() =>
      import(`pages/services/vdx_core_api/env/${env.value}/dashboard`)
    ),
    exact: true,
  }));
const SERVICES_VDX_CORE_API_CREATE_PARTNER_PER_ENV_COMPS =
  VDX_CORE_API_ENVIRONMENTS.map((env) => ({
    key: `services/vdx-core-api/${env.value}/create-partner`,
    path: SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES[env.id],
    Component: loadable(() =>
      import(`pages/services/vdx_core_api/env/${env.value}/create_partner`)
    ),
    exact: true,
  }));
const RESOURCES_FAQ_COMP = {
  key: 'resources/faq',
  path: RESOURCES_FAQ_ROUTE,
  Component: loadable(() =>
    import('pages/resources/support/FreqAskedQuestions')
  ),
  exact: true,
};
const ACCOUNT_SETTINGS_COMP = {
  key: 'account/settings',
  path: ACCOUNT_SETTINGS_ROUTE,
  Component: loadable(() => import('pages/settings')),
  exact: true,
};
const ACCOUNT_TEAM_CREATE_COMP = {
  key: 'account/team/create',
  path: ACCOUNT_TEAM_CREATE_ROUTE,
  Component: loadable(() => import('pages/team/create')),
  exact: true,
};

const OwnerRoutes = [
  TEAM_DASHBOARD_COMP,
  TEAM_SUBSCRIPTIONS_COMP,
  TEAM_SUBSCRIPTION_PAYMENT_COMP,
  TEAM_SUBSCRIPTION_RETRY_PAYMENT_COMP,
  TEAM_MANAGE_COMP,
  TEAM_PAYMENTS_COMP,
  TEAM_PAYMENTS_HISTORY_COMP,
  TEAM_PAYMENTS_PENDING_COMP,
  TEAM_PAYMENTS_PENDING_RETRY_COMP,
  TEAM_PAYMENTS_UPCOMING_COMP,
  TEAM_PAYMENT_ID_COMP,
  SERVICES_VDX_CORE_API_DASHBOARD_COMP,
  ...SERVICES_VDX_CORE_API_DASHBOARD_PER_ENV_COMPS,
  ...SERVICES_VDX_CORE_API_CREATE_PARTNER_PER_ENV_COMPS,
  RESOURCES_FAQ_COMP,
  ACCOUNT_SETTINGS_COMP,
  ACCOUNT_TEAM_CREATE_COMP,
];

const ManagerRoutes = [
  TEAM_DASHBOARD_COMP,
  TEAM_SUBSCRIPTIONS_COMP,
  TEAM_SUBSCRIPTION_PAYMENT_COMP,
  TEAM_SUBSCRIPTION_RETRY_PAYMENT_COMP,
  TEAM_MANAGE_COMP,
  TEAM_PAYMENTS_COMP,
  TEAM_PAYMENTS_HISTORY_COMP,
  TEAM_PAYMENTS_PENDING_COMP,
  TEAM_PAYMENTS_PENDING_RETRY_COMP,
  TEAM_PAYMENTS_UPCOMING_COMP,
  TEAM_PAYMENT_ID_COMP,
  SERVICES_VDX_CORE_API_DASHBOARD_COMP,
  ...SERVICES_VDX_CORE_API_DASHBOARD_PER_ENV_COMPS,
  ...SERVICES_VDX_CORE_API_CREATE_PARTNER_PER_ENV_COMPS,
  RESOURCES_FAQ_COMP,
  ACCOUNT_SETTINGS_COMP,
  ACCOUNT_TEAM_CREATE_COMP,
];

const MemberRoutes = [
  TEAM_DASHBOARD_COMP,
  TEAM_MANAGE_COMP,
  SERVICES_VDX_CORE_API_DASHBOARD_COMP,
  ...SERVICES_VDX_CORE_API_DASHBOARD_PER_ENV_COMPS,
  ...SERVICES_VDX_CORE_API_CREATE_PARTNER_PER_ENV_COMPS,
  RESOURCES_FAQ_COMP,
  ACCOUNT_SETTINGS_COMP,
  ACCOUNT_TEAM_CREATE_COMP,
];

const FirstTimeUserRoutes = [
  RESOURCES_FAQ_COMP,
  ACCOUNT_SETTINGS_COMP,
  ACCOUNT_TEAM_CREATE_COMP,
];

export default {
  Owner: OwnerRoutes,
  Manager: ManagerRoutes,
  Member: MemberRoutes,
  FirstTimeUser: FirstTimeUserRoutes,
};
