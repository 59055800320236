import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const AlertTriangle = (props) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask id="prefix__a" fill="currentColor">
        <path d="M12.675 5.86L4.205 20a2 2 0 001.71 3h16.94a2 2 0 001.71-3l-8.47-14.14a1.999 1.999 0 00-3.42 0z" />
        <path d="M15.385 19a1 1 0 11-2 0 1 1 0 012 0z" />
      </mask>
      <path
        d="M14.885 11a.5.5 0 10-1 0zm-1 4a.5.5 0 001 0zm0-4v4h1v-4zm-1.21-5.14l-.855-.519-.003.005zM4.205 20l-.858-.514-.008.014zm1.71 3l-.011 1h.011zm16.94 0v1h.011zm1.71-3l.866-.5-.008-.014zm-8.47-14.14l.858-.514-.003-.005zm-1.71-.963v-1zm-2.568.45l-8.47 14.14 1.716 1.027 8.47-14.14zM3.34 19.5a3 3 0 00-.402 1.492l2 .005a1 1 0 01.134-.497zm-.402 1.492a3 3 0 00.394 1.494l1.737-.99a1 1 0 01-.131-.499zm.394 1.494a3 3 0 001.085 1.1l1.014-1.724a1 1 0 01-.362-.367zm1.085 1.1c.451.265.964.408 1.488.414l.022-2a1 1 0 01-.496-.138zM5.915 24h16.94v-2H5.915zm16.951 0a3.001 3.001 0 001.488-.414l-1.014-1.724a1 1 0 01-.496.138zm1.488-.414a3 3 0 001.085-1.1l-1.737-.99a.999.999 0 01-.362.366zm1.085-1.1a3 3 0 00.394-1.494l-2 .005a1 1 0 01-.131.498zm.394-1.494a2.999 2.999 0 00-.402-1.492l-1.732 1a.998.998 0 01.134.497zm-.41-1.506l-8.47-14.14-1.716 1.028 8.47 14.14zM16.95 5.341a3 3 0 00-1.093-1.058l-.981 1.743a1 1 0 01.364.353zm-1.093-1.058a3 3 0 00-1.472-.386v2a1 1 0 01.49.129zm-1.472-.386a3 3 0 00-1.472.386l.981 1.743a1 1 0 01.491-.129zm-1.472.386a3 3 0 00-1.093 1.058l1.71 1.038c.09-.147.215-.269.364-.353zM14.385 19v2a2 2 0 002-2zm0 0h-2a2 2 0 002 2zm0 0v-2a2 2 0 00-2 2zm0 0h2a2 2 0 00-2-2z"
        fill="currentColor"
        mask="url(#prefix__a)"
        transform="translate(-2.385 -1.949)"
      />
    </svg>
  </SvgIcon>
);

export default AlertTriangle;
