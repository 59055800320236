import { actionOn, thunk, action } from 'easy-peasy';
import capitalize from 'lodash/capitalize';

export default (actionName, thunkAction) => {
  const sendName = `${actionName
    .split('-')
    .map((i, index) => (index === 0 ? i : capitalize(i)))
    .join('')}`;
  const listenerName = `${actionName
    .split('-')
    .map((i) => capitalize(i))
    .join('')}`;

  return {
    [sendName]: thunk(thunkAction),
    [`onSend${listenerName}Success`]: actionOn(
      (actions) => actions[sendName].successType,
      (state) => {
        state.sending = false;
        state[`${sendName}State`].sent = true;
        state[`${sendName}State`].sending = false;
        state[`${sendName}State`].error = null;
      }
    ),
    [`onSend${listenerName}Start`]: actionOn(
      (actions) => actions[sendName].startType,
      (state) => {
        state[`${sendName}State`] = { sending: true };
        state.sending = true;
      }
    ),
    [`onSend${listenerName}Fail`]: actionOn(
      (actions) => actions[sendName].failType,
      (state, { error, payload }) => {
        state.sending = false;
        state[`${sendName}State`].sent = false;
        state[`${sendName}State`].sending = false;
        state[`${sendName}State`].error = { message: error, payload };
      }
    ),
    [`clear${listenerName}State`]: action((state) => {
      state[`${sendName}State`] = null;
    }),
  };
};
