import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  progress: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  },
}));

const LoadingPage = styled(({ className }) => {
  const classes = useStyles();
  return <LinearProgress className={clsx(classes.progress, className)} />;
})``;

export default LoadingPage;
