import React from 'react';

import { IconButton } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import HelpIcon from 'assets/icons/notifications/help_circle';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import useOnboarding from 'hooks/useOnboarding';
import { Steps } from 'intro.js-react';
import styled from 'styled-components';

import 'intro.js/introjs.css';
import './introjs_custom_styling.css';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  highlighter: {
    boxShadow: `${theme.palette.primary.dark} 0px 0px 1px 2px, ${alpha(
      theme.palette.secondary.dark,
      0.5
    )} 0px 0px 0px 5000px !important`,
  },
  tooltip: {
    ...theme.typography.body1,
    backgroundColor: theme.palette.background.paper,
    paddingLeft: '3.6rem',
    paddingRight: '2.8rem',
    paddingBottom: '3.2rem',
    minWidth: '15vw',
    maxWidth: '20vw',
    [theme.breakpoints.down('lg')]: {
      minWidth: '30vw',
      maxWidth: '35vw',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '35vw',
      maxWidth: '40vw',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '50vw',
      maxWidth: '60vw',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '80vw',
      maxWidth: '90vw',
    },
    '& .introjs-tooltip-header': {
      padding: 0,
      position: 'static',
      paddingTop: '9.1rem',
      '& .introjs-tooltip-title': {
        ...theme.typography.heading5,
        textAlign: 'center',
        width: '100%',
      },
      '& .introjs-skipbutton': {
        width: '100%',
        height: '100%',
        lineHeight: 'unset',
        transform: 'translate(50%, -50%)',
        background:
          'no-repeat center url(/images/onboarding/dialog_close_button.svg)',
        borderRadius: '50%',
      },
    },
    '& .introjs-tooltiptext': {
      padding: 0,
      marginTop: '2.9rem',
      marginBottom: '5rem',
      '& p': {
        marginTop: '1rem',
        '&:first-child': {
          marginTop: 0,
        },
      },
    },
    '& .introjs-bullets': {
      padding: 0,
    },
    '& .introjs-tooltipbuttons': {
      borderTop: 'none',
      display: 'flex',
      justifyContent: 'center',
      padding: 0,
      marginTop: '3.3rem',
    },
    '& .introjs-arrow': {
      display: 'none !important',
    },
    '& *': {
      fontFamily: theme.typography.body1.fontFamily,
    },
    '& .introjs-prevbutton': {
      left: 'calc(3.6rem + 1.4rem)',
      color: theme.palette.shades.secondary[600],
      ...theme.typography.body2,
      position: 'absolute',
      top: '2.8rem',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: '-1.4rem',
        top: '0.1rem',
        width: '0.6rem',
        height: '1.2rem',
        background:
          'no-repeat center url(/images/onboarding/previous_arrow.svg)',
      },
      '&.introjs-disabled': {
        display: 'none',
        width: 0,
        height: 0,
      },
    },
  },
  button: {
    '&.introjs-nextbutton': {
      ...theme.typography.body1,
      ...theme.overrides.MuiButton.root,
      ...theme.overrides.MuiButton.label,
      ...theme.overrides.MuiButton.contained,
      ...theme.overrides.MuiButton.containedSecondary,
    },
  },
}));

const options = {
  showBullets: true,
  doneLabel: 'Finish',
  nextLabel: 'Continue',
  hidePrev: false,
  disableInteraction: true,
  prevLabel: 'Previous',
  skipLabel: '',
};

const HelpButton = motion(IconButton);

const Onboarding = styled(({ className, ...props }) => {
  const { tips, enabledTips, showTips, hideTips } = useOnboarding();
  const classes = useStyles();
  return (
    <AnimatePresence>
      {tips.length && (
        <>
          <HelpButton
            {...props}
            className={clsx(classes.iconButton, className)}
            onClick={showTips}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            key="help-button"
          >
            <HelpIcon />
          </HelpButton>
          <Steps
            enabled={enabledTips}
            steps={tips}
            initialStep={0}
            onExit={hideTips}
            options={{
              ...options,
              ...(tips.length <= 1 ? { showBullets: false } : null),
              highlightClass: classes.highlighter,
              buttonClass: classes.button,
              tooltipClass: classes.tooltip,
            }}
          />
        </>
      )}
    </AnimatePresence>
  );
})``;

export default Onboarding;
