import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const MoreHorizontal = (props) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM21 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM7 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default MoreHorizontal;
