export const VDX_CORE_API_ENVIRONMENTS = [
  {
    id: 'LIVE',
    label: 'Live',
    value: 'live',
  },
  {
    id: 'TEST',
    label: 'Test',
    value: 'test',
  },
];

export const VDX_CORE_API_ENGINE_IDS = ['bitcoin', 'ethereum', 'polygon'];
export const VDX_CORE_API_UPCOMING_ENGINE_IDS = [
  'cardano',
  'solana',
  'ripple',
  'bsc',
];

export const VDX_CORE_API_PARTNER_ENGINES = {
  bitcoin: {
    id: 'bitcoin',
    value: 'Bitcoin',
  },
  ethereum: {
    id: 'ethereum',
    value: 'Ethereum',
  },
  polygon: {
    id: 'polygon',
    value: 'Polygon',
  },
  cardano: {
    id: 'cardano',
    value: 'Cardano (ADA)',
  },
  solana: {
    id: 'solana',
    value: 'Solana (Sol)',
  },
  ripple: {
    id: 'ripple',
    value: 'Ripple (XRP)',
  },
  bsc: {
    id: 'bsc',
    value: 'Binance Smart Coin (BSC)',
  },
};
