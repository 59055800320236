import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  font: {
    ...theme.typography.smallTitle,
  },
}));

const SmallTitle = styled(({ className, children }) => {
  const classes = useStyles();
  return (
    <Typography className={clsx(classes.font, className)}>
      {children}
    </Typography>
  );
})``;

export default SmallTitle;
