import { CancelToken } from 'axios';

const cancellablePromise = (promise, cancel) =>
  new Proxy(promise, {
    get(target, prop) {
      if (prop === 'cancel') return () => cancel();

      const value = target[prop];

      return typeof value === 'function'
        ? (...args) => cancellablePromise(value.bind(target)(...args), cancel)
        : value;
    },
  });

export default (service) =>
  (...args) => {
    const source = CancelToken.source();

    const promise = service(...[...args, source.token]);

    return cancellablePromise(promise, source.cancel);
  };
