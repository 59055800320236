import httpClient from '../client';
import local from '../client/local';

export default async ({ page = 1, status = 'SENT' }) => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(
        `/api/invitations/?status=${status}&page=${page}`
      );
    } else {
      response = await httpClient.get(
        `/api/invitations/?status=${status}&page=${page}`
      );
    }

    return {
      count: response.count,
      items: response.items.map((item) => ({
        token: item.token,
        team: item.team,
        expirationDate: item.expiration_date,
      })),
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
