import React from 'react';

import { Checkbox as MUICheckbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: ({ hasError }) => (hasError ? theme.palette.error.main : 'inherit'),
  },
}));

const Checkbox = ({ hasError, ...props }) => {
  const classes = useStyles({ hasError });
  return <MUICheckbox {...props} className={classes.checkbox} />;
};

export default Checkbox;
