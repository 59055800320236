/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';

import { InputAdornment } from '@material-ui/core';
import { Autocomplete as MUIAutocomplete } from '@material-ui/lab';
import SearchIcon from 'assets/icons/popular/search';

import TextField from './TextField';

const Autocomplete = ({
  name,
  value,
  onInputChange,
  onBlur,
  error,
  helperText,
  disabled,
  id,
  label,
  onFocus,
  options,
}) => (
  <MUIAutocomplete
    id={id}
    options={options}
    autoHighlight
    fullWidth
    onInputChange={(e, vl, reason) => {
      if (onInputChange && reason === 'input') onInputChange(name, vl);
    }}
    onChange={(e, vl, reason) => {
      if (onInputChange && (reason === 'select-option' || reason === 'clear'))
        onInputChange(name, vl);
    }}
    getOptionLabel={(option) => option}
    renderOption={(option) => option}
    clearOnBlur={false}
    onOpen={onFocus}
    inputValue={value}
    disabled={disabled}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        error={error}
        helperText={helperText}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
        onBlur={onBlur}
        disabled={disabled}
      />
    )}
  />
);

export default Autocomplete;
