import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';

const containerAnimations = {
  close: {
    height: 0,
    overflow: 'hidden',
    transition: {
      type: 'ease',
    },
  },
  open: {
    height: 'auto',
    transition: {
      type: 'ease',
    },
  },
};
const AnimatedContainer = ({
  keyLabel = 'animated-container',
  isOpen = false,
  children,
}) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        key={keyLabel}
        initial="close"
        animate="open"
        exit="close"
        variants={containerAnimations}
      >
        {children}
      </motion.div>
    )}
    <motion.div />
  </AnimatePresence>
);

export default AnimatedContainer;
