import httpClient from '../client';
import local from '../client/local';

export default async () => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(`/api/profile/`);
    } else {
      response = await httpClient.get(`/api/profile/`);
    }

    const user = response;
    const emailSettings = {};
    const pushSettings = {};
    Object.keys(user.email_settings).forEach((key) => {
      emailSettings[key] = user.email_settings[key];
    });
    Object.keys(user.email_settings).forEach((key) => {
      pushSettings[key] = user.push_notification_settings[key];
    });
    return {
      id: user.user.id,
      firstName: user.user.first_name,
      lastName: user.user.last_name,
      email: user.user.email,
      fullName: user.full_name,
      photo: user.photo,
      emailSettings,
      pushSettings,
      uid: user.uid,
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
