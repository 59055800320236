import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Info = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <mask id="prefix__a" fill="#fff">
        <path d="M14.385 24c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z" />
        <path d="M15.385 10a1 1 0 11-2 0 1 1 0 012 0z" />
      </mask>
      <path
        d="M13.885 18a.5.5 0 001 0zm1-4a.5.5 0 10-1 0zm0 4v-4h-1v4zm8.5-4a9 9 0 01-9 9v2c6.075 0 11-4.925 11-11zm-9 9a9 9 0 01-9-9h-2c0 6.075 4.925 11 11 11zm-9-9a9 9 0 019-9V3c-6.075 0-11 4.925-11 11zm9-9a9 9 0 019 9h2c0-6.075-4.925-11-11-11zm0 5v2a2 2 0 002-2zm0 0h-2a2 2 0 002 2zm0 0V8a2 2 0 00-2 2zm0 0h2a2 2 0 00-2-2z"
        fill="currentColor"
        mask="url(#prefix__a)"
        transform="translate(-2.385 -2)"
      />
    </svg>
  </SvgIcon>
);

export default Info;
