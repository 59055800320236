import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const ChevronDown = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        d="M6 9l6 6 6-6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default ChevronDown;
