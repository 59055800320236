import React, { useEffect, useState } from 'react';

import RedirectDialog from 'components/molecules/Dialog/RedirectDialog';
import { PLAN_STATUS } from 'consts';
import { NavLink, useLocation } from 'react-router-dom';
import {
  ACCOUNT_SETTINGS_ROUTE,
  TEAM_DASHBOARD_ROUTE,
  TEAM_MANAGE_ROUTE,
  TEAM_SUBSCRIPTION_RETRY_PAYMENT_ROUTE,
} from 'routes/paths';
import teams from 'stores/teams';

const SuspendedPlan = () => {
  const { plan, teamUser, activeTeam, isSwitchingTeams } = teams.useStoreState(
    (st) => st
  );
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (plan.fetched) {
      const isSuspended = plan.result.status === PLAN_STATUS.suspended.id;
      const isInDeletion = activeTeam.toBeDeleted;

      if (
        isSuspended &&
        !isInDeletion &&
        !isSwitchingTeams &&
        location.pathname !== TEAM_MANAGE_ROUTE &&
        location.pathname !== TEAM_SUBSCRIPTION_RETRY_PAYMENT_ROUTE &&
        location.pathname !== TEAM_DASHBOARD_ROUTE &&
        location.pathname !== ACCOUNT_SETTINGS_ROUTE
      )
        setOpenDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan.fetched, location.pathname]);

  const closeDialog = () => setOpenDialog(false);

  if (openDialog && teamUser.permissions.teams.canUnsuspend) {
    return (
      <RedirectDialog
        open={openDialog}
        title="Your subscription is suspended!"
        redirectButtonProps={{
          component: NavLink,
          onClick: closeDialog,
          to: TEAM_MANAGE_ROUTE,
          style: { textTransform: 'none' },
        }}
        redirectButtonLabel="Go to Team Management"
        onClose={closeDialog}
      >
        Your subscription is suspended at the moment. Please retry your payment
        in the team management page.
      </RedirectDialog>
    );
  }
  if (openDialog && !teamUser.permissions.teams.canUnsuspend) {
    return (
      <RedirectDialog
        open={openDialog}
        title="Your subscription is suspended!"
        redirectButtonProps={{
          onClick: closeDialog,
        }}
        redirectButtonLabel="Got it!"
        onClose={closeDialog}
      >
        Your subscription is suspended at the moment. Please contact your team
        owner or manager in order to solve this issue.
      </RedirectDialog>
    );
  }
  return null;
};

export default SuspendedPlan;
