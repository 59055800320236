const baseColors = {
  primary: '#9F91EF',
  primaryVariant: '#8070E0',
  complementary: '#5BC8AF',
  complementaryVariant: '#38A88E',
  secondary: '#757575',
  secondaryVariant: '#191919',
};

const blue = {
  900: '#3C2E97',
  800: '#4A39BA',
  700: '#5B47DA',
  600: '#6E5BE2',
  500: baseColors.primaryVariant,
  400: baseColors.primary,
  300: '#BCB1FC',
  200: '#D5CEFF',
  100: '#E9E5FF',
};

const neutral = {
  900: baseColors.secondaryVariant,
  800: '#4D4D4D',
  700: baseColors.secondary,
  600: '#9E9E9E',
  500: '#B4B4B4',
  400: '#D6D6D6',
  300: '#F1F1F1',
  200: '#F8F9FA',
  100: '#FFF',
};

const complementary = {
  red: '#DC3545',
  redLight: '#FFD8D8',
  green: baseColors.complementary,
  greenLight: baseColors.complementaryVariant,
  blue: '#0055F0',
  blueLight: '#F0F5FE',
  orange: '#FFB674',
  orangeDark: '#D28743',
};

export default {
  primary: {
    main: baseColors.primary,
    dark: baseColors.primaryVariant,
    contrastText: neutral[100],
  },
  secondary: {
    main: baseColors.secondary,
    dark: baseColors.secondaryVariant,
    contrastText: neutral[100],
  },
  info: {
    main: complementary.blue,
    light: complementary.blueLight,
    contrastText: neutral[100],
  },
  error: {
    main: complementary.red,
    light: complementary.redLight,
    contrastText: neutral[100],
  },
  warning: {
    main: complementary.orange,
    dark: complementary.orangeDark,
    contrastText: neutral[100],
  },
  success: {
    main: complementary.green,
    light: complementary.greenLight,
    contrastText: neutral[100],
  },
  shades: {
    primary: blue,
    secondary: neutral,
  },
  text: {
    primary: neutral[500],
  },
  background: {
    default: '#2C2D2F',
    paper: '#222225',
    popper: '#2C2D2F',
  },
  divider: {
    color: neutral[800],
  },
};
