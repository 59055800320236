import React, { Suspense } from 'react';

import useAuth from 'auth/useAuth';
import { LoadingPage } from 'components/molecules';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import teams from 'stores/teams';

import ComponentRoutes from './components';
import ExternalRedirect from './ExternalRedirect';
import { ACCOUNT_SETTINGS_ROUTE, TEAM_DASHBOARD_ROUTE } from './paths';

export const NavigationByRole = ({ role }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        {ComponentRoutes[role || 'FirstTimeUser'].map((route) =>
          route.external ? (
            <ExternalRedirect
              key={route.key}
              exact={route.exact}
              path={route.path}
              link={route.link}
            />
          ) : (
            <Route
              key={route.key}
              path={route.path}
              exact={route.exact}
              render={() => {
                if (isAuthenticated) {
                  return <route.Component />;
                }
                return null;
              }}
            />
          )
        )}
        {role && (
          <>
            <Redirect exact from="/" to={TEAM_DASHBOARD_ROUTE} />
            <Redirect from="*" to={TEAM_DASHBOARD_ROUTE} />
          </>
        )}
        {!role && (
          <>
            <Redirect exact from="/" to={ACCOUNT_SETTINGS_ROUTE} />
            <Redirect from="*" to={ACCOUNT_SETTINGS_ROUTE} />
          </>
        )}
      </Switch>
    </Suspense>
  );
};

export const Routes = () => {
  const { teamUser } = teams.useStoreState((st) => st);
  return <NavigationByRole role={teamUser.role} />;
};

const Router = ({ children }) => <BrowserRouter>{children}</BrowserRouter>;

export default Router;
