import React from 'react';

import { Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VDXApiLightLogoWithText from 'assets/app_logos/vdx_api_light_with_text';
import ExternalLinkIcon from 'assets/icons/popular/external_link';
import { Body1 } from 'components/atoms';
import { ThinDialog as Dialog } from 'components/molecules/Dialog/Dialog';
import { NavLink } from 'react-router-dom';
import {
  EXTERNAL_RESOURCES_DOCUMENTATION,
  TEAM_SUBSCRIPTIONS_ROUTE,
} from 'routes/paths';

const useStyles = makeStyles((theme) => ({
  paperClass: {
    width: '42rem',
  },
  header: {
    padding: '3.6rem 0 3rem 0',
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 'inherit',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: '14.8rem',
    height: '8rem',
  },
  content: {
    padding: '3.1rem 3.5rem 1.3rem 3.5rem',
  },
  title: {
    fontSize: '2rem',
    color: theme.palette.primary.dark,
    fontWeight: 700,
    lineHeight: '2rem',
  },
  description: {
    marginTop: '1.3rem',
    color: theme.palette.shades.secondary[700],
    marginBottom: '2rem',
    '& ul': {
      paddingLeft: '1.2rem',
    },
  },
  footer: {
    marginTop: '2.3rem',
    textAlign: 'center',
    color: theme.palette.shades.secondary[500],
    lineHeight: '2rem',
  },
  link: {
    color: theme.palette.info.main,
    textTransform: 'uppercase',
    lineHeight: '2rem',
    '&:not(:first-child)': {
      marginLeft: '2rem',
    },
    width: 'fit-content',
    '& p': {
      textTransform: 'uppercase',
      fontWeight: 700,
      marginRight: '0.4rem',
    },
  },
  list: {
    marginTop: 0,
  },
}));

const AboutMe = ({ open, onClose }) => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.paperClass }}
    >
      <div className={classes.header}>
        <VDXApiLightLogoWithText className={classes.logo} />
      </div>
      <div className={classes.content}>
        <Body1 className={classes.title}>About VDXapi</Body1>
        <div className={classes.description}>
          <Body1>
            VDX self-service API portal provides developers with an easy-to-use
            interface to issue and verify any type of assets on the blockchain.
          </Body1>
          <br />
          <Body1>VDX value propositions:</Body1>
          <ul className={classes.list}>
            <li>
              <Body1>Build apps that bring undisputable trust.</Body1>
            </li>
            <li>
              <Body1>
                Authenticate & instantly verify any assets - forever.
              </Body1>
            </li>
            <li>
              <Body1>
                VDX Portal is a web-serviced API that allows developers to
                connect and use the power of blockchain technology.
              </Body1>
            </li>
            <li>
              <Body1>
                VDXapi is 100% agnostic and can communicate/interact with any
                infrastructure or blockchain network.
              </Body1>
            </li>
            <li>
              <Body1>
                To reinvent and simplify the use of blockchain technology by
                merging cloud computing, web services and blockchain networks.
              </Body1>
            </li>
          </ul>
        </div>
        <Grid container alignItems="center">
          <NavLink
            to={TEAM_SUBSCRIPTIONS_ROUTE}
            className={classes.link}
            onClick={onClose}
          >
            <Body1>Pricing</Body1>
          </NavLink>
          <Link
            href={EXTERNAL_RESOURCES_DOCUMENTATION}
            target="_blank"
            rel="noopener"
            className={classes.link}
          >
            <Grid container alignItems="center">
              <Body1>Docs</Body1>
              <ExternalLinkIcon />
            </Grid>
          </Link>
          <Link
            href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
            target="_blank"
            rel="noopener"
            className={classes.link}
          >
            <Grid container alignItems="center">
              <Body1>Terms of Service</Body1>
              <ExternalLinkIcon />
            </Grid>
          </Link>
        </Grid>
        <Body1 className={classes.footer}>
          Copyright © 2022{currentYear > 2022 ? `-${currentYear}` : null}{' '}
          Vizidox Solutions Limited
        </Body1>
      </div>
    </Dialog>
  );
};

export default AboutMe;
