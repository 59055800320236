import { Tabs, Tab } from '@material-ui/core';
import styled from 'styled-components';

const TabPanel = styled.div`
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

export { Tabs, Tab, TabPanel };
