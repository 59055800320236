import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LoadingIcon from 'assets/icons/animated/loading';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    '&:hover': {
      borderColor: theme.palette.error.light,
      backgroundColor: theme.palette.error.light,
    },
  },
}));

const SecondaryDangerButton = styled(
  ({
    className,
    children,
    loading = false,
    icon,
    size = 'medium',
    ...props
  }) => {
    const classes = useStyles();
    return (
      <Button
        variant="text"
        classes={{
          root: classes.root,
        }}
        size={size}
        {...props}
        startIcon={loading ? <LoadingIcon /> : icon}
        className={className}
      >
        {children}
      </Button>
    );
  }
)``;

export default SecondaryDangerButton;
