import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Eye = (props) => (
  <SvgIcon {...props} viewBox="0 0 29 29">
    <svg width={29} height={29} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.888 14.496s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.888 17.496a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default Eye;
