import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const ExternalLink = (props) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 15v6a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h6m4-3h6m0 0v6m0-6L12 16"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default ExternalLink;
