import React from 'react';

import { Link } from '@material-ui/core';
import SecondaryButton from 'components/atoms/Buttons/SecondaryButton';

const SecondaryButtonLink = ({ children, color = 'secondary', ...props }) => (
  <SecondaryButton
    component={Link}
    {...props}
    color={color}
    className={props.className}
  >
    {children}
  </SecondaryButton>
);

export default SecondaryButtonLink;
