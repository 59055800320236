import httpClient from '../client';
import local from '../client/local';

export default async (teamId) => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(`/api/teams/${teamId}/get_billing_details/`);
    } else {
      response = await httpClient.get(
        `/api/teams/${teamId}/get_billing_details/`
      );
    }

    return {
      street: response.street_address,
      city: response.city,
      postalCode: response.postal_code,
      country: response.country,
      vat: response.vat_number,
      countryCode: response.country_code,
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
