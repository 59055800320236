import React from 'react';

import {
  SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES,
  SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES,
  TEAM_DASHBOARD_ROUTE,
  TEAM_MANAGE_ROUTE,
  TEAM_PAYMENTS_HISTORY_ROUTE,
} from 'routes/paths';

const TeamDashboardTipsWoLivePartner = [
  {
    title: 'Get started',
    intro: (
      <>
        <p>This is the Team Dashboard screen.</p>
        <p>
          You are able to see an overview of the statistics and{' '}
          <b>data related to your current team</b>.
        </p>
      </>
    ),
  },
  {
    title: 'Create a partner',
    element: '#services-menu-button',
    intro: (
      <>
        <p>
          We currently offer our <b>VDX Core API</b>.
        </p>
        <p>
          Create a partner first in order to use our API. You can do it under{' '}
          <b>Services</b>.
        </p>
      </>
    ),
    position: 'right',
  },
  {
    title: 'Track your requests',
    element: '#api-requests-widget',
    intro: (
      <>
        <p>
          Once you create a partner on the <b>LIVE environment</b>, you will be
          able to track all your API requests used through this widget.
        </p>
        <p>
          All used requests <b>reset every month</b>, on every plan.
        </p>
      </>
    ),
  },
];

const TeamDashboardTipsWLivePartner = [
  {
    title: 'Team dashboard',
    intro: (
      <>
        <p>This is the Team Dashboard screen.</p>
        <p>
          You are able to see an <b>overview</b> of the statistics and{' '}
          <b>data related to your current team</b>.
        </p>
      </>
    ),
  },
  {
    title: 'Track requests',
    element: '#api-requests-widget',
    intro: (
      <>
        <p>
          Here you can track all your used API requests on your current{' '}
          <b>LIVE environment</b>.
        </p>
        <p>
          We want to remember you that all used requests{' '}
          <b>reset every month</b>, on every plan.
        </p>
      </>
    ),
  },
  {
    title: 'Active services',
    element: '#active-services-widget',
    intro: (
      <>
        <p>
          You can also see the quantity of requests and jobs done so far on your
          current <b>LIVE environment.</b>
        </p>
      </>
    ),
  },
  {
    title: 'Increase request limit',
    element: '#team-manage-menu-button',
    intro: (
      <>
        <p>
          If necessary you can increase the{' '}
          <b>request limit of your current plan</b> in Team Management, without
          the need to upgrade to a new plan.
        </p>
      </>
    ),
    position: 'right',
  },
];

const FullTeamManagement = [
  {
    title: 'Team management',
    intro: (
      <>
        <p>This is the Team Management screen.</p>
        <p>
          You are able to manage your <b>team members</b>, as well as your team{' '}
          <b>settings and billing data</b>.
        </p>
      </>
    ),
  },
  {
    title: 'View all team members',
    element: '#team-members',
    intro: (
      <>
        <p>{`You can upgrade/downgrade their roles, as well as transferring the team's ownership to another member.`}</p>
      </>
    ),
    position: 'bottom',
  },
  {
    title: 'Change team settings',
    element: '#allow-extra-requests-setting',
    intro: (
      <>
        <p>
          In your team settings, you can <b>increase</b> the maximum{' '}
          <b>limit for your API requests</b>.
        </p>
        <p>{`The number specified will be added to your current plan's limit. They will only be charged if used.`}</p>
        <p>All requests used reset to 0 every month.</p>
      </>
    ),
    position: 'left',
  },
  {
    title: 'Update billing data',
    element: '#team-billing',
    intro: (
      <>
        <p>To proceed with your payment, we request your billing data.</p>
      </>
    ),
    position: 'right',
  },
  {
    title: 'Update payment card',
    element: '#team-payment-card',
    intro: (
      <>
        <p>As well as a valid credit card.</p>
      </>
    ),
    position: 'left',
  },
  {
    title: 'Cancel current subscription',
    element: '#team-cancel-subscription-button',
    intro: (
      <>
        <p>
          You can also opt out to <b>cancel your current paid subscription</b>{' '}
          anytime.
        </p>
        <p>
          Your subscription will be <b>valid until the specified end date</b>.
          After that period has passed, your plan will be downgraded to the
          Sandbox plan and no more payments will be sent.
        </p>
      </>
    ),
    position: 'top-right-aligned',
  },
];

const LimitedTeamManagement = [
  {
    title: 'Team management',
    intro: (
      <>
        <p>This is the Team Management screen.</p>
        <p>
          You are able to <b>view all the others members</b> of your team, as
          well as the <b>current plan</b> and its status.
        </p>
      </>
    ),
  },
];

const TeamPaymentsHistory = [
  {
    title: 'Team payment history',
    intro: (
      <>
        <p>This is the Payments History screen.</p>
        <p>
          Here you can see all your <b>completed payments</b> and their
          corresponding invoices. Click each entry to view further details.
        </p>
      </>
    ),
  },
];

const ServicesCreatePartner = [
  {
    title: 'Create a partner',
    intro: (
      <>
        <p>
          You need to create a partner first before using <b>VDX Core API</b> in
          this environment.
        </p>
      </>
    ),
  },
  {
    title: 'Partner name',
    element: '#create-partner-name',
    intro: (
      <>
        <p>Adding a partner name is mandatory.</p>
        <p>
          You <b>cannot edit</b> the name once its created.
        </p>
      </>
    ),
  },
  {
    title: 'Blockchain settings',
    element: '#create-partner-issuance-frequency',
    intro: (
      <>
        <p>
          Select your preferred blockchain network and its issuance frequency.
          You must at least <b>select one</b>.
        </p>
        <p>
          This option <b>can be changed later on</b>.
        </p>
      </>
    ),
  },
];

const ServicesDashboard = [
  {
    title: 'Services dashboard',
    intro: (
      <>
        <p>This is the Services Dashboard screen.</p>
        <p>
          You are able to <b>view all the statistics</b> regarding your usage of
          VDX Core API on the selected environment.
        </p>
      </>
    ),
  },
  {
    title: 'Blockchain settings',
    element: '#update-blockchain-network-button',
    intro: (
      <>
        <p>Feel free to update your blockchain networks if needed.</p>
      </>
    ),
    position: 'left',
  },
  {
    title: 'Blockchain settings',
    element: '#dashboard-issuance-frequency',
    intro: (
      <>
        <p>As well as the issuance frequency for each network.</p>
      </>
    ),
    position: 'right',
  },
];

const OwnerRestrictedTips = {
  [TEAM_DASHBOARD_ROUTE]: ({ hasLivePartner }) => {
    if (hasLivePartner) return TeamDashboardTipsWLivePartner;
    return TeamDashboardTipsWoLivePartner;
  },
  [TEAM_MANAGE_ROUTE]: () => FullTeamManagement,
  [TEAM_PAYMENTS_HISTORY_ROUTE]: () => TeamPaymentsHistory,
  [SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES.LIVE]: ({
    hasLivePartner,
  }) => (!hasLivePartner ? ServicesCreatePartner : null),
  [SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES.TEST]: ({
    hasTestPartner,
  }) => (!hasTestPartner ? ServicesCreatePartner : null),
  [SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES.LIVE]: ({ hasLivePartner }) =>
    hasLivePartner ? ServicesDashboard : null,
  [SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES.TEST]: ({ hasTestPartner }) =>
    hasTestPartner ? ServicesDashboard : null,
};

const ManagerRestrictedTips = {
  [TEAM_DASHBOARD_ROUTE]: ({ hasLivePartner }) => {
    if (hasLivePartner) return TeamDashboardTipsWLivePartner;
    return TeamDashboardTipsWoLivePartner;
  },
  [TEAM_MANAGE_ROUTE]: () => FullTeamManagement,
  [TEAM_PAYMENTS_HISTORY_ROUTE]: () => TeamPaymentsHistory,
  [SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES.LIVE]: ({
    hasLivePartner,
  }) => (!hasLivePartner ? ServicesCreatePartner : null),
  [SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES.TEST]: ({
    hasTestPartner,
  }) => (!hasTestPartner ? ServicesCreatePartner : null),
  [SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES.LIVE]: ({ hasLivePartner }) =>
    hasLivePartner ? ServicesDashboard : null,
  [SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES.TEST]: ({ hasTestPartner }) =>
    hasTestPartner ? ServicesDashboard : null,
};

const MemberRestrictedTips = {
  [TEAM_DASHBOARD_ROUTE]: ({ hasLivePartner }) => {
    if (hasLivePartner) return TeamDashboardTipsWLivePartner;
    return TeamDashboardTipsWoLivePartner;
  },
  [TEAM_MANAGE_ROUTE]: () => LimitedTeamManagement,
  [SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES.LIVE]: ({
    hasLivePartner,
  }) => (!hasLivePartner ? ServicesCreatePartner : null),
  [SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES.TEST]: ({
    hasTestPartner,
  }) => (!hasTestPartner ? ServicesCreatePartner : null),
  [SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES.LIVE]: ({ hasLivePartner }) =>
    hasLivePartner ? ServicesDashboard : null,
  [SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES.TEST]: ({ hasTestPartner }) =>
    hasTestPartner ? ServicesDashboard : null,
};

const screenTipsByRole = {
  Owner: OwnerRestrictedTips,
  Manager: ManagerRestrictedTips,
  Member: MemberRestrictedTips,
};

export default ({
  role,
  pathname,
  hasLivePartner = false,
  hasTestPartner = false,
}) => {
  if (!role) return null;
  return screenTipsByRole[role][pathname]
    ? screenTipsByRole[role][pathname]({ hasLivePartner, hasTestPartner })
    : null;
};
