import React, { forwardRef, Fragment, useState } from 'react';

import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LoadingIcon from 'assets/icons/animated/loading';
import clsx from 'clsx';
import { Body1, Body2 } from 'components/atoms';
import { NavLink, useLocation } from 'react-router-dom';

import AnimatedContainer from './components/AnimatedContainer';

const useStyles = makeStyles((theme) => ({
  list: {
    ...theme.nav.children.listContainer,
    paddingBottom: '0.8rem',
    paddingTop: '0.4rem',
    borderBottomLeftRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
  },
  entryLink: {
    marginTop: '0.1rem',
    padding: '0.4rem 0 0.4rem 2.7rem',
    display: 'block',
  },
  entryChild: {
    display: 'flex',
    paddingTop: '0.8rem',
    paddingLeft: '2.7rem',
    paddingRight: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  idle: {
    ...theme.nav.children.idle,
    borderRadius: '0.5rem',
    '&:hover': {
      ...theme.nav.children.active,
      textDecoration: 'none',
    },
  },
  active: {
    ...theme.nav.children.active,
  },
  idleChild: {
    ...theme.nav.children.children.idle,
    width: '100%',
    '&:hover': {
      ...theme.nav.children.children.active,
      textDecoration: 'none',
      '& > p::after': {
        content: '""',
        position: 'absolute',
        bottom: '0.2rem',
        left: 0,
        right: 0,
        backgroundColor: theme.nav.children.children.active.color,
        height: '1px',
      },
    },
  },
  activeChild: {
    ...theme.nav.children.children.active,
    '& > p::after': {
      content: '""',
      position: 'absolute',
      bottom: '0.2rem',
      left: 0,
      right: 0,
      backgroundColor: theme.nav.children.children.active.color,
      height: '1px',
    },
  },
  textChild: {
    width: 'max-content',
  },
  addTransparency: {
    opacity: 0.5,
  },
  loadingIcon: {
    width: '1rem',
    height: '1rem !important',
    '& > svg': {
      width: '1rem',
      height: '1rem',
    },
  },
}));

const SubSubEntries = forwardRef(
  ({ parentItem, entryActions = {}, hoveringEntry }, ref) => {
    const classes = useStyles();
    return (
      <div ref={ref}>
        {parentItem.sub &&
          parentItem.sub.map((item) =>
            item.path && (item.loading || !item.disabled) ? (
              <NavLink
                key={item.id}
                to={item.path}
                className={clsx(
                  classes.entryChild,
                  classes.idleChild,
                  hoveringEntry.id === item.id && classes.activeChild,
                  item.loading && classes.addTransparency
                )}
                activeClassName={classes.activeChild}
                onMouseEnter={() =>
                  entryActions.onMouseEnter({
                    parentId: parentItem.id,
                    ...item,
                  })
                }
                onMouseLeave={entryActions.onMouseLeave}
              >
                <Body2 className={classes.textChild}>{item.label}</Body2>
                {item.loading && (
                  <LoadingIcon className={classes.loadingIcon} />
                )}
              </NavLink>
            ) : null
          )}
      </div>
    );
  }
);

const ExpandableList = ({
  item,
  onClick,
  isOpen,
  hoveringEntry,
  hoveringActions = {},
}) => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <>
      <NavLink
        to={item.path}
        className={clsx(
          classes.entryLink,
          classes.idle,
          hoveringEntry.parentId === item.id && classes.active,
          item.defaultExpanded && classes.active
        )}
        onClick={(e) => {
          e.preventDefault();
          onClick(item);
        }}
        activeClassName={classes.active}
      >
        <Body1>{item.label}</Body1>
      </NavLink>
      {item.defaultExpanded && (
        <SubSubEntries
          parentItem={item}
          entryActions={{
            onMouseEnter: hoveringActions.onMouseEnter,
            onMouseLeave: hoveringActions.onMouseLeave,
          }}
          hoveringEntry={hoveringEntry}
        />
      )}
      {!item.defaultExpanded && (
        <AnimatedContainer
          isOpen={isOpen || location.pathname.includes(item.path)}
          keyLabel={`sub-sub-menu-list-${item.id}`}
        >
          <SubSubEntries
            parentItem={item}
            entryActions={{
              onMouseEnter: hoveringActions.onMouseEnter,
              onMouseLeave: hoveringActions.onMouseLeave,
            }}
            hoveringEntry={hoveringEntry}
          />
        </AnimatedContainer>
      )}
    </>
  );
};

const NavigationEntry = ({ item }) => {
  const classes = useStyles();
  return (
    <NavLink
      to={item.path}
      className={clsx(classes.entryLink, classes.idle)}
      activeClassName={classes.active}
      id={`${item.id.split('/').join('-')}-menu-button`}
    >
      <Body1>{item.label}</Body1>
    </NavLink>
  );
};

const ExternalEntry = ({ item }) => {
  const classes = useStyles();
  return (
    <Link
      key={item.id}
      href={item.external}
      className={clsx(classes.entryLink, classes.idle)}
      id={`${item.id.split('/').join('-')}-menu-button`}
      {...item.args}
    >
      <Body1>{item.label}</Body1>
    </Link>
  );
};

const SubMenuList = forwardRef((props, ref) => {
  const [hoveringEntry, setHoveringEntry] = useState({});
  const [showEntriesByButton, setShowEntriesByButton] = useState({});
  const { sub } = props;
  const classes = useStyles();

  const highlightParentEntry = (item) => {
    setHoveringEntry(item);
  };

  const unHighlightParentEntry = () => {
    setHoveringEntry({});
  };

  const toggleExpand = (item) => {
    if (showEntriesByButton === item.id) {
      setShowEntriesByButton(null);
    } else if (item.sub) {
      setShowEntriesByButton(item.id);
    }
  };

  return (
    <div className={classes.list} ref={ref}>
      {sub &&
        sub.map((subItem) => (
          <Fragment key={subItem.id}>
            {subItem.sub && (
              <ExpandableList
                item={subItem}
                onClick={toggleExpand}
                isOpen={showEntriesByButton === subItem.id}
                hoveringEntry={hoveringEntry}
                hoveringActions={{
                  onMouseEnter: highlightParentEntry,
                  onMouseLeave: unHighlightParentEntry,
                }}
              />
            )}
            {subItem.path && !subItem.sub && <NavigationEntry item={subItem} />}
            {subItem.external && <ExternalEntry item={subItem} />}
          </Fragment>
        ))}
    </div>
  );
});

export default ({ isOpen, ...props }) => (
  <AnimatedContainer isOpen={isOpen} keyLabel={`sub-menu-list-${props.id}`}>
    <SubMenuList {...props} />
  </AnimatedContainer>
);
