export const BILLING_PERIODS = {
  MON: {
    label: 'Monthly',
    monthlyDuration: 'month',
    calculateTotal: (monthlyPrice) => monthlyPrice,
  },
  ANN: {
    label: 'Annual',
    monthlyDuration: '12 months',
    calculateTotal: (monthlyPrice) => monthlyPrice * 12,
  },
};

export const BILLING_MODE = {
  extraCosts: {
    id: 'MON',
    label: 'Monthly',
  },
};
