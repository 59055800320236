import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Bell = (props) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.754 3.502a7 7 0 00-7 7v5a3 3 0 01-3 3h20a3 3 0 01-3-3v-5a7 7 0 00-7-7zm0 0v-3m1.73 22a2 2 0 01-3.46 0"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default Bell;
