import { VDX_CORE_API_ENVIRONMENTS } from 'consts';
import {
  createContextStore,
  thunkOn,
  actionOn,
  action,
  thunk,
} from 'easy-peasy';
import getPartner from 'services/api/partner/get_partner';
import getPartnerEngine from 'services/api/partner/get_partner_engine';
import getPartnerSubscriptionRequests from 'services/api/partner/get_partner_subscription_requests';
import getBillingDetails from 'services/api/teams/get_billing_details';
import getCreditCardInfo from 'services/api/teams/get_credit_card_info';
import getSubscription from 'services/api/teams/get_latest_subscription';
import getFailedPayment from 'services/api/teams/get_pending_extra_costs';
import getTeam from 'services/api/teams/get_team';
import getTeams from 'services/api/teams/get_teams';
import getUpcomingPayment from 'services/api/teams/get_upcoming_payment';
import {
  get as getLastAccessedTeam,
  set as setLastAccessedTeam,
} from 'storage/last_accessed_team';
import cancellable from 'stores/utils/cancellable_thunk';
import fetchThunkHelper from 'stores/utils/fetch_thunk_helper';
import { getPermissions } from 'utils/roles_permissions';

const getCurrTeam = (teams) => {
  const lastActiveTeamId = getLastAccessedTeam()?.id;
  return (
    (lastActiveTeamId && teams.find((i) => i.id === lastActiveTeamId)) ||
    teams[0]
  );
};

const model = () => ({
  teamUser: {
    email: undefined,
    memberId: undefined,
    role: undefined,
    permissions: {},
  },
  teams: {},
  activeTeam: {},
  plan: {
    fetching: true,
    result: {},
  },
  billing: {
    fetching: true,
    result: {},
  },
  payment: {
    fetching: true,
    result: {},
  },
  upcomingPayment: { fetching: true, result: {} },
  failedPayment: { fetching: true, result: {} },
  partner: {},
  isSwitchingTeams: false,
  fetching: true,

  /* --- Active team plan --- */
  ...fetchThunkHelper('plan', (actions, params, { fail }) =>
    cancellable(getSubscription)(params).catch((err) => fail(err))
  ),
  onGetPlanStart: actionOn(
    (actions) => actions.getPlan.startType,
    (state) => {
      state.plan.result = {};
      state.plan.error = null;
      state.plan.fetching = true;
      state.plan.fetched = false;
    }
  ),
  onGetPlanSuccess: actionOn(
    (actions) => actions.getPlan.successType,
    (state, { result }) => {
      state.plan.result = result;
      state.plan.fetching = false;
      state.plan.fetched = true;
    }
  ),
  onGetPlanFail: actionOn(
    (actions) => actions.getPlan.failType,
    (state, { error }) => {
      state.plan.error = error;
      state.plan.fetching = false;
      state.plan.fetched = false;
    }
  ),

  /* --- Active team billing data --- */
  ...fetchThunkHelper('billing', (actions, params, { fail }) =>
    cancellable(getBillingDetails)(params).catch((err) => fail(err))
  ),
  onGetBillingStart: actionOn(
    (actions) => actions.getBilling.startType,
    (state) => {
      state.billing.result = {};
      state.billing.error = null;
      state.billing.fetching = true;
      state.billing.fetched = false;
    }
  ),
  onGetBillingSuccess: actionOn(
    (actions) => actions.getBilling.successType,
    (state, { result }) => {
      state.billing.result = result;
      state.billing.fetching = false;
      state.billing.fetched = true;
    }
  ),
  onGetBillingFail: actionOn(
    (actions) => actions.getBilling.failType,
    (state, { error }) => {
      state.billing.error = error;
      state.billing.fetching = false;
      state.billing.fetched = false;
    }
  ),

  /* --- Active team payment info --- */
  ...fetchThunkHelper('payment', (actions, params, { fail }) =>
    cancellable(getCreditCardInfo)(params).catch((err) => fail(err))
  ),
  onGetPaymentStart: actionOn(
    (actions) => actions.getPayment.startType,
    (state) => {
      state.payment.result = {};
      state.payment.error = null;
      state.payment.fetching = true;
      state.payment.fetched = false;
    }
  ),
  onGetPaymentSuccess: actionOn(
    (actions) => actions.getPayment.successType,
    (state, { result }) => {
      state.payment.result = result;
      state.payment.fetching = false;
      state.payment.fetched = true;
    }
  ),
  onGetPaymentFail: actionOn(
    (actions) => actions.getPayment.failType,
    (state, { error }) => {
      state.payment.error = error;
      state.payment.fetching = false;
      state.payment.fetched = false;
    }
  ),

  /* --- Active team upcoming payment --- */
  ...fetchThunkHelper('upcoming-payment', (actions, params, { fail }) =>
    cancellable(getUpcomingPayment)(params).catch((err) => fail(err))
  ),
  onGetUpcomingPaymentStart: actionOn(
    (actions) => actions.getUpcomingPayment.startType,
    (state) => {
      state.upcomingPayment.result = {};
      state.upcomingPayment.error = null;
      state.upcomingPayment.fetching = true;
      state.upcomingPayment.fetched = false;
    }
  ),
  onGetUpcomingPaymentSuccess: actionOn(
    (actions) => actions.getUpcomingPayment.successType,
    (state, { result }) => {
      state.upcomingPayment.result = result;
      state.upcomingPayment.fetching = false;
      state.upcomingPayment.fetched = true;
    }
  ),
  onGetUpcomingPaymentFail: actionOn(
    (actions) => actions.getUpcomingPayment.failType,
    (state, { error }) => {
      state.upcomingPayment.error = error;
      state.upcomingPayment.fetching = false;
      state.upcomingPayment.fetched = false;
    }
  ),

  /* --- Active team failed payment --- */
  ...fetchThunkHelper('failed-payment', (actions, params, { fail }) =>
    cancellable(getFailedPayment)(params).catch((err) => fail(err))
  ),
  onGetFailedPaymentStart: actionOn(
    (actions) => actions.getFailedPayment.startType,
    (state) => {
      state.failedPayment.result = {};
      state.failedPayment.error = null;
      state.failedPayment.fetching = true;
      state.failedPayment.fetched = false;
    }
  ),
  onGetFailedPaymentSuccess: actionOn(
    (actions) => actions.getFailedPayment.successType,
    (state, { result }) => {
      state.failedPayment.result = result;
      state.failedPayment.fetching = false;
      state.failedPayment.fetched = true;
    }
  ),
  onGetFailedPaymentFail: actionOn(
    (actions) => actions.getFailedPayment.failType,
    (state, { error }) => {
      state.failedPayment.error = error;
      state.failedPayment.fetching = false;
      state.failedPayment.fetched = false;
    }
  ),

  /* --- Partner data & engines --- */
  ...fetchThunkHelper('partner', (actions, params, { fail }) =>
    cancellable(getPartner)(params).catch((err) => fail(err))
  ),
  onGetPartnerStart: actionOn(
    (actions) => actions.getPartner.startType,
    (state, { payload }) => {
      state.partner[payload.environment] = { fetching: true };
    }
  ),
  onGetPartnerSuccess: actionOn(
    (actions) => actions.getPartner.successType,
    (state, { result, payload }) => {
      state.partner[payload.environment] = {
        result,
        fetching: true,
      };
    }
  ),
  onGetPartnerFail: actionOn(
    (actions) => actions.getPartner.failType,
    (state, { payload, error }) => {
      state.partner[payload.environment] = {
        fetching: false,
        fetched: false,
        error,
      };
    }
  ),
  onAfterGetPartnerSuccess: thunkOn(
    (actions) => actions.getPartner.successType,
    (actions, { payload, result }) => {
      if (payload.environment === 'LIVE') {
        actions.getPartnerRequests({ ...payload });
      }
      if (result && result.engines) {
        result.engines.forEach((engine) =>
          actions.getPartnerEngine({ ...payload, engine })
        );
      }
    }
  ),
  ...fetchThunkHelper('partner-engine', (actions, params, { fail }) =>
    cancellable(getPartnerEngine)(params).catch((err) => fail(err))
  ),
  onGetPartnerEngineStart: actionOn(
    (actions) => actions.getPartnerEngine.startType,
    (state, { payload }) => {
      if (!state.partner[payload.environment].result.engine) {
        state.partner[payload.environment].result.engine = {};
      }
    }
  ),
  onGetPartnerEngineSuccess: actionOn(
    (actions) => actions.getPartnerEngine.successType,
    (state, { result, payload }) => {
      const fetchedEngines = [
        ...Object.keys(state.partner[payload.environment].result.engine),
        payload.engine,
      ];
      if (
        state.partner[payload.environment].result.engines.every((eng) =>
          fetchedEngines.includes(eng)
        )
      ) {
        state.partner[payload.environment] = {
          ...state.partner[payload.environment],
          fetching: false,
          fetched: true,
        };
      } else {
        state.partner[payload.environment] = {
          ...state.partner[payload.environment],
          fetching: true,
          fetched: false,
        };
      }
      state.partner[payload.environment].result.engine[payload.engine] = result;
    }
  ),
  onGetPartnerEngineFail: actionOn(
    (actions) => actions.getPartnerEngine.failType,
    (state, { error, payload }) => {
      state.partner[payload.environment] = {
        ...state.partner[payload.environment],
        fetching: false,
        fetched: false,
        error,
      };
    }
  ),
  getPartnerByEnvironment: thunk((actions, { teamId }) => {
    VDX_CORE_API_ENVIRONMENTS.forEach((env) => {
      actions.getPartner({ teamId, environment: env.id });
    });
  }),
  ...fetchThunkHelper('partner-requests', (actions, params, { fail }) =>
    cancellable(getPartnerSubscriptionRequests)(params).catch((err) =>
      fail(err)
    )
  ),
  onGetPartnerRequestsStart: actionOn(
    (actions) => actions.getPartnerRequests.startType,
    () => {
      // silent fetching
    }
  ),
  onGetPartnerRequestsSuccess: actionOn(
    (actions) => actions.getPartnerRequests.successType,
    (state, { payload, result }) => {
      state.partner[payload.environment].usedSubscriptionRequests =
        result.subscriptionRequests;
      state.partner[payload.environment].usedExtraRequests =
        result.extraRequests;
    }
  ),
  onGetPartnerRequestsFail: actionOn(
    (actions) => actions.getPartnerRequests.failType,
    () => {
      // silent fail
    }
  ),

  /* --- Update teams only --- */
  ...fetchThunkHelper('teams-update', (actions, params, { fail }) =>
    cancellable(getTeams)(params).catch((err) => fail(err))
  ),
  onGetTeamsUpdateSuccess: actionOn(
    (actions) => actions.getTeamsUpdate.successType,
    (state, { result }) => {
      state.teams = result;
      state.fetching = false;
      state.fetched = true;
      state.error = undefined;
    }
  ),
  /* --- Update active team only --- */
  ...fetchThunkHelper('team-update', (actions, params, { fail }) =>
    cancellable(getTeam)(params).catch((err) => fail(err))
  ),
  onGetTeamUpdateSuccess: actionOn(
    (actions) => actions.getTeamUpdate.successType,
    (state, { result }) => {
      const updatedTeamList = state.teams.items.map((team) =>
        team.id === result.id ? result : team
      );
      state.teams = { ...state.teams, items: updatedTeamList };
      if (state.activeTeam.id === result.id) {
        state.activeTeam = result;
        const currentUser = result.members.find(
          (i) => i.email === state.teamUser.email
        );
        /* update user role if needed */
        if (currentUser.role !== state.role) {
          const currentUserPermissions = getPermissions({
            currRole: currentUser.role,
            teamCount: state.teams.count,
            currTeam: { toBeDeleted: result.toBeDeleted },
          });
          state.teamUser.role = currentUser.role;
          state.teamUser.permissions = currentUserPermissions;
        }
      }

      state.fetching = false;
      state.fetched = true;
      state.error = undefined;
    }
  ),
  onGetTeamUpdateFail: thunkOn(
    (actions) => actions.getTeamUpdate.failType,
    (actions) => {
      actions.getTeams();
    }
  ),

  /* --- Teams & active team data --- */
  ...fetchThunkHelper('teams', (actions, params, { fail }) =>
    cancellable(getTeams)(params).catch((err) => fail(err))
  ),
  onGetTeamsSuccess: actionOn(
    (actions) => actions.getTeams.successType,
    (state, { result, payload }) => {
      if (result.count >= 1) {
        const currTeam = getCurrTeam(result.items);
        const currentUser = currTeam.members.find(
          (i) => i.email === (payload?.teamUserEmail || state.teamUser.email)
        );
        const currentUserPermissions = getPermissions({
          currRole: currentUser.role,
          teamCount: result.count,
          currTeam: { toBeDeleted: currTeam.toBeDeleted },
        });

        state.activeTeam = currTeam;
        state.teamUser.role = currentUser.role;
        state.teamUser.permissions = currentUserPermissions;
        state.teamUser.memberId = currentUser.id;

        state.teams = result;
        setLastAccessedTeam({ id: currTeam.id });

        if (!currentUserPermissions.teams.canViewActivePlan) {
          state.plan.fetching = false;
        }
        if (!currentUserPermissions.teams.canViewBilling) {
          state.billing.fetching = false;
          state.payment.fetching = false;
        }
      } else {
        state.teams = { count: 0, items: [] };
        state.plan.fetching = false;
        state.billing.fetching = false;
        state.payment.fetching = false;
        /* reset active team and team user data */
        state.activeTeam = {};
        state.teamUser.memberId = null;
        state.teamUser.role = null;
        state.teamUser.permissions = {};
      }

      state.fetching = false;
      state.fetched = true;
      state.error = undefined;
    }
  ),
  onAfterGetTeamsSuccess: thunkOn(
    (actions) => actions.getTeams.successType,
    (actions, { result }, { getState }) => {
      if (result.count >= 1) {
        const currTeam = getCurrTeam(result.items);
        const currentUser = currTeam.members.find(
          (i) => i.email === getState().teamUser.email
        );
        const roles = getPermissions({
          currRole: currentUser.role,
          teamCount: result.count,
          currTeam: { toBeDeleted: result.toBeDeleted },
        });

        actions.getPartnerByEnvironment({ teamId: currTeam.id });
        if (roles.teams.canViewActivePlan) {
          actions.getPlan(currTeam.id);
        }
        if (roles.teams.canViewBilling) {
          actions.getBilling(currTeam.id);
          actions.getPayment(currTeam.id);
          actions.getUpcomingPayment(currTeam.id);
          actions.getFailedPayment(currTeam.id);
        }
      }
    }
  ),
  setTeamUserEmail: action((state, email) => {
    state.teamUser.email = email;
  }),
  setTeamUser: thunk((actions, { email }) => {
    actions.setTeamUserEmail(email);
    actions.getTeams({ teamUserEmail: email });
  }),
  onSetActiveTeam: action((state, teamId) => {
    const newActiveTeam = state.teams.items.find((i) => i.id === teamId);
    const currentUser = newActiveTeam.members.find(
      (i) => i.email === state.teamUser.email
    );
    state.activeTeam = newActiveTeam;
    state.teamUser.role = currentUser.role;
    state.teamUser.permissions = getPermissions({
      currRole: currentUser.role,
      teamCount: state.teams.count,
      currTeam: { toBeDeleted: newActiveTeam.toBeDeleted },
    });
    state.teamUser.memberId = currentUser.id;
    setLastAccessedTeam({ id: teamId });
  }),
  setActiveTeam: thunk((actions, teamId, { getState }) => {
    const currTeam = getState().teams.items.find((i) => i.id === teamId);
    const currentUser = currTeam.members.find(
      (i) => i.email === getState().teamUser.email
    );
    const roles = getPermissions({
      currRole: currentUser.role,
      teamCount: getState().teams.count,
      currTeam: { toBeDeleted: getState().activeTeam.toBeDeleted },
    });

    actions.getPartnerByEnvironment({ teamId: currTeam.id });
    if (roles.teams.canViewActivePlan) {
      actions.getPlan(teamId);
    }
    if (roles.teams.canViewBilling) {
      actions.getBilling(teamId);
      actions.getPayment(teamId);
      actions.getUpcomingPayment(currTeam.id);
      actions.getFailedPayment(currTeam.id);
    }
    actions.onSetActiveTeam(teamId);
  }),

  /* Switching Teams */
  setSwitchingTeams: action((state) => {
    state.isSwitchingTeams = true;
  }),
  clearSwitchingTeams: action((state) => {
    state.isSwitchingTeams = false;
  }),
});

export default createContextStore(
  {
    ...model(),
  },
  {
    name: 'Teams',
    devTools: process.env.REACT_APP_REDUX_DEV_TOOLS_ENABLED === 'true',
  }
);
