import keycloak from 'auth/keycloak';
import { isArray, isObject } from 'lodash';

const errorResponseApiHandler = ({ status, body }) => {
  const apiError = {};
  const errorMessage = body.message || body.detail;
  if (errorMessage && !(isObject(errorMessage) || isArray(errorMessage))) {
    apiError.data = errorMessage;
  } else {
    apiError.data = 'Internal server error.';
  }
  apiError.status = status;

  return Promise.reject(apiError);
};

const get = async (url) => {
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${keycloak.token}`,
    },
  });
  /* read response and format errors */
  let body;
  if (response.status !== 204) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      body = await response.json();
    } else {
      body = await response.text();
    }
  }
  if (response.status >= 400) {
    return errorResponseApiHandler({ status: response.status, body });
  }
  return body;
};

const dlt = async (url, data = '', headers = {}) => {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${keycloak.token}`,
      ...headers,
    },
    body: data,
  });
  /* read response and format errors */
  let body;
  if (response.status !== 204) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      body = await response.json();
    } else {
      body = await response.text();
    }
  }
  if (response.status >= 400) {
    return errorResponseApiHandler({ status: response.status, body });
  }
  return body;
};

const post = async (url, data = '', headers = {}) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${keycloak.token}`,
      ...headers,
    },
    body: data,
  });
  /* read response and format errors */
  let body;
  if (response.status !== 204) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      body = await response.json();
    } else {
      body = await response.text();
    }
  }
  if (response.status >= 400) {
    return errorResponseApiHandler({ status: response.status, body });
  }
  return body;
};

const patch = async (url, data = '', headers = {}) => {
  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${keycloak.token}`,
      ...headers,
    },
    body: data,
  });
  /* read response and format errors */
  let body;
  if (response.status !== 204) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      body = await response.json();
    } else {
      body = await response.text();
    }
  }
  if (response.status >= 400) {
    return errorResponseApiHandler({ status: response.status, body });
  }
  return body;
};

const put = async (url, data = '', headers = {}) => {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${keycloak.token}`,
      ...headers,
    },
    body: data,
  });
  /* read response and format errors */
  let body;
  if (response.status !== 204) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      body = await response.json();
    } else {
      body = await response.text();
    }
  }
  if (response.status >= 400) {
    return errorResponseApiHandler({ status: response.status, body });
  }
  return body;
};

export default {
  get,
  dlt,
  post,
  patch,
  put,
};
