import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  info: {
    border: `1px solid ${theme.palette.info.main}`,
    padding: '1.1rem 1.4rem 0.8rem 1.7rem',
    color: theme.palette.info.main,
    borderRadius: '0.5rem',
    marginTop: '1.3rem',
    '& span': {
      fontWeight: 'bold',
    },
    width: 'fit-content',
  },
}));

const TextInfo = ({ children, className }) => {
  const classes = useStyles();
  return <div className={clsx(classes.info, className)}>{children}</div>;
};

export default TextInfo;
