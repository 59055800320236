export default {
  fontFamily: 'Khula, Arial, sans-serif',
  htmlFontSize: 10,
  h1: {
    fontSize: '2.4rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    position: 'relative',
    top: '0.2rem',
  },
  h2: {
    fontSize: '1.8rem',
    fontWeight: 400,
    position: 'relative',
    top: '0.2rem',
  },
  h3: {
    fontSize: '1.8rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    position: 'relative',
    top: '0.2rem',
  },
  h4: {
    fontSize: '1.4rem',
    textTransform: 'uppercase',
    fontWeight: 400,
    position: 'relative',
    top: '0.2rem',
  },
  h5: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    position: 'relative',
    top: '0.2rem',
  },
  h6: {
    fontSize: '1.4rem',
    fontWeight: 400,
    position: 'relative',
    top: '0.2rem',
  },
  body1: {
    fontSize: '1.4rem',
    fontWeight: 400,
    position: 'relative',
    top: '0.2rem',
  },
  body2: {
    fontSize: '1.2rem',
    fontWeight: 400,
    position: 'relative',
    top: '0.2rem',
  },
  smallTitle: {
    fontSize: '1rem',
    fontWeight: 400,
    textTransform: 'capitalize',
    position: 'relative',
    top: '0.2rem',
  },
};
