import React, { useEffect, useState, useRef } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import merge from 'lodash/merge';
import styled from 'styled-components';
import { prettifyShortDate } from 'utils/dates';

const FIXED_CHART_HEIGHT = 200;

const LineChart = ({ className, options }) => {
  const [chartObj, setChartObj] = useState();
  const [loadChart, setLoadChart] = useState(false);
  const ref = useRef();

  const chartOptions = {
    chart: {
      height: FIXED_CHART_HEIGHT,
      reflow: false,
      events: {
        render: (e) => {
          const xAxis = e.target.xAxis[0];
          const { ticks, min, max } = xAxis;
          if (ticks[min]?.label) {
            const firstTickLabel = ticks[min].label;
            firstTickLabel.translate(firstTickLabel.getBBox().width / 2, 0);
          }
          if (ticks[max]?.label) {
            const lastTickLabel = ticks[max].label;
            lastTickLabel.translate(-lastTickLabel.getBBox().width / 2, 0);
          }
        },
        load: (e) => setChartObj(e.target),
      },
      backgroundColor: 'transparent',
    },
    title: {
      style: {
        display: 'none',
        height: 0,
        width: 0,
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    yAxis: {
      visible: false,
      min: 0,
      minRange: 1,
      minPadding: 0,
      maxPadding: 0,
    },
    series: [
      {
        type: 'line',
        marker: {
          enabled: false,
        },
        pointStart: 0,
      },
    ],
    xAxis: {
      labels: {
        enabled: true,
        rotation: 0,
        step: 1,
        reserveSpace: true,
        padding: 2,
      },
      tickLength: 0,
      minPadding: 0,
      maxPadding: 0,
      startOnTick: true,
      endOnTick: true,
    },
    tooltip: {
      useHtml: true,
      formatter() {
        return (
          // eslint-disable-next-line react/no-this-in-sfc
          `<b>${prettifyShortDate(this.key)}</b>` +
          // eslint-disable-next-line react/no-this-in-sfc
          `<br/>${this.series.name}: <b>${this.y}</b>`
        );
      },
    },
  };

  const fullOptions = merge(chartOptions, options);

  useEffect(() => {
    let checkAvailableSize;
    let adjustToSize;
    if (chartObj && ref.current) {
      checkAvailableSize = setInterval(() => {
        const width = ref.current?.clientWidth;
        if (width && width !== chartObj.chartWidth) {
          adjustToSize = setTimeout(
            () => chartObj.setSize(width, FIXED_CHART_HEIGHT, true),
            300
          );
        }
      }, 500);
    }
    return () => {
      if (checkAvailableSize) {
        clearInterval(checkAvailableSize);
      }
      if (adjustToSize) {
        clearTimeout(adjustToSize);
      }
    };
  }, [chartObj]);

  useEffect(() => {
    setTimeout(() => setLoadChart(true), [800]);
  }, []);

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '100%',
        height: `${FIXED_CHART_HEIGHT}px`,
      }}
      ref={ref}
    >
      {loadChart && (
        <HighchartsReact
          highcharts={Highcharts}
          options={fullOptions}
          containerProps={{ className }}
        />
      )}
    </div>
  );
};

export default styled(LineChart)``;
