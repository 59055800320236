import upperCase from 'lodash/upperCase';

import httpClient from '../client';
import local from '../client/local';

export default async ({ teamId, engine = 'bitcoin', environment = 'LIVE' }) => {
  const queryParams = [];
  if (engine) queryParams.push(`engine=${engine}`);
  queryParams.push(`environment=${upperCase(environment)}`);

  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(
        `/api/teams/${teamId}/get_partner_engine/?${queryParams.join('&')}`
      );
    } else {
      response = await httpClient.get(
        `/api/teams/${teamId}/get_partner_engine/?${queryParams.join('&')}`
      );
    }
    return {
      schedule: response.schedule,
      nextIssuingDate: response.next_issuing_date,
      addressUrl: response.address_url,
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
