import React from 'react';

import notifications from 'stores/notifications';
import plans from 'stores/plans';
import profile from 'stores/profile';
import teams from 'stores/teams';

export const ProfileStoreProvider = ({ children }) => (
  <profile.Provider>{children}</profile.Provider>
);

export const PortalStoresProvider = ({ children }) => (
  <notifications.Provider>
    <plans.Provider>
      <teams.Provider>{children}</teams.Provider>
    </plans.Provider>
  </notifications.Provider>
);
