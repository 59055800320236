import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import {
  get as getUserHasVisitedPage,
  set as setUserHasVisitedPage,
} from 'storage/has_visited_page';
import teams from 'stores/teams';
import getScreenTips from 'utils/screen_tips';

const useOnboarding = () => {
  const [enabledTips, setEnabledTips] = useState(false);
  const [tips, setTips] = useState([]);
  const { pathname } = useLocation();
  const { teamUser, partner } = teams.useStoreState((st) => st);

  const showTips = () => setEnabledTips(true);
  const hideTips = () => {
    if (enabledTips) {
      setUserHasVisitedPage(pathname, true);
      setEnabledTips(false);
    }
  };

  useEffect(() => {
    let t;
    if (
      partner.LIVE &&
      !partner.LIVE.fetching &&
      partner.TEST &&
      !partner.TEST.fetching
    ) {
      const screenTips = getScreenTips({
        role: teamUser.role,
        pathname,
        hasLivePartner: partner.LIVE.fetched,
        hasTestPartner: partner.TEST.fetched,
      });
      if (screenTips && !getUserHasVisitedPage(pathname)) {
        t = setTimeout(() => setEnabledTips(true), 1000);
      }
      setTips(screenTips || []);
    }
    return () => clearTimeout(t);
  }, [pathname, teamUser.role, partner.LIVE, partner.TEST]);

  return {
    enabledTips,
    tips,
    showTips,
    hideTips,
  };
};

export default useOnboarding;
