import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Party = (props) => (
  <SvgIcon
    style={{ width: '36px', height: '35px' }}
    {...props}
    viewBox="0 0 36 35"
  >
    <svg width={36} height={35} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.313 14.73a1.504 1.504 0 100-3.007 1.504 1.504 0 000 3.007zM22.04 10.928a1.504 1.504 0 100-3.008 1.504 1.504 0 000 3.008zM9.25 3.691a1.504 1.504 0 100-3.007 1.504 1.504 0 000 3.007zM25.24 25.926L2.522 34.253a.994.994 0 01-1.276-1.275L9.574 10.26a.994.994 0 011.636-.36L25.6 24.29a.994.994 0 01-.36 1.636z"
        fill="none"
      />
      <path
        d="M3.208 27.677l-.029.029-1.933 5.272a.994.994 0 001.276 1.275l5.314-1.948-4.628-4.628zM4.542 24.397l6.561 6.56 6.049-2.251L6.794 18.348l-2.252 6.049zM7.873 32.292l-4.665-4.665 1.334-3.23 6.561 6.56-3.23 1.335z"
        fill="none"
      />
      <path
        d="M17.152 28.706L6.794 18.348l1.184-3.38 12.554 12.554-3.38 1.184z"
        fill="none"
      />
      <path
        d="M34.15 19.511a.684.684 0 00-.283-.924 10.72 10.72 0 00-11.822 1.18l-1.105-1.104 1.804-1.804a.684.684 0 00-.967-.967l-1.804 1.804-4.4-4.401a9.547 9.547 0 001.829-4.599 9.502 9.502 0 00-1.053-5.599.684.684 0 00-1.208.642 8.141 8.141 0 01-.547 8.577l-2.9-2.9a1.67 1.67 0 00-1.548-.452 1.67 1.67 0 00-1.214 1.061L.605 32.743a1.661 1.661 0 00.389 1.764 1.662 1.662 0 001.763.388l22.718-8.327a1.67 1.67 0 001.06-1.214 1.67 1.67 0 00-.451-1.547l-3.067-3.067a9.352 9.352 0 0110.208-.946c.334.177.747.05.925-.283zM11.388 30.276l-6.163-6.164 1.707-4.659 9.115 9.115-4.659 1.707zM8.048 31.5L4 27.453l.706-1.926 5.267 5.267-1.926.706zm-5.761 2.112a.297.297 0 01-.327-.072.297.297 0 01-.072-.326l1.593-4.346 3.151 3.15-4.345 1.594zM25.2 25.06a.302.302 0 01-.197.224l-4.202 1.54-3.888-3.888a.684.684 0 00-.967.967l3.44 3.44-1.925.706L7.45 18.039l.706-1.926 3.44 3.44a.682.682 0 00.967 0 .684.684 0 000-.967l-3.888-3.888 1.54-4.202a.302.302 0 01.225-.197.297.297 0 01.286.084l14.39 14.39a.302.302 0 01.084.287zM21.117 5.455a.684.684 0 00.683-.683 1.04 1.04 0 011.039-1.038 2.408 2.408 0 002.405-2.406.684.684 0 00-1.367 0 1.04 1.04 0 01-1.038 1.038 2.408 2.408 0 00-2.406 2.406c0 .377.306.683.684.683zM27.182 11.908a2.487 2.487 0 002.484-2.484c0-.616.501-1.117 1.117-1.117a.684.684 0 000-1.368A2.487 2.487 0 0028.3 9.424c0 .616-.501 1.117-1.117 1.117a.684.684 0 100 1.367zM31.125 13.227a2.19 2.19 0 002.188 2.187 2.19 2.19 0 002.187-2.187 2.19 2.19 0 00-2.188-2.188 2.19 2.19 0 00-2.187 2.188zm2.188-.82a.821.821 0 010 1.64.821.821 0 010-1.64z"
        fill="currentColor"
      />
      <path
        d="M22.04 11.61a2.19 2.19 0 002.188-2.187 2.19 2.19 0 00-2.188-2.187 2.19 2.19 0 00-2.187 2.187 2.19 2.19 0 002.188 2.188zm0-3.007a.821.821 0 11-.001 1.642.821.821 0 01.002-1.642zM9.25 4.375a2.19 2.19 0 002.188-2.188A2.19 2.19 0 009.25 0a2.19 2.19 0 00-2.188 2.188A2.19 2.19 0 009.25 4.374zm0-3.008a.821.821 0 010 1.64.821.821 0 010-1.64zM31.433 23.998a.684.684 0 00-.967.967l.645.644a.681.681 0 00.966 0 .684.684 0 000-.967l-.644-.644zM34.656 27.22a.684.684 0 00-.967.967l.644.644a.682.682 0 00.967 0 .684.684 0 000-.966l-.644-.645zM34.333 23.998l-.644.645a.684.684 0 00.967.966l.644-.644a.684.684 0 00-.967-.967zM31.11 27.22l-.644.645a.684.684 0 10.967.966l.644-.644a.684.684 0 00-.966-.967zM34.172 2.012c.175 0 .35-.067.484-.2l.644-.645A.684.684 0 1034.333.2l-.644.645a.684.684 0 00.483 1.167zM30.95 5.234c.174 0 .35-.067.483-.2l.644-.645a.684.684 0 10-.967-.967l-.644.645a.684.684 0 00.483 1.167zM34.656 3.422a.684.684 0 00-.967.967l.644.645a.682.682 0 00.967 0 .684.684 0 000-.967l-.644-.645zM31.11 1.811a.681.681 0 00.967 0 .684.684 0 000-.966L31.433.2a.684.684 0 00-.967.967l.644.644zM14.255 20.561a.689.689 0 00-.483.2.689.689 0 00-.2.484c0 .18.073.356.2.483s.304.2.483.2c.18 0 .356-.073.484-.2a.689.689 0 00.2-.483.689.689 0 00-.2-.484.689.689 0 00-.484-.2zM24.229 13.44l-.496.496a.684.684 0 00.967.967l.495-.496a.684.684 0 00-.966-.967z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default Party;
