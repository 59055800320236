import { useEffect } from 'react';

import useQueryParams from 'hooks/useQueryParams';
import { isNumber, isString } from 'lodash';
import { useHistory } from 'react-router-dom';
import teams from 'stores/teams';

const useSwitchTeam = () => {
  const history = useHistory();
  const { setActiveTeam } = teams.useStoreActions((act) => act);
  const queryParams = useQueryParams();
  const switchTeam = queryParams.get('team');

  useEffect(() => {
    if (switchTeam) {
      if (isString(switchTeam)) {
        setActiveTeam(Number(switchTeam, 10));
      } else if (isNumber(switchTeam)) {
        setActiveTeam(switchTeam);
      }

      queryParams.delete('team');
      history.replace({
        pathname: history.location.pathname,
        search: queryParams.toString(),
      });
    }
  }, [switchTeam]);

  return null;
};

export default useSwitchTeam;
