import React, { forwardRef } from 'react';

import { SvgIcon } from '@material-ui/core';
import AlertCircleIcon from 'assets/icons/notifications/alert_circle';
import { motion } from 'framer-motion';

const ErrorAlertIcon = motion(AlertCircleIcon);

const Error = forwardRef((props, ref) => (
  <ErrorAlertIcon
    initial={{ scale: 0 }}
    animate={{ scale: 1 }}
    exit={{ scale: 0, opacity: 0 }}
    {...props}
    {...ref}
  />
));

export default (props) => (
  <SvgIcon component={Error} viewBox="0 0 24 24" {...props} />
);
