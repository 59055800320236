import httpClient from '../client';
import local from '../client/local';

const transformChains = (chains) => {
  const chainsResult = {};
  if (chains)
    Object.keys(chains).forEach((key) => {
      chainsResult[key] = {
        number: chains[key].number,
        totalCost: chains[key].total_cost,
        transactions: chains[key].transactions.map((tx) => ({
          date: tx.date,
          txUrl: tx.tx_url,
          costType: tx.cost_type,
          costGbp: tx.cost_gbp,
        })),
      };
    });
  return chainsResult;
};

export default async (teamId) => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(`/api/teams/${teamId}/get_upcoming_payment/`);
    } else {
      response = await httpClient.get(
        `/api/teams/${teamId}/get_upcoming_payment/`
      );
    }

    return {
      beginSubscription: response.begin_of_subscription,
      endSubscription: response.end_of_subscription,
      validity: response.validity,
      subscriptionCost: response.subscription_cost,
      totalCost: response.total_cost,
      extraCostsRenewalDate: response.renewal_date,
      extraCostsData: {
        totalCost: response.extra_costs_data?.total_cost,
        requests: {
          number: response.extra_costs_data?.requests?.number,
          pricePerRequest:
            response.extra_costs_data?.requests?.price_per_request,
          totalCost: response.extra_costs_data?.requests?.total_cost,
        },
        blockchain: {
          number: response.extra_costs_data?.blockchain?.number,
          totalCost: response.extra_costs_data?.blockchain?.total_cost,
          chains: transformChains(
            response.extra_costs_data?.blockchain?.chains
          ),
        },
      },
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
