import httpClient from '../client';
import local from '../client/local';

export default async ({ teamId }) => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(
        `/api/teams/${teamId}/get_partner_subscription_requests/`
      );
    } else {
      response = await httpClient.get(
        `/api/teams/${teamId}/get_partner_subscription_requests/`
      );
    }
    return {
      subscriptionRequests: response.subscription_requests,
      extraRequests: response.extra_requests,
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
