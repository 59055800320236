import React, { useState, useEffect, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { getNavByRole } from 'routes/nav_links';
import teams from 'stores/teams';
import styled from 'styled-components';

import MainMenuButton from './MainMenuButton';
import SubMenuList from './SubMenuList';

const useStyles = makeStyles((theme) => ({
  menu: {
    display: 'flex',
    height: '100%',
    position: 'relative',
    width: 'min-content',
    paddingRight: '2rem',
    zIndex: theme.zIndex.drawer,
  },
  list: {
    backgroundColor: theme.palette.secondary.dark,
    width: theme.nav.width,
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    width: '100%',
    padding: '3.9rem 4.5rem 5.4rem 4.5rem',
  },
}));

const Sidebar = styled(({ className }) => {
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState();
  const [showEntriesByButton, setShowEntriesByButton] = useState();
  const location = useLocation();
  const { teamUser, upcomingPayment, activeTeam, failedPayment } =
    teams.useStoreState((st) => st);

  useEffect(() => {
    const found = getNavByRole(teamUser.role).find(
      (item) =>
        item.sub &&
        item.sub.find((subItem) => location.pathname.includes(subItem.path))
    );
    if (found) {
      setShowEntriesByButton(found.id);
      setActiveButton(found.id);
    } else {
      setShowEntriesByButton(null);
      setActiveButton(null);
    }
  }, [location.pathname, teamUser.role]);

  const toggleExpand = (item) => {
    if (showEntriesByButton === item.id) {
      setShowEntriesByButton(null);
    } else if (item.sub) {
      setShowEntriesByButton(item.id);
    }
  };

  return (
    <nav data-testid="navigation" className={clsx(classes.menu, className)}>
      <div className={classes.list}>
        <img
          className={classes.logo}
          src={`${process.env.PUBLIC_URL}/images/light_logo.svg`}
          alt="vdx-logo"
        />
        {getNavByRole(teamUser.role, {
          teamId: activeTeam.id,
          checkingPendingPayment:
            failedPayment.fetching || upcomingPayment.fetching,
          hasPendingPayment:
            upcomingPayment.fetched && upcomingPayment.result.totalCost > 0,
          hasFailedPendingPayment: activeTeam.hasUnpaidRequests,
        }).map(
          (item) =>
            !item.disabled && (
              <Fragment key={item.id}>
                <MainMenuButton
                  {...item}
                  onClick={toggleExpand}
                  isActive={activeButton === item.id}
                  isOpen={showEntriesByButton === item.id}
                />
                <SubMenuList
                  {...item}
                  isOpen={showEntriesByButton === item.id}
                />
              </Fragment>
            )
        )}
      </div>
    </nav>
  );
})``;

export default Sidebar;
