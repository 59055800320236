import React, { forwardRef } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const AlertCircle = forwardRef((props, ref) => (
  <SvgIcon {...props} viewBox="0 0 19 20" ref={ref}>
    <svg width={19} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={9.5} cy={9.5} r={9.5} fill="currentColor" />
      <path
        d="M10.848 13.168c0 .368-.108.658-.323.868-.21.21-.507.315-.892.315-.379 0-.676-.103-.891-.307-.21-.205-.315-.497-.315-.877 0-.379.102-.668.307-.868.21-.2.51-.3.9-.3.389 0 .688.103.898.308.21.205.316.492.316.86Zm0-9.114-.354 6.762H8.811l-.353-6.762h2.39Z"
        fill="#fff"
      />
    </svg>
  </SvgIcon>
));

export default AlertCircle;
