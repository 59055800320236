import { createContextStore, action } from 'easy-peasy';

const model = () => ({
  notifications: [],
  unreadCount: 0,

  add: action((state, notif) => {
    const notifDate = notif.timestamp.splice(0, 6);
    notifDate[1] -= 1; // month index operates differently (starts at 0)
    const existingNotifIndex = state.notifications.findIndex(
      (i) => i.uuid === notif.uuid
    );
    const newDate = new Date(...notifDate);

    if (existingNotifIndex >= 0) {
      const updated = [...state.notifications];
      updated[existingNotifIndex] = {
        ...notif,
        timestamp: newDate,
      };
      state.notifications = updated;
    } else if (
      state.notifications.length > 0 &&
      newDate >= state.notifications[0].timestamp
    ) {
      state.notifications = [
        { ...notif, timestamp: newDate },
        ...state.notifications,
      ];
    } else {
      state.notifications = [
        ...state.notifications,
        { ...notif, timestamp: newDate },
      ];
    }
    if (!notif.read) state.unreadCount += 1;
  }),
  read: action((state, uuid) => {
    state.notifications = state.notifications.map((i) => {
      if (i.uuid === uuid) {
        if (!i.read) state.unreadCount -= 1;
        return {
          ...i,
          read: true,
        };
      }
      return i;
    });
  }),
  readAll: action((state) => {
    state.notifications = state.notifications.map((i) => ({
      ...i,
      read: true,
    }));
    state.unreadCount = 0;
  }),
});

export default createContextStore(
  {
    ...model(),
  },
  {
    name: 'Notifications',
    devTools: process.env.REACT_APP_REDUX_DEV_TOOLS_ENABLED === 'true',
  }
);
