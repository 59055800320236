import React, { useEffect, useRef, useState } from 'react';

import { alpha, FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronDownIcon from 'assets/icons/chevrons/chevron_down';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: '20px',
    '& .MuiInputBase-root': {
      height: '100%',
      '&:before': {
        content: 'none',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiInput-underline:after': {
      content: 'none',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: 'transparent',
    },
    minHeight: '4rem',
  },
  selectArrow: {
    width: '1.6rem',
    height: '1.6rem',
    top: '50%',
    right: 0,
    transform: 'translate(-1.4rem, -40%)',
    color: theme.palette.text.primary,
    '&.Mui-disabled': {
      color: alpha(
        theme.palette.text.primary,
        theme.palette.action.disabledOpacity
      ),
    },
  },
  select: {
    '& .MuiSelect-root': {
      height: ({ selectHeight }) =>
        selectHeight ? `${selectHeight}px` : '100%',
      display: 'flex',
      alignItems: 'center',
      paddingRight: 'calc(24px + 2rem)',
      paddingLeft: '2rem',
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      '&.Mui-disabled': {
        color: theme.palette.text.primary,
      },
    },
  },
  popoverPaper: {
    borderRadius: '2rem',
    border: `1px solid ${theme.palette.text.primary}`,
    boxSizing: 'content-box',
    transform: 'translate(-0.5rem, -0.3rem) !important',
    width: ({ popoverPaperWidth }) =>
      popoverPaperWidth ? `calc(${popoverPaperWidth}px + 1px)` : 'auto',
    '& ul': {
      overflow: 'hidden',
      padding: 0,
      '& li': {
        top: 0,
        color: theme.palette.text.primary,
        paddingTop: '0.8rem',
        paddingBottom: '0.8rem',
        '&:first-child': {
          paddingTop: '1rem',
        },
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.text.primary,
            theme.palette.action.hoverOpacity
          ),
        },
        '&.Mui-selected': {
          backgroundColor: `${theme.palette.shades.secondary[400]} !important`,
          color: theme.palette.shades.secondary[800],
        },
      },
    },
  },
}));

const Dropdown = ({ value, onChange, options, disabled, className }) => {
  const dropRef = useRef();
  const [popoverWidth, setPopoverWidth] = useState();
  const [closedDropdownHeight, setClosedDropdownHeight] = useState();
  const classes = useStyles({
    popoverPaperWidth: popoverWidth,
    selectHeight: closedDropdownHeight,
  });

  const handleChange = (e) => {
    if (onChange) onChange(options.find((role) => role.id === e.target.value));
    e.stopPropagation();
  };

  const onClick = (e) => e.stopPropagation();

  useEffect(() => {
    setPopoverWidth(dropRef.current.clientWidth);
  }, [value]);

  useEffect(() => {
    setClosedDropdownHeight(dropRef.current.clientHeight);
  }, []);

  return (
    <FormControl
      className={clsx(classes.dropdown, className)}
      ref={dropRef}
      disabled={disabled}
    >
      <Select
        value={value}
        onChange={handleChange}
        onClick={onClick}
        IconComponent={ChevronDownIcon}
        classes={{
          icon: classes.selectArrow,
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PopoverClasses: {
            paper: classes.popoverPaper,
          },
        }}
        className={classes.select}
      >
        {options.map(({ id, label }) => (
          <MenuItem key={id || 'placeholder'} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
