import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Loading = styled(({ className, ...props }) => (
  <motion.div
    animate={{ rotate: 360 }}
    transition={{ repeat: Infinity, duration: 1 }}
    className={className}
  >
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
      >
        <path d="M12 4V2A10 10 0 002 12h2a8 8 0 018-8z" />
      </svg>
    </SvgIcon>
  </motion.div>
))`
  height: 2.4rem;
`;

export default Loading;
