import httpClient from '../client';
import local from '../client/local';

export default async (teamId) => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(`/api/teams/${teamId}/get_credit_card_info/`);
    } else {
      response = await httpClient.get(
        `/api/teams/${teamId}/get_credit_card_info/`
      );
    }

    return {
      brand: response.brand,
      expMonth: response.exp_month,
      expYear: response.exp_year,
      funding: response.funding,
      last4: response.last4,
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
