import httpClient from '../client';
import local from '../client/local';

const transformChains = (chains) => {
  const chainsResult = {};
  if (chains)
    Object.keys(chains).forEach((key) => {
      chainsResult[key] = {
        number: chains[key].number,
        totalCost: chains[key].total_cost,
        transactions: chains[key].transactions.map((tx) => ({
          date: tx.date,
          txUrl: tx.tx_url,
          costType: tx.cost_type,
          costGbp: tx.cost_gbp,
        })),
      };
    });
  return chainsResult;
};

export default async (teamId) => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(
        `/api/teams/${teamId}/get_latest_subscription/`
      );
    } else {
      response = await httpClient.get(
        `/api/teams/${teamId}/get_latest_subscription/`
      );
    }

    let payment;
    if (response.payment) {
      payment = {};
      payment.status = response.payment.status;
      payment.paymentBroker = response.payment.payment_broker;
      payment.value = response.payment.value;
      payment.creditValue = response.payment.credit_value;
      payment.invoice = response.payment.invoice;
      payment.extraCostsData = {
        totalCost: response.payment.extra_costs_data?.total_cost,
        requests: {
          number: response.payment.extra_costs_data?.requests?.number,
          pricePerRequest:
            response.payment.extra_costs_data?.requests?.price_per_request,
          totalCost: response.payment.extra_costs_data?.requests?.total_cost,
        },
        blockchain: {
          number: response.payment.extra_costs_data?.blockchain?.number,
          totalCost: response.payment.extra_costs_data?.blockchain?.total_cost,
          chains: transformChains(
            response.payment.extra_costs_data?.blockchain?.chains
          ),
        },
      };
    }

    let scheduledDowngrade;
    if (response.downgradesubscription) {
      scheduledDowngrade = {};
      scheduledDowngrade.plan = {};
      scheduledDowngrade.plan.name = response.downgradesubscription.plan.name;
      scheduledDowngrade.plan.pricePounds =
        response.downgradesubscription.plan.price_pounds;
      scheduledDowngrade.plan.validity =
        response.downgradesubscription.plan.validity;
      scheduledDowngrade.plan.annualPrice =
        response.downgradesubscription.plan.annual_price;
    }

    return {
      name: response.plan?.name,
      pricePounds: response.plan?.price_pounds,
      validity: response.plan?.validity,
      annualPrice: response.plan?.annual_price,
      status: response.status,
      beginSubscription: response.begin_of_subscription,
      endSubscription: response.end_of_subscription,
      createdAt: response.created_at,
      payment,
      scheduledDowngrade,
      toBeCancelled: response.to_be_cancelled,
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
