import { formatISO } from 'date-fns';

import httpClient from '../client';
import local from '../client/local';

const transformChains = (chains) => {
  const chainsResult = {};
  if (chains)
    Object.keys(chains).forEach((key) => {
      chainsResult[key] = {
        number: chains[key].number,
        totalCost: chains[key].total_cost,
        transactions: chains[key].transactions.map((tx) => ({
          date: tx.date,
          txUrl: tx.tx_url,
          costType: tx.cost_type,
          costGbp: tx.cost_gbp,
        })),
      };
    });
  return chainsResult;
};

export default async ({ teamId, params = { page: 1 } }) => {
  try {
    let response;
    const queryParams = [`page=${params.page || 1}`];
    if (params.plan) queryParams.push(`plan_name=${params.plan}`);
    if (params.validity) queryParams.push(`validity=${params.validity}`);
    if (params.fromDate)
      queryParams.push(
        `from_date=${formatISO(params.fromDate, { representation: 'date' })}`
      );
    if (params.toDate)
      queryParams.push(
        `to_date=${formatISO(params.toDate, { representation: 'date' })}`
      );
    if (params.displayId) queryParams.push(`display_id=${params.displayId}`);

    if (process.env.NODE_ENV === 'local') {
      response = await local.get(
        `/api/teams/${teamId}/get_all_subscription_payments_info/?${queryParams.join(
          '&'
        )}`
      );
    } else {
      response = await httpClient.get(
        `/api/teams/${teamId}/get_all_subscription_payments_info/?${queryParams.join(
          '&'
        )}`
      );
    }

    return {
      count: response.count,
      items: response.items.map((item) => ({
        displayId: item.display_id,
        plan: {
          name: item.plan.name,
          pricePounds: item.plan.price_pounds,
          validity: item.plan.validity,
          annualPrice: item.plan.annual_price,
        },
        status: item.status,
        beginSubscription: item.begin_of_subscription,
        endSubscription: item.end_of_subscription,
        createdAt: item.created_at,
        payment: {
          status: item.payment?.status,
          paymentBroker: item.payment?.payment_broker,
          value: item.payment?.value,
          creditValue: item.payment?.credit_value,
          invoice: item.payment?.invoice,
          date: item.payment?.date,
          extraCostsData: {
            totalCost: item.payment?.extra_costs_data?.total_cost,
            requests: {
              number: item.payment?.extra_costs_data?.requests?.number,
              pricePerRequest:
                item.payment?.extra_costs_data?.requests?.price_per_request,
              totalCost: item.payment?.extra_costs_data?.requests?.total_cost,
            },
            blockchain: {
              number: item.payment?.extra_costs_data?.blockchain?.number,
              totalCost: item.payment?.extra_costs_data?.blockchain?.total_cost,
              chains: transformChains(
                item.payment?.extra_costs_data?.blockchain?.chains
              ),
            },
          },
        },
      })),
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
