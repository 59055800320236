import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Switch from 'components/molecules/Switch/Values';
import { useThemeContext } from 'theme/context';

const useStyles = makeStyles((theme) => ({
  switch: {
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      position: 'relative',
    },
    '& .MuiSwitch-thumb:before': {
      transition:
        'background-color 0.1s linear, width 0.2s linear, height 0.2s linear',
      content: "''",
      borderRadius: '50%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  dark: {
    '& .MuiSwitch-thumb:before': {
      width: '1.2rem',
      height: '1.2rem',
      backgroundColor: theme.palette.primary.main,
      left: '40%',
      transform: 'translateY(-50%)',
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  light: {
    '& .MuiSwitch-thumb:before': {
      backgroundColor: 'transparent',
      left: '50%',
      width: 0,
      height: 0,
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.shades.secondary[100],
    },
  },
  label: {
    ...theme.typography.smallTitle,
  },
  lightLabel: {
    color: theme.palette.shades.secondary[800],
  },
  darkLabel: {
    color: theme.palette.shades.secondary[500],
  },
}));

const ThemeSwitch = () => {
  const classes = useStyles();
  const { isDarkMode, setLightMode, setDarkMode } = useThemeContext();

  const changeTheme = (value) => {
    if (value === 'Dark') {
      setDarkMode();
    } else {
      setLightMode();
    }
  };

  return (
    <Switch
      left="Dark"
      right="Light"
      value={isDarkMode ? 'Dark' : 'Light'}
      onChange={changeTheme}
      className={clsx(
        classes.switch,
        isDarkMode && classes.dark,
        !isDarkMode && classes.light
      )}
      classes={{
        leftLabel: classes.darkLabel,
        rightLabel: classes.lightLabel,
      }}
      data-testid="theme-switcher"
    />
  );
};

export default ThemeSwitch;
