export default {
  nav: {
    width: '15rem',
  },
  main: {
    paddingLeft: '3rem',
    paddingRight: '4rem',
    paddingTop: '3rem',
    paddingBottom: '15rem',
  },
};
