import keycloak from 'auth/keycloak';
import axios from 'axios';
import { stringify } from 'query-string';

import errorHandler from './handlers/error_handler';
import errorResponseApiHandler from './handlers/error_response_api_handler';

const instance = axios.create();

instance.defaults.paramsSerializer = (query) => stringify(query);
instance.defaults.baseURL = process.env.REACT_APP_API_URL;
instance.defaults.maxRedirects = 0;
instance.defaults.headers.common.Accept = 'application/json';
instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

instance.interceptors.request.use((config) => {
  if (keycloak?.token) {
    config.headers.Authorization = `Bearer ${keycloak.token}`;
  }
  return config;
});

instance.interceptors.response.use(
  undefined,
  errorHandler(errorResponseApiHandler, (status) => status >= 400)
);

instance.interceptors.response.use((response) => response.data);

export default instance;
