import React from 'react';

import SuspendedPlan from 'components/app/Alerts/SuspendedPlan';
import TeamInDeletion from 'components/app/Alerts/TeamInDeletion';
import Welcome from 'components/app/Alerts/Welcome';
import useSwitchTeam from 'hooks/useSwitchTeam';

export default () => {
  useSwitchTeam();
  return (
    <>
      <Welcome />
      <SuspendedPlan />
      <TeamInDeletion />
    </>
  );
};
