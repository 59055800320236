import httpClient from '../client';
import local from '../client/local';

export default async () => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.dlt('/api/profile/delete_profile_picture/');
    } else {
      response = await httpClient.delete(
        '/api/profile/delete_profile_picture/'
      );
    }
    return response;
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
