import React, { Children, useState, useEffect } from 'react';

import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PartyIllust from 'assets/illustrations/party';
import clsx from 'clsx';
import { PrimaryButton, Heading5 } from 'components/atoms';

import Dialog from './Dialog';

const useStyles = makeStyles((theme) => ({
  illust: {
    color: theme.palette.success.main,
    marginBottom: '1rem',
    display: 'flex',
    alignSelf: 'center',
  },
  title: {
    color: theme.palette.success.main,
    textAlign: 'center',
  },
  content: {
    marginTop: '1.5rem',
    ...theme.typography.body1,
    '& p': {
      margin: 0,
    },
    '& .emph': {
      color: theme.palette.success.main,
    },
  },
  paragraph: {
    textAlign: 'center',
    marginTop: '1.5rem',
    ...theme.typography.body1,
    '& p': {
      margin: 0,
    },
    '& .emph': {
      color: theme.palette.success.main,
    },
  },
  button: {
    marginTop: '2.5rem',
  },
}));

const SuccessDialog = ({
  open,
  onClose,
  title,
  children,
  buttonProps = {},
  buttonLabel,
  loading,
  useInternalState = true,
}) => {
  const [internalState, setInternalState] = useState({});
  const classes = useStyles();

  useEffect(() => {
    if (open)
      setInternalState({
        title,
        buttonLabel,
        children,
      });
  }, [open, loading]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <>
        <PartyIllust className={classes.illust} />
        <Heading5 className={classes.title}>
          {useInternalState ? internalState.title : title}
        </Heading5>
        {loading && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.content}
          >
            <CircularProgress color="secondary" />
          </Grid>
        )}
        {!loading && (
          <div>
            {useInternalState &&
              Children.map(internalState.children, (item) =>
                item.type === 'div' ? (
                  <div className={classes.paragraph}>{item.props.children}</div>
                ) : (
                  <div className={classes.content}>{item}</div>
                )
              )}
            {!useInternalState &&
              Children.map(children, (item) =>
                item.type === 'div' ? (
                  <div className={classes.paragraph}>{item.props.children}</div>
                ) : (
                  <div className={classes.content}>{item}</div>
                )
              )}
          </div>
        )}
        {useInternalState && internalState.buttonLabel && (
          <Grid container justifyContent="center">
            <Grid item>
              <PrimaryButton
                {...buttonProps}
                color="secondary"
                className={clsx(classes.button, buttonProps.className)}
              >
                {internalState.buttonLabel}
              </PrimaryButton>
            </Grid>
          </Grid>
        )}
        {!useInternalState && buttonLabel && (
          <Grid container justifyContent="center">
            <Grid item>
              <PrimaryButton
                {...buttonProps}
                color="secondary"
                className={clsx(classes.button, buttonProps.className)}
              >
                {buttonLabel}
              </PrimaryButton>
            </Grid>
          </Grid>
        )}
      </>
    </Dialog>
  );
};

export default SuccessDialog;
