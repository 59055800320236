import { alpha } from '@material-ui/core/styles/colorManipulator';

import commonAppStyles from '../common/app_styles';
import shadows from '../common/shadows';
import typography from '../common/typography';
import colors from './colors';

export default {
  palette: {
    ...colors,
    action: {
      disabledTextColor: colors.shades.secondary[600],
    },
    divider: colors.divider.color,
  },
  typography,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '62.5%' /* 62.5% de 16px = 10px */,
          width: '100vw',
          overflowX: 'hidden',
          height: '100%',
          backgroundColor: colors.background.default,
        },
        a: {
          color: colors.primary.main,
          textDecoration: 'none',
          fontWeight: 600,
          cursor: 'pointer',
        },
        body: {
          top: 0,
        },
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 5,
        '& .MuiPaper-root': {
          /* any child paper inside of paper has a different bg */
          backgroundColor: colors.background.default,
        },
      },
      elevation1: {
        boxShadow: shadows.paperShadow,
      },
      elevation2: {
        boxShadow: shadows.paperShadow,
      },
      elevation3: {
        boxShadow: shadows.paperShadow,
      },
      elevation4: {
        boxShadow: shadows.paperShadow,
      },
      elevation5: {
        boxShadow: shadows.paperShadow,
      },
      elevation6: {
        boxShadow: shadows.paperShadow,
      },
      elevation7: {
        boxShadow: shadows.paperShadow,
      },
      elevation8: {
        boxShadow: shadows.paperShadow,
      },
      elevation9: {
        boxShadow: shadows.paperShadow,
      },
      elevation10: {
        boxShadow: shadows.paperShadow,
      },
      elevation11: {
        boxShadow: shadows.paperShadow,
      },
      elevation12: {
        boxShadow: shadows.paperShadow,
      },
      elevation13: {
        boxShadow: shadows.paperShadow,
      },
      elevation14: {
        boxShadow: shadows.paperShadow,
      },
      elevation15: {
        boxShadow: shadows.paperShadow,
      },
      elevation16: {
        boxShadow: shadows.paperShadow,
      },
      elevation17: {
        boxShadow: shadows.paperShadow,
      },
      elevation18: {
        boxShadow: shadows.paperShadow,
      },
      elevation19: {
        boxShadow: shadows.paperShadow,
      },
      elevation20: {
        boxShadow: shadows.paperShadow,
      },
      elevation21: {
        boxShadow: shadows.paperShadow,
      },
      elevation22: {
        boxShadow: shadows.paperShadow,
      },
      elevation23: {
        boxShadow: shadows.paperShadow,
      },
      elevation24: {
        boxShadow: shadows.paperShadow,
      },
    },
    MuiTabs: {
      root: {
        minHeight: 0,
        color: colors.shades.secondary[700],
      },
    },
    MuiTab: {
      root: {
        minHeight: 0,
        paddingBottom: '0.5rem',
        padding: 0,
        textTransform: 'capitalize',
        minWidth: '0 !important',
        marginRight: '6rem',
      },
      textColorInherit: {
        opacity: 0.3,
      },
    },
    PrivateTabIndicator: {
      root: {
        width: '3rem !important',
      },
      colorSecondary: {
        backgroundColor: colors.shades.primary[400],
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        borderRadius: '2rem',
        padding: '0.8rem 1.5rem 0.6rem 1.5rem',
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          color: colors.shades.secondary[800],
          border: `1px solid ${colors.shades.secondary[800]}`,
        },
        overflow: 'hidden',
      },
      sizeSmall: {
        padding: '0.6rem 0.8rem 0.4rem 0.8rem',
      },
      outlined: {
        padding: '0.8rem 1.5rem 0.6rem 1.5rem',
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          color: colors.shades.secondary[800],
          border: `1px solid ${colors.shades.secondary[800]}`,
        },
      },
      outlinedPrimary: {
        color: colors.secondary.dark,
        border: `1px solid ${colors.shades.secondary[400]}`,
        backgroundColor: 'transparent',
        '&:hover': {
          border: `1px solid ${colors.shades.secondary[400]}`,
          backgroundColor: colors.shades.secondary[400],
          color: colors.secondary.dark,
        },
      },
      outlinedSecondary: {
        color: colors.primary.main,
        border: `1px solid ${colors.primary.main}`,
        backgroundColor: 'transparent',
        '&:hover': {
          border: `1px solid ${colors.primary.main}`,
          backgroundColor: colors.primary.main,
          color: colors.primary.contrastText,
        },
      },
      contained: {
        padding: '0.8rem 1.5rem 0.6rem 1.5rem',
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          color: colors.shades.secondary[800],
          border: `1px solid ${colors.shades.secondary[800]}`,
        },
      },
      containedPrimary: {
        color: colors.secondary.dark,
        border: `1px solid ${colors.shades.secondary[400]}`,
        backgroundColor: colors.shades.secondary[400],
        '&:hover': {
          backgroundColor: colors.shades.secondary[400],
          color: colors.secondary.dark,
          border: `1px solid ${colors.shades.secondary[400]}`,
        },
      },
      containedSecondary: {
        color: colors.primary.contrastText,
        border: `1px solid ${colors.primary.main}`,
        backgroundColor: colors.primary.main,
        '&:hover': {
          backgroundColor: colors.primary.main,
          color: colors.primary.contrastText,
          border: `1px solid ${colors.primary.main}`,
        },
      },
      text: {
        padding: '0.8rem 1.5rem 0.6rem 1.5rem',
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          color: colors.shades.secondary[800],
          border: `1px solid ${colors.shades.secondary[800]}`,
        },
      },
      textPrimary: {
        color: colors.secondary.contrastText,
        border: `1px solid ${colors.secondary.main}`,
        backgroundColor: colors.secondary.main,
        '&:hover': {
          backgroundColor: colors.secondary.main,
          color: colors.secondary.contrastText,
          border: `1px solid ${colors.secondary.main}`,
        },
      },
      textSecondary: {
        color: colors.primary.contrastText,
        border: `1px solid ${colors.primary.main}`,
        backgroundColor: colors.primary.main,
        '&:hover': {
          backgroundColor: colors.primary.main,
          color: colors.primary.contrastText,
          border: `1px solid ${colors.primary.main}`,
        },
      },
      containedSizeSmall: {
        padding: '0.6rem 0.8rem 0.4rem 0.8rem',
      },
      outlinedSizeSmall: {
        padding: '0.6rem 0.8rem 0.4rem 0.8rem',
      },
      textSizeSmall: {
        padding: '0.6rem 0.8rem 0.4rem 0.8rem',
      },
      startIcon: {
        '& > svg': {
          width: '1.6rem',
          height: '1.6rem',
          position: 'relative',
          top: -2,
        },
      },
      endIcon: {
        '& > svg': {
          width: '1.6rem',
          height: '1.6rem',
          position: 'relative',
          top: -2,
        },
      },
      label: {
        position: 'relative',
        top: '0.1rem',
      },
    },
    MuiInputBase: {
      root: {
        minHeight: '3rem',
        paddingLeft: '0.4rem',
        '&$disabled': {
          backgroundColor: colors.shades.secondary[800],
          color: colors.shades.secondary[700],
          borderRadius: '0.3rem',
          '&:hover': {
            backgroundColor: colors.shades.secondary[800],
            color: colors.shades.secondary[700],
            borderRadius: '0.3rem',
          },
        },
        '&:hover': {
          backgroundColor: alpha(colors.secondary.dark, 0.6),
          borderRadius: '0.3rem',
        },
        '&$focused': {
          color: colors.secondary.dark,
        },
      },
      input: {
        padding: 0,
        position: 'relative',
        top: '0.2rem',
        color: colors.text.primary,
        '&$disabled': {
          backgroundColor: colors.shades.secondary[800],
          color: colors.shades.secondary[700],
        },
        height: 'auto',
      },
      multiline: {
        '& > .MuiInputAdornment-positionStart': {
          display: 'flex',
          alignSelf: 'flex-start',
        },
        '& > .MuiInputBase-input': {
          top: '0.4rem',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 500,
        color: colors.shades.secondary[500],
        '&$disabled': {
          color: colors.shades.secondary[500],
        },
        '&$focused': {
          color: colors.shades.secondary[500],
        },
      },
    },
    MuiInput: {
      root: {
        top: 0,
      },
      underline: {
        '&:before': {
          borderBottomColor: colors.shades.secondary[700],
        },
        '&$disabled:before': {
          borderBottomStyle: 'solid',
          borderBottomColor: colors.shades.secondary[700],
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        height: '100%',
        color: colors.text.primary,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiInput-root"] .MuiAutocomplete-input': {
          padding: '0 !important',
        },
      },
    },
    MuiTooltip: {
      arrow: {
        color: alpha('#000', 0.6),
      },
      tooltip: {
        borderRadius: 2,
        backgroundColor: alpha('#000', 0.6),
        color: '#fff',
        /* Bold Small Title */
        fontSize: '1rem',
        fontWeight: 600,
        textTransform: 'capitalize',
        lineHeight: '1.6rem',
      },
    },
    MuiCheckbox: {
      root: {
        padding: '0.5rem',
        color: colors.shades.secondary[500],
      },
      colorSecondary: {
        '&:hover': {
          backgroundColor: colors.shades.secondary[200],
        },
        '&$checked': {
          color: colors.secondary.dark,
        },
      },
      colorPrimary: {
        '&:hover': {
          backgroundColor: colors.shades.secondary[200],
        },
        '&$checked': {
          color: colors.primary.dark,
        },
      },
    },
    MuiDialog: {
      paper: {
        boxShadow: shadows.dialogShadow,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: alpha(colors.secondary.dark, 0.5),
        backdropFilter: 'blur(20px)',
      },
    },
    MuiLink: {
      root: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.secondary.dark,
      },
    },
    MuiPickersModal: {
      withAdditionalAction: {
        '& button': {
          color: colors.secondary.dark,
          '&:hover': {
            backgroundColor: alpha(colors.secondary.dark, 0.04),
          },
        },
      },
    },
    MuiAccordion: {
      root: {
        margin: 0,
        '&.MuiPaper-root': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
        },
        '&:before': {
          content: '',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        flexDirection: 'row-reverse',
        minHeight: 'min-content !important',
        padding: 0,
        '&.Mui-disabled': {
          opacity: 1,
        },
        '&$expanded': {
          minHeight: 'min-content !important',
          margin: 0,
        },
      },
      content: {
        margin: '0 !important',
        padding: '0 0 0 0.5rem',
        fontWeight: 600,
        color: colors.shades.secondary[500],
        '&.Mui-expanded': {
          color: colors.shades.secondary[300],
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '0.5rem 0 0 0.5rem',
        lineHeight: '2rem',
        fontSize: '1.4rem',
        color: colors.text.primary,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.divider.color,
      },
    },
    MuiTypography: {
      colorPrimary: {
        color:
          '' /* invalid value on purpose, a element is setting the primary color */,
      },
      colorSecondary: {
        color: colors.text.primary,
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: colors.primary.main,
      },
      colorSecondary: {
        color: colors.text.primary,
      },
    },
  },
  ...commonAppStyles,
  header: {
    breadcrumbs: {
      activeColor: colors.primary.main,
      idleColor: colors.secondary.main,
      arrowColor: colors.secondary.main,
    },
  },
  isLightTheme: false,
  isDarkTheme: true,
  nav: {
    ...commonAppStyles.nav,
    mainButton: {
      active: {
        backgroundColor: colors.primary.main,
        color: colors.secondary.dark,
        fontWeight: 700,
        '&:hover': {
          backgroundColor: colors.primary.main,
          color: colors.shades.primary[900],
        },
      },
      idle: {
        color: colors.shades.secondary[600],
        '&:hover': {
          color: colors.primary.main,
        },
      },
    },
    children: {
      listContainer: {
        backgroundColor: colors.shades.secondary[800],
        boxShadow: shadows.menuInsetShadow,
      },
      active: {
        backgroundColor: colors.shades.secondary[700],
        color: colors.primary.main,
      },
      idle: {
        color: colors.shades.secondary[400],
      },
      children: {
        active: {
          color: colors.shades.secondary[500],
        },
        idle: {
          color: colors.shades.secondary[500],
        },
      },
    },
  },
};
