import isEmpty from 'lodash/isEmpty';
import upperCase from 'lodash/upperCase';

import httpClient from '../client';
import local from '../client/local';

export default async ({ teamId, environment = 'LIVE' }) => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(
        `/api/teams/${teamId}/get_partner/?environment=${upperCase(
          environment
        )}`
      );
    } else {
      response = await httpClient.get(
        `/api/teams/${teamId}/get_partner/?environment=${upperCase(
          environment
        )}`
      );
    }

    let engines;
    if (response.blockchain_schedules)
      engines = Object.keys(response.blockchain_schedules).filter(
        (engine) => !isEmpty(response.blockchain_schedules[engine])
      );
    return {
      partnerId: response.partner_id,
      partnerName: response.partner_name,
      engines,
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
