import { isArray, isObject } from 'lodash';

export default (error) => {
  if (!error.response) return Promise.reject(error);

  const { status, data } = error.response;
  const apiError = {};
  const errorMessage = data.message || data.detail;
  if (errorMessage && !(isObject(errorMessage) || isArray(errorMessage))) {
    apiError.data = errorMessage;
  } else {
    apiError.data = 'Internal server error.';
  }
  apiError.status = status;

  return Promise.reject(apiError);
};
