import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LoadingIcon from 'assets/icons/animated/loading';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  root: {
    borderWidth: '1px',
    borderStyle: 'solid',
  },
}));

const PrimaryButton = styled(
  ({
    className,
    children,
    color = 'primary',
    loading = false,
    icon,
    size = 'medium',
    ...props
  }) => {
    const classes = useStyles();
    return (
      <Button
        variant="contained"
        classes={{
          root: classes.root,
        }}
        size={size}
        {...props}
        startIcon={loading ? <LoadingIcon /> : icon}
        color={color}
        className={className}
      >
        {children}
      </Button>
    );
  }
)``;

export default PrimaryButton;
