import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  hidden: {
    opacity: 0,
    position: 'absolute',
    transition: 'opacity 0.4s ease-in-out',
    backgroundColor: theme.palette.background.default,
  },
  display: {
    opacity: 1,
    position: 'sticky',
  },
}));

const Image = ({ src, Loader, alt, className }) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  return (
    <>
      <img
        src={src}
        alt={alt}
        onLoad={() => setLoading(false)}
        className={clsx(classes.hidden, !loading && classes.display, className)}
      />
      {loading && Loader}
    </>
  );
};

export default Image;
