import React, { Children } from 'react';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AlertTriangleIcon from 'assets/icons/notifications/alert_triangle';
import InfoIcon from 'assets/icons/notifications/info';
import CheckCircleIcon from 'assets/icons/popular/check_circle';
import XCircleIcon from 'assets/icons/popular/x_circle';
import clsx from 'clsx';
import { SmallTitle } from 'components/atoms';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    padding: '1.5rem',
    borderRadius: '0.5rem',
    backgroundColor: ({ severity }) =>
      theme.palette[severity]?.light || theme.palette.info.light,
  },
  icon: {
    flex: '0 0 auto',
    marginRight: '1rem',
    '& svg': {
      color: ({ severity }) =>
        theme.palette[severity]?.main || theme.palette.info.main,
      display: 'flex',
    },
  },
  textContainer: {
    flex: '1 1 100%',
  },
  text: {
    textTransform: 'none',
    fontWeight: 600,
    '& .emph': {
      color: ({ severity }) =>
        theme.palette[severity]?.main || theme.palette.info.main,
    },
  },
}));

const defaultIcons = {
  error: XCircleIcon,
  warning: AlertTriangleIcon,
  success: CheckCircleIcon,
  info: InfoIcon,
};

const DefaultIcon = styled.svg``;

const TextAlert = ({ icon, children, severity = 'info', ...props }) => {
  const classes = useStyles({ severity });
  return (
    <Grid container className={clsx(classes.container, props.className)}>
      <Grid item className={classes.icon}>
        {icon || <DefaultIcon as={defaultIcons[severity]} /> || null}
      </Grid>
      <Grid item>
        {Children.map(children, (item) => (
          <SmallTitle className={classes.text}>{item}</SmallTitle>
        ))}
      </Grid>
    </Grid>
  );
};

export default TextAlert;
