import React from 'react';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import { LoadingPage } from 'components/molecules';

import keycloak from './keycloak';

const AuthProvider = ({ children }) => {
  if (process.env.NODE_ENV === 'test') {
    return <>{children}</>;
  }
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'check-sso',
        pkceMethod: 'S256',
        checkLoginIframe: false,
      }}
      LoadingComponent={<LoadingPage />}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

export default AuthProvider;
