import httpClient from '../client';
import local from '../client/local';

export default async ({ photo }) => {
  try {
    const formData = new FormData();
    if (photo) formData.append('photo', photo);

    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.post(
        '/api/profile/upload_profile_picture',
        formData
      );
    } else {
      response = await httpClient.post(
        '/api/profile/upload_profile_picture',
        formData
      );
    }

    return response;
  } catch (error) {
    if (error.status === 413) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('The image is over 2MB.');
    }
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
