import React, { useEffect, useState } from 'react';

import { RedirectDialog } from 'components/molecules';
import { NavLink, useLocation } from 'react-router-dom';
import {
  ACCOUNT_TEAMS_ROUTE,
  TEAM_DASHBOARD_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  TEAM_MANAGE_ROUTE,
} from 'routes/paths';
import teams from 'stores/teams';
import { prettifyTimestamp } from 'utils/dates';

const TeamInDeletion = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const { activeTeam, isSwitchingTeams, teamUser } = teams.useStoreState(
    (st) => st
  );
  const location = useLocation();

  useEffect(() => {
    if (activeTeam.toBeDeleted) {
      const isInDeletion = activeTeam.toBeDeleted;
      if (
        isInDeletion &&
        !isSwitchingTeams &&
        location.pathname !== TEAM_DASHBOARD_ROUTE &&
        location.pathname !== ACCOUNT_SETTINGS_ROUTE &&
        location.pathname !== TEAM_MANAGE_ROUTE
      )
        setOpenDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeam.id, location.pathname]);

  const closeDialog = () => setOpenDialog(false);

  if (openDialog && teamUser.permissions.teams.canCancelDelete) {
    return (
      <RedirectDialog
        open={openDialog}
        onClose={closeDialog}
        title="Team does not allow any changes!"
        redirectButtonProps={{
          onClick: closeDialog,
          component: NavLink,
          to: ACCOUNT_TEAMS_ROUTE,
        }}
        redirectButtonLabel="Cancel Team Deletion"
      >
        <>
          <p>
            The team is on the process for deletion and does not allow any
            changes to be made.
          </p>
          <p>
            If you do not want to proceed with the deletion, you can cancel this
            operation until{' '}
            {prettifyTimestamp(activeTeam.deleteTeam.scheduledDeleteDate)}.
          </p>
        </>
      </RedirectDialog>
    );
  }
  if (openDialog && !teamUser.permissions.teams.canCancelDelete) {
    return (
      <RedirectDialog
        open={openDialog}
        title="Team does not allow any changes!"
        redirectButtonProps={{
          onClick: closeDialog,
        }}
        redirectButtonLabel="Got it!"
        onClose={closeDialog}
      >
        <>
          <p>
            The team is on the process for deletion and does not allow any
            changes to be made.
          </p>
          <p>
            It will be permanently deleted on{' '}
            {prettifyTimestamp(activeTeam.deleteTeam.scheduledDeleteDate)}.
          </p>
        </>
      </RedirectDialog>
    );
  }
  return null;
};

export default TeamInDeletion;
