import React from 'react';

import { InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from 'assets/icons/popular/search';
import clsx from 'clsx';

import TextField from './TextField';

const useStyles = makeStyles((theme) => ({
  search: {
    padding: '0.5rem 2.5rem 0.5rem 2.5rem',
    borderRadius: '2rem',
    border: `1px solid ${theme.palette.shades.secondary[700]}`,
    color: theme.palette.shades.secondary[700],
    '& .MuiInputBase-root': {
      '&:before': {
        content: 'none',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.shades.secondary[700],
      opacity: 1,
    },
    '& .MuiInput-underline:after': {
      content: 'none',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
}));

const Search = ({ onChange, placeholder, className, ...props }) => {
  const classes = useStyles();
  return (
    <TextField
      className={clsx(classes.search, className)}
      placeholder={placeholder}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default Search;
