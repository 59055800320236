import React, { forwardRef, useEffect, useState } from 'react';

import {
  Divider,
  Grid,
  ClickAwayListener,
  Popper,
  MenuItem,
} from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import LogoutIcon from 'assets/icons/account/log_out';
import useAuth from 'auth/useAuth';
import clsx from 'clsx';
import AboutMeDialog from 'components/app/Dialogs/AboutMeDialog';
import { Heading2, Body1, Tabs, Tab } from 'components/atoms';
import {
  IndividualAvatar,
  AvatarCommon as Avatar,
  TeamAvatar,
} from 'components/molecules';
import {
  usePopupState,
  bindTrigger,
  bindPopper,
} from 'material-ui-popup-state/hooks';
import profile from 'stores/profile';
import teams from 'stores/teams';
import shadows from 'theme/common/shadows';

const useStyles = makeStyles((theme) => ({
  popper: {
    boxShadow: shadows.popperShadow,
    backgroundColor: theme.palette.background.popper,
    borderRadius: '0.5rem',
    top: '1rem !important',
  },
  header: {
    padding: '2rem 3rem 2.5rem 3rem',
  },
  headerItems: {
    marginLeft: '1rem',
    display: 'flex',
  },
  name: {
    lineHeigh: '2.4rem',
  },
  email: {
    color: theme.palette.secondary.main,
    lineHeigh: '2rem',
  },
  divider: {
    width: '100%',
  },
  avatar: {
    cursor: 'pointer',
  },
  logout: {
    padding: '0 3.4rem',
    color: theme.palette.secondary.dark,
    marginBottom: '0.7rem',
    height: '3.4rem',
  },
  logoutIcon: {
    width: '2.2rem',
    height: '2.2rem',
    marginRight: '1.1rem',
  },
  aboutMe: {
    padding: '0 3.4rem',
    marginTop: '0.7rem',
    height: '3.4rem',
    top: 0,
  },
  companyLogo: {
    marginRight: '1.1rem',
    backgroundColor: theme.palette.secondary.dark,
    padding: '0.2rem',
    '& > img': {
      borderRadius: 'unset',
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.text.primary,
        theme.palette.action.hoverOpacity
      ),
    },
  },
}));

const ProfileHeader = () => {
  const classes = useStyles();
  const { user } = profile.useStoreState((st) => st);
  return (
    <Grid container className={classes.header}>
      <Grid item>
        {user.photo && (
          <Avatar size="medium">
            <img src={user.photo} alt={user.fullName} />
          </Avatar>
        )}
        {!user.photo && <IndividualAvatar size="medium" />}
      </Grid>
      <Grid item className={classes.headerItems}>
        <Grid container direction="column" justifyContent="center">
          <Grid item>
            <Heading2 className={classes.name}>{user.fullName}</Heading2>
          </Grid>
          <Grid item>
            <Body1 className={classes.email}>{user.email}</Body1>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const LogoutButton = (props) => {
  const classes = useStyles();
  return (
    <MenuItem className={clsx(classes.menuItem, classes.logout)} {...props}>
      <LogoutIcon color="secondary" className={classes.logoutIcon} />
      <Body1 color="secondary">Sign out</Body1>
    </MenuItem>
  );
};

const useVTabsStyle = makeStyles((theme) => ({
  root: {
    margin: '0.7rem 0',
    color: theme.palette.text.primary,
  },
  scroller: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  flexContainerVertical: {
    flex: '1 1 auto',
    margin: 0,
    '& > button': {
      opacity: 1,
      padding: '0 3.4rem',
      maxWidth: 'unset',
      margin: 0,
    },
    '& > button:hover': {
      backgroundColor: alpha(
        theme.palette.text.primary,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  indicator: {
    width: '0.2rem !important',
    marginLeft: '0.7rem',
    left: 0,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '3.4rem',
    textTransform: 'none',
    '& > *': {
      flex: '0 0 auto',
    },
    '& > *:last-child': {
      marginLeft: '1.1rem',
    },
  },
}));

const a11yProps = (index) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

const Teams = () => {
  const { teams: allTeams, activeTeam } = teams.useStoreState((st) => st);
  const { setActiveTeam } = teams.useStoreActions((act) => act);
  const classes = useVTabsStyle();
  return (
    <>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={activeTeam.id}
        onChange={(e, val) => setActiveTeam(val)}
        classes={{
          root: classes.root,
          scroller: classes.scroller,
          flexContainerVertical: classes.flexContainerVertical,
          indicator: classes.indicator,
        }}
      >
        {allTeams.items.map((t, index) => (
          <Tab
            key={t.id}
            value={t.id}
            label={
              <>
                {t.photo ? (
                  <Avatar size="xSmall">
                    <img src={t.photo} alt={t.name} />
                  </Avatar>
                ) : (
                  <TeamAvatar size="xSmall" />
                )}
                <Body1>{t.name}</Body1>
              </>
            }
            {...a11yProps(index)}
            classes={{
              wrapper: classes.wrapper,
            }}
          />
        ))}
      </Tabs>
    </>
  );
};

const AboutMe = (props) => {
  const classes = useStyles();
  return (
    <MenuItem {...props} className={clsx(classes.menuItem, classes.aboutMe)}>
      <Avatar size="xSmall" className={classes.companyLogo}>
        <img
          src={`${process.env.PUBLIC_URL}/images/light_logo.svg`}
          alt="about-me"
        />
      </Avatar>
      <Body1>About VDXapi</Body1>
    </MenuItem>
  );
};

const ProfilePopper = ({ state, onOpenAboutMe }) => {
  const classes = useStyles();
  const { teams: allTeams } = teams.useStoreState((st) => st);
  const { setSwitchingTeams, clearSwitchingTeams } = teams.useStoreActions(
    (act) => act
  );
  const { logout } = useAuth();

  const onLogout = () => {
    state.close();
    logout();
  };

  useEffect(() => {
    if (state.isOpen) {
      setSwitchingTeams();
    } else {
      clearSwitchingTeams();
    }
  }, [state.isOpen]);

  return (
    <>
      <ClickAwayListener onClickAway={state.close}>
        <Popper
          {...bindPopper(state)}
          placement="bottom-end"
          transition
          className={classes.popper}
        >
          <>
            <ProfileHeader />
            <Divider className={classes.divider} />
            {allTeams.count > 0 && (
              <>
                <Teams />
                <Divider className={classes.divider} />
              </>
            )}
            <AboutMe onClick={onOpenAboutMe} />
            <LogoutButton onClick={onLogout} />
          </>
        </Popper>
      </ClickAwayListener>
    </>
  );
};

const Profile = forwardRef((props, ref) => {
  const { user, fetching, sending, deletePhotoState, updatePhotoState } =
    profile.useStoreState((st) => st);
  const classes = useStyles();
  const popperState = usePopupState({
    variant: 'popper',
    popupId: 'profile-popper',
  });
  const [isAboutMeOpen, setIsAboutMeOpen] = useState(false);

  const onOpenAboutMe = () => {
    popperState.close();
    setIsAboutMeOpen(true);
  };

  const onCloseAboutMe = () => {
    setIsAboutMeOpen(false);
  };

  return (
    <>
      {user.photo && (
        <Avatar
          {...props}
          {...bindTrigger(popperState)}
          className={clsx(classes.avatar, props.className)}
          ref={ref}
          loading={fetching || sending}
          id="profile-popper-button"
        >
          <img src={user.photo} alt={user.fullName} />
        </Avatar>
      )}
      {!user.photo && (
        <IndividualAvatar
          {...props}
          {...bindTrigger(popperState)}
          className={clsx(classes.avatar, props.className)}
          ref={ref}
          loading={fetching || sending}
          id="profile-popper-button"
        />
      )}
      {popperState.isOpen &&
        !deletePhotoState?.sending &&
        !updatePhotoState?.sending && (
          <ProfilePopper state={popperState} onOpenAboutMe={onOpenAboutMe} />
        )}
      <AboutMeDialog open={isAboutMeOpen} onClose={onCloseAboutMe} />
    </>
  );
});

export default Profile;
