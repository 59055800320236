import React from 'react';

import {
  ClickAwayListener,
  Popper,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import LoadingIcon from 'assets/icons/animated/loading';
import MoreVerticalIcon from 'assets/icons/popular/more_vertical';
import clsx from 'clsx';
import {
  usePopupState,
  bindTrigger,
  bindPopper,
} from 'material-ui-popup-state/hooks';
import shadows from 'theme/common/shadows';

const useStyles = makeStyles((theme) => ({
  popper: {
    boxShadow: shadows.popperShadow,
    backgroundColor: theme.palette.background.popper,
    borderRadius: '0.5rem',
  },
  moreOptionsButton: {
    width: '2.2rem',
    height: '2.3rem',
    borderRadius: '0.5rem',
    marginLeft: 'auto',
    '& svg': {
      height: '1.5rem',
    },
    color: theme.palette.text.primary,
  },
  listItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dangerListItem: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.error.main,
        theme.palette.action.hoverOpacity
      ),
      cursor: 'pointer',
    },
  },
  listIcon: {
    minWidth: 'unset',
    marginRight: '0.8rem',
    '& > svg': {
      width: '1.5rem',
    },
  },
  dangerListIcon: {
    color: theme.palette.error.main,
  },
}));

const MoreOptions = ({ id, items, loading, disabled }) => {
  const classes = useStyles();
  const popperState = usePopupState({
    variant: 'popper',
    popupId: `moreOptions${id || 1}`,
  });
  return (
    <>
      <IconButton
        {...bindTrigger(popperState)}
        edge="end"
        className={classes.moreOptionsButton}
        disabled={disabled}
      >
        <MoreVerticalIcon />
      </IconButton>
      <Popper
        {...bindPopper(popperState)}
        placement="right-start"
        transition
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={popperState.close}>
          <List>
            {items
              .filter((entry) => entry.display)
              .map((entry, index) => (
                <ListItem
                  key={entry.id || index}
                  className={clsx(
                    classes.listItem,
                    entry.danger && classes.dangerListItem
                  )}
                  button
                  onClick={() => {
                    popperState.close();
                    if (entry.onClick) entry.onClick();
                  }}
                  disabled={entry.disabled}
                >
                  {entry.icon && (
                    <ListItemIcon
                      className={clsx(
                        classes.listIcon,
                        entry.danger && classes.dangerListIcon
                      )}
                    >
                      {loading ? <LoadingIcon /> : entry.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText>{entry.label}</ListItemText>
                </ListItem>
              ))}
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default MoreOptions;
