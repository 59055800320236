import React, { forwardRef } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  common: {
    backgroundColor: theme.palette.shades.secondary[300],
    color: theme.palette.shades.secondary[300],
    display: 'flex',
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    flex: '0 0 auto',
    '& > *': {
      width: '100%',
      height: '100%',
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      textAlign: 'center',
    },
  },
  xSmall: {
    width: '2.2rem',
    height: '2.2rem',
    '& > svg': {
      width: '1.4rem',
      height: '1.4rem',
      borderRadius: 0,
    },
  },
  small: {
    width: '3.4rem',
    height: '3.4rem',
    '& > svg': {
      width: '2rem',
      height: '2rem',
      borderRadius: 0,
    },
  },
  medium: {
    width: '6rem',
    height: '6rem',
    '& > svg': {
      width: '3rem',
      height: '3rem',
      borderRadius: 0,
    },
  },
  large: {
    width: '16rem',
    height: '16rem',
    '& > svg': {
      width: '8rem',
      height: '8rem',
      borderRadius: 0,
    },
  },
  progress: {
    width: '100% !important',
    height: '100% !important',
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

const AvatarCommon = forwardRef(
  ({ size = 'small', children, loading = false, ...props }, ref) => {
    const classes = useStyles({ size });
    return (
      <div
        {...props}
        className={clsx(
          classes.common,
          size === 'xSmall' && classes.xSmall,
          size === 'small' && classes.small,
          size === 'medium' && classes.medium,
          size === 'large' && classes.large,
          props.className
        )}
        ref={ref}
      >
        {children}
        {loading && <CircularProgress className={classes.progress} />}
      </div>
    );
  }
);

export default AvatarCommon;
