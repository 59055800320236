import React, { forwardRef } from 'react';

import { SvgIcon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { motion } from 'framer-motion';

const Check = forwardRef((props, ref) => {
  const theme = useTheme();
  return (
    <svg
      width={28}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <motion.path
        initial={{ opacity: 0, pathLength: 0, pathOffset: 1 }}
        animate={{ opacity: 1, pathLength: 1, pathOffset: 0 }}
        transition={{ duration: 0.5 }}
        d="M20.75 5.47l-12 12-5.5-5.5"
        fill="none"
        stroke={theme.palette.success.main}
      />
    </svg>
  );
});

export default (props) => (
  <SvgIcon component={Check} viewBox="0 0 24 24" {...props} />
);
