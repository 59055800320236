import React from 'react';

import { Link } from '@material-ui/core';
import PrimaryButton from 'components/atoms/Buttons/PrimaryButton';

const PrimaryButtonLink = ({ children, color = 'primary', ...props }) => (
  <PrimaryButton
    component={Link}
    {...props}
    color={color}
    className={props.className}
  >
    {children}
  </PrimaryButton>
);

export default PrimaryButtonLink;
