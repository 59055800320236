const baseColors = {
  primary: '#F0A500',
  primaryVariant: '#D9830B',
  complementary: '#0055F0',
  complementaryVariant: '#F0F5FE',
  secondary: '#757575',
  secondaryVariant: '#191919',
};

const bee = {
  900: '#2B1A02',
  800: '#824F07',
  700: '#AE6909',
  600: baseColors.primaryVariant,
  500: baseColors.primary,
  400: '#F5C04D',
  300: '#F9DB99',
  200: '#FCEDCC',
  100: '#FEF6E6',
};

const neutral = {
  900: baseColors.secondaryVariant,
  800: '#4D4D4D',
  700: baseColors.secondary,
  600: '#9E9E9E',
  500: '#B4B4B4',
  400: '#D6D6D6',
  300: '#F1F1F1',
  200: '#F8F9FA',
  100: '#FFF',
};

const complementary = {
  red: '#DC3545',
  redLight: '#FEF0F0',
  green: '#28A745',
  greenLight: '#EEF8F0',
  blue: '#0055F0',
  blueLight: '#F0F5FE',
  orange: '#FF7900',
  orangeLight: '#FFF3E8',
};

export default {
  primary: {
    main: baseColors.primary,
    dark: baseColors.primaryVariant,
    contrastText: neutral[100],
  },
  secondary: {
    main: baseColors.secondary,
    dark: baseColors.secondaryVariant,
    contrastText: neutral[100],
  },
  info: {
    main: complementary.blue,
    light: complementary.blueLight,
    contrastText: neutral[100],
  },
  error: {
    main: complementary.red,
    light: complementary.redLight,
    contrastText: neutral[100],
  },
  warning: {
    main: complementary.orange,
    light: complementary.orangeLight,
    contrastText: neutral[100],
  },
  success: {
    main: complementary.green,
    light: complementary.greenLight,
    contrastText: neutral[100],
  },
  shades: {
    primary: bee,
    secondary: neutral,
  },
  text: {
    primary: neutral[800],
  },
  background: {
    default: '#E5E5E5',
    paper: neutral[100],
    popper: neutral[100],
  },
  divider: {
    color: neutral[300],
  },
};
