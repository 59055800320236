import React, { Children } from 'react';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Heading5,
  SecondaryButton,
  PrimaryDangerButton,
  Body1,
} from 'components/atoms';

const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    padding: '2rem 3.3rem 1.5rem 3.3rem',
    borderRadius: '0.5rem',
    backgroundColor: ({ severity }) =>
      theme.palette[severity]?.light || theme.palette.info.light,
  },
  title: {
    marginBottom: '0.6rem',
    color: theme.palette.shades.secondary[800],
  },
  textContainer: {
    flex: '1 1 100%',
  },
  text: {
    textTransform: 'none',
    color: theme.palette.shades.secondary[800],
    '& .emph': {
      color: ({ severity }) =>
        theme.palette[severity]?.main || theme.palette.info.main,
    },
  },
  actions: {
    marginTop: '1.4rem',
  },
}));

const ActionAlert = ({
  children,
  title,
  severity = 'info',
  actionButtonProps,
  actionButtonText,
  hideActionButton = false,
  ...props
}) => {
  const classes = useStyles({ severity });
  return (
    <Grid container className={clsx(classes.container, props.className)}>
      <Grid item>
        <Grid container direction="column">
          {title && (
            <Grid item>
              <Heading5 className={classes.title}>{title}</Heading5>
            </Grid>
          )}
          <Grid item>
            {Children.map(children, (item) => (
              <Body1 className={classes.text}>{item}</Body1>
            ))}
          </Grid>
          {actionButtonProps && (
            <Grid item className={classes.actions}>
              {severity === 'error' && !hideActionButton && (
                <PrimaryDangerButton {...actionButtonProps}>
                  {actionButtonText}
                </PrimaryDangerButton>
              )}
              {severity !== 'error' && !hideActionButton && (
                <SecondaryButton {...actionButtonProps}>
                  {actionButtonText}
                </SecondaryButton>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionAlert;
