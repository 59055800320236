import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold',
  },
}));

const Emph = styled(({ className, children }) => {
  const classes = useStyles();
  return (
    <span className={clsx('emph', classes.bold, className)}>{children}</span>
  );
})``;

export default Emph;
