import { createContextStore, thunk, thunkOn, actionOn } from 'easy-peasy';
import getNotificationTypes from 'services/api/notifications/get_notification_types';
import deletePhoto from 'services/api/profile/delete_profile_picture';
import getProfile from 'services/api/profile/get_profile';
import updatePhoto from 'services/api/profile/update_profile_picture';
import getSchedules from 'services/api/schedules/get_schedules';

import cancellable from './utils/cancellable_thunk';
import fetchThunkHelper from './utils/fetch_thunk_helper';
import updateThunkHelper from './utils/update_thunk_helper';

const profile = () => ({
  user: {},
  fetching: true,

  ...fetchThunkHelper('user', (actions, params, { fail }) =>
    cancellable(getProfile)().catch((err) => fail(err))
  ),
  ...updateThunkHelper('update-photo', (actions, params, { fail }) =>
    cancellable(updatePhoto)(params).catch((err) => fail(err))
  ),
  ...updateThunkHelper('delete-photo', (actions, params, { fail }) =>
    cancellable(deletePhoto)().catch((err) => fail(err))
  ),
  onAfterDeletePhotoSuccess: thunkOn(
    (actions) => actions.deletePhoto.successType,
    (actions) => {
      actions.getUser();
    }
  ),
});

const settings = () => ({
  settings: {
    user: {
      notifications: {
        fetching: true,
        result: [],
      },
    },
    services: {
      schedules: {
        fetching: true,
        result: [],
      },
    },
  },

  ...fetchThunkHelper('settings-notifications', (actions, params, { fail }) =>
    cancellable(getNotificationTypes)().catch((err) => fail(err))
  ),
  onGetSettingsNotificationsStart: actionOn(
    (actions) => actions.getSettingsNotifications.startType,
    () => {
      // empty
    }
  ),
  onGetSettingsNotificationsSuccess: actionOn(
    (actions) => actions.getSettingsNotifications.successType,
    (state, { result }) => {
      state.settings.user.notifications.result = result;
      state.settings.user.notifications.fetching = false;
      state.settings.user.notifications.fetched = true;
    }
  ),
  onGetSettingsNotificationsFail: actionOn(
    (actions) => actions.getSettingsNotifications.failType,
    (state, { error }) => {
      state.settings.user.notifications.error = error;
      state.settings.user.notifications.fetching = false;
      state.settings.user.notifications.fetched = false;
    }
  ),
  ...fetchThunkHelper('services-schedules', (actions, params, { fail }) =>
    cancellable(getSchedules)().catch((err) => fail(err))
  ),
  onGetServicesSchedulesStart: actionOn(
    (actions) => actions.getServicesSchedules.startType,
    () => {
      // empty
    }
  ),
  onGetServicesSchedulesSuccess: actionOn(
    (actions) => actions.getServicesSchedules.successType,
    (state, { result }) => {
      state.settings.services.schedules.result = result;
      state.settings.services.schedules.fetching = false;
      state.settings.services.schedules.fetched = true;
    }
  ),
  onGetServicesSchedulesFail: actionOn(
    (actions) => actions.getServicesSchedules.failType,
    (state, { error }) => {
      state.settings.services.schedules.error = error;
      state.settings.services.schedules.fetching = false;
      state.settings.services.schedules.fetched = false;
    }
  ),
  loadSettings: thunk((actions) => {
    actions.getSettingsNotifications();
    actions.getServicesSchedules();
  }),
});

export default createContextStore(
  {
    ...profile(),
    ...settings(),
    getProfile: thunk((actions) => {
      actions.getUser();
      actions.loadSettings();
    }),
  },
  {
    name: 'Profile',
    devTools: process.env.REACT_APP_REDUX_DEV_TOOLS_ENABLED === 'true',
  }
);
