import { createTheme } from '@material-ui/core/styles';
import dark from 'theme/dark';
import light from 'theme/light';

const darkTheme = createTheme({ ...dark });
const lightTheme = createTheme({ ...light });

export default (isDarkMode) => {
  if (isDarkMode) return darkTheme;
  return lightTheme;
};
