import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const CheckCircle = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        d="M22 11.086v.92a10 10 0 11-5.93-9.14M22 4.006l-10 10.01-3-3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default CheckCircle;
