import React, { useEffect, useState } from 'react';

import { FormControlLabel, Switch as MUISwitch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '3rem',
    height: '1.6rem',
    padding: 0,
    margin: 0,
  },
  switchBase: {
    padding: '0.1rem',
    top: '50%',
    left: '0.1rem',
    transform: 'translateY(-50%)',
    '&$checked:not(.Mui-disabled)': {
      transform: 'translate(1.4rem, -50%)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$checked.Mui-disabled': {
      transform: 'translate(1.4rem, -50%)',
      color: theme.palette.common.white,
      '& + $track': {
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
    },
  },
  thumb: {
    width: '1.2rem',
    height: '1.2rem',
  },
  track: {
    borderRadius: '2.2rem',
    backgroundColor: theme.palette.shades.secondary[400],
    transition: theme.transitions.create(['background-color']),
  },
  checked: {},
  focusVisible: {},
  formRoot: {
    margin: 0,
  },
}));

const Switch = ({ on, onChange, ...props }) => {
  const classes = useStyles();
  const [active, setActive] = useState(on);

  const onSetActive = (e) => {
    if (onChange) onChange(e.target.checked);
    setActive(e.target.checked);
  };

  useEffect(() => {
    setActive(on);
  }, [on]);

  return (
    <FormControlLabel
      {...props}
      classes={{
        root: classes.formRoot,
      }}
      control={
        <MUISwitch
          checked={active}
          onChange={onSetActive}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
        />
      }
    />
  );
};

export default Switch;
