import { VDX_CORE_API_ENVIRONMENTS } from 'consts';
import {
  TEAM_ROUTE,
  TEAM_DASHBOARD_ROUTE,
  TEAM_SUBSCRIPTIONS_ROUTE,
  TEAM_MANAGE_ROUTE,
  TEAM_PAYMENTS_ROUTE,
  TEAM_PAYMENTS_HISTORY_ROUTE,
  TEAM_PAYMENTS_PENDING_ROUTE,
  TEAM_PAYMENTS_UPCOMING_ROUTE,
  SERVICES_ROUTE,
  SERVICES_VDX_CORE_API_DASHBOARD_ROUTE,
  SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES,
  RESOURCES_ROUTE,
  EXTERNAL_RESOURCES_DOCUMENTATION,
  RESOURCES_FAQ_ROUTE,
} from 'routes/paths';

const Team = {
  id: 'team',
  label: 'Team',
  icon: 'account/users',
  path: TEAM_ROUTE,
};
const TeamDashboard = {
  id: 'team/dashboard',
  label: 'Dashboard',
  path: TEAM_DASHBOARD_ROUTE,
};
const TeamSubscriptions = {
  id: 'team/subscriptions',
  label: 'Subscriptions',
  path: TEAM_SUBSCRIPTIONS_ROUTE,
};
const TeamManage = {
  id: 'team/manage',
  label: 'Manage',
  path: TEAM_MANAGE_ROUTE,
};
const TeamPayments = {
  id: 'team/payments',
  label: 'Payments',
  path: TEAM_PAYMENTS_ROUTE,
};
const TeamPaymentsHistory = {
  id: 'team/payments/history',
  label: 'History',
  path: TEAM_PAYMENTS_HISTORY_ROUTE,
};
const TeamPaymentsPending = (conditions) => ({
  id: 'team/payments/pending',
  label:
    conditions.checkingPendingPayment || !conditions.hasFailedPendingPayment
      ? 'Upcoming'
      : 'Pending',
  path:
    conditions.checkingPendingPayment || !conditions.hasFailedPendingPayment
      ? TEAM_PAYMENTS_UPCOMING_ROUTE
      : TEAM_PAYMENTS_PENDING_ROUTE,
  disabled: !conditions.hasPendingPayment || false,
  loading: conditions.checkingPendingPayment || false,
});
const Services = {
  id: 'services',
  label: 'Services',
  icon: 'misc/box',
  path: SERVICES_ROUTE,
};
const ServicesCoreApi = {
  id: 'services/vdx-core-api',
  label: 'VDX Core API',
  path: SERVICES_VDX_CORE_API_DASHBOARD_ROUTE,
  defaultExpanded: true,
};
const ServicesCoreApiEnvs = VDX_CORE_API_ENVIRONMENTS.map((env) => ({
  id: `services/vdx-core-api/${env.value}`,
  label: env.label,
  path: SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES[env.id],
}));
const Resources = {
  id: 'resources',
  label: 'Resources',
  icon: 'misc/database',
  path: RESOURCES_ROUTE,
};
const ResourcesDocumentation = {
  id: 'resources/documentation',
  label: 'Documentation',
  external: EXTERNAL_RESOURCES_DOCUMENTATION,
  args: {
    target: '_noblank',
    rel: 'noopener',
  },
};
const ResourcesFaq = {
  id: 'resources/faq',
  label: 'FAQ',
  path: RESOURCES_FAQ_ROUTE,
};

const ownerNav = (conditions) => [
  {
    ...Team,
    sub: [
      TeamDashboard,
      TeamSubscriptions,
      TeamManage,
      {
        ...TeamPayments,
        sub: [TeamPaymentsHistory, TeamPaymentsPending(conditions)],
      },
    ],
  },
  {
    ...Services,
    sub: [
      {
        ...ServicesCoreApi,
        sub: ServicesCoreApiEnvs,
      },
    ],
  },
  {
    ...Resources,
    sub: [ResourcesDocumentation, ResourcesFaq],
  },
];

const managerNav = (conditions) => [
  {
    ...Team,
    sub: [
      TeamDashboard,
      TeamSubscriptions,
      TeamManage,
      {
        ...TeamPayments,
        sub: [TeamPaymentsHistory, TeamPaymentsPending(conditions)],
      },
    ],
  },
  {
    ...Services,
    sub: [
      {
        ...ServicesCoreApi,
        sub: ServicesCoreApiEnvs,
      },
    ],
  },
  {
    ...Resources,
    sub: [ResourcesDocumentation, ResourcesFaq],
  },
];

const memberNav = () => [
  {
    ...Team,
    sub: [TeamDashboard, TeamManage],
  },
  {
    ...Services,
    sub: [
      {
        ...ServicesCoreApi,
        sub: ServicesCoreApiEnvs,
      },
    ],
  },
  {
    ...Resources,
    sub: [ResourcesDocumentation, ResourcesFaq],
  },
];

const firstTimeUserNav = () => [
  {
    ...Team,
    disabled: true,
  },
  {
    ...Services,
    disabled: true,
  },
  {
    ...Resources,
    sub: [ResourcesDocumentation, ResourcesFaq],
  },
];

const navByRole = (conditions) => ({
  Owner: ownerNav(conditions),
  Manager: managerNav(conditions),
  Member: memberNav(),
});

export const getNavByRole = (role, conditions = {}) => {
  if (role) {
    return navByRole(conditions)[role];
  }
  return firstTimeUserNav();
};
