import { VDX_CORE_API_ENVIRONMENTS } from 'consts';

/* User settings tabs */
export const USER_SETTINGS_TAB = 'user-settings';
export const USER_TEAMS_TAB = 'teams';
export const USER_NOTIFICATIONS_TAB = 'notification-settings';
export const USER_INVITATIONS_TAB = 'team-invitations';

export const TEAM_ROUTE = '/team';
export const TEAM_DASHBOARD_ROUTE = '/team/dashboard';
export const TEAM_SUBSCRIPTIONS_ROUTE = '/team/subscriptions';
export const TEAM_SUBSCRIPTION_PAYMENT_ROUTE = '/team/subscriptions/payment';
export const TEAM_SUBSCRIPTION_RETRY_PAYMENT_ROUTE =
  '/team/subscriptions/payment/retry-payment';
export const TEAM_MANAGE_ROUTE = '/team/manage';
export const TEAM_PAYMENTS_ROUTE = '/team/payments';
export const TEAM_PAYMENTS_HISTORY_ROUTE = '/team/payments/history';
export const TEAM_PAYMENTS_UPCOMING_ROUTE = '/team/payments/upcoming';
export const TEAM_PAYMENTS_PENDING_ROUTE = '/team/payments/pending';
export const TEAM_PAYMENTS_PENDING_RETRY_ROUTE =
  '/team/payments/pending/retry-payment';
export const TEAM_PAYMENT_ID_ROUTE = '/team/payments/history/:id';

export const SERVICES_ROUTE = '/services';
export const SERVICES_VDX_CORE_API_DASHBOARD_ROUTE = '/services/vdx-core-api';
export const SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES =
  VDX_CORE_API_ENVIRONMENTS.reduce((obj, item) => {
    obj[item.id] = `/services/vdx-core-api/${item.value}`;
    return obj;
  }, {});
export const SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES =
  VDX_CORE_API_ENVIRONMENTS.reduce((obj, item) => {
    obj[item.id] = `/services/vdx-core-api/${item.value}/create-partner`;
    return obj;
  }, {});
export const RESOURCES_ROUTE = '/resources';
export const RESOURCES_FAQ_ROUTE = '/resources/faq';

export const ACCOUNT_SETTINGS_ROUTE = '/account/settings';
export const ACCOUNT_TEAMS_ROUTE = `/account/settings?tab=${USER_TEAMS_TAB}`;
export const ACCOUNT_NOTIFICATION_SETTINGS_ROUTE = `/account/settings?tab=${USER_NOTIFICATIONS_TAB}`;
export const ACCOUNT_INVITATIONS_ROUTE = `/account/settings?tab=${USER_INVITATIONS_TAB}`;
export const ACCOUNT_TEAM_CREATE_ROUTE = '/account/team/create';

/* External */
export const EXTERNAL_RESOURCES_DOCUMENTATION =
  process.env.REACT_APP_VIZIDOX_DOCUMENTATION_URL;
