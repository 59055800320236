import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LoadingIcon from 'assets/icons/animated/loading';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  },
}));

const PrimaryDangerButton = styled(
  ({
    className,
    children,
    loading = false,
    icon,
    size = 'medium',
    ...props
  }) => {
    const classes = useStyles();
    return (
      <Button
        variant="outlined"
        classes={{
          root: classes.root,
        }}
        size={size}
        {...props}
        startIcon={loading ? <LoadingIcon /> : icon}
        className={className}
      >
        {children}
      </Button>
    );
  }
)``;

export default PrimaryDangerButton;
