import React, { useEffect, useState } from 'react';

import {
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  Grid,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import FirstPageIcon from 'assets/icons/list/first';
import LastPageIcon from 'assets/icons/list/last';
import NextPageIcon from 'assets/icons/list/next';
import PreviousPageIcon from 'assets/icons/list/previous';
import clsx from 'clsx';
import { Heading5 } from 'components/atoms';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  row: {
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: alpha(
        theme.palette.text.primary,
        theme.palette.action.hoverOpacity
      ),
    },
    '&:last-child': {
      '& > *': {
        borderBottom: 0,
      },
    },
  },
  header: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: '0.4rem',
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    ...theme.typography.h4,
    color: theme.palette.text.primary,
    boxSizing: 'border-box',
  },
  cell: {
    paddingLeft: 0,
    paddingTop: '2.7rem',
    paddingBottom: '2.4rem',
    borderColor: theme.palette.divider,
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    boxSizing: 'border-box',
    top: 0,
  },
  pagination: {
    borderTop: `1px solid ${theme.palette.text.primary}`,
    paddingTop: '1.4rem',
    paddingBottom: '1.4rem',
    '& > *:not(:last-child)': {
      marginRight: '1.2rem',
    },
  },
  paginationButton: {
    '& svg': {
      height: '1.4rem',
      width: '1.2rem',
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: alpha(theme.palette.background.paper, 0.7),
    borderRadius: '0.5rem',
    minHeight: '11rem',
    '& > *': {
      position: 'relative',
      top: '-10%',
    },
  },
}));

const LoadingOverlay = ({ showLoadingIcon }) => {
  const classes = useStyles();
  const [showingIcon, setShowingIcon] = useState(false);
  useEffect(() => {
    let t;
    if (showLoadingIcon && !showingIcon) {
      t = setTimeout(() => {
        setShowingIcon(true);
      }, [1000]);
    }
    return () => clearTimeout(t);
  }, [showLoadingIcon, showingIcon]);
  return (
    <div className={classes.loading}>{showingIcon && <CircularProgress />}</div>
  );
};

export default ({
  config = [],
  data = [],
  className,
  onRow = {},
  totalItemCount,
  itemCountPerPage = 10,
  showPagination = false,
  onChangePage,
  loading,
  showLoadingIcon = true,
  tableProps = {},
  paginationProps = {},
  isClientSide = false,
}) => {
  const classes = useStyles();
  const totalPages = Math.ceil(totalItemCount / itemCountPerPage);
  const [currPage, setCurrPage] = useState({
    number: 1,
    maxCount:
      totalItemCount < itemCountPerPage ? totalItemCount : itemCountPerPage,
    minCount: 1,
    previousDisabled: true,
    nextDisabled: totalItemCount < itemCountPerPage,
  });

  const onNextPage = () => {
    const nextPage =
      currPage.number + 1 >= totalPages ? totalPages : currPage.number + 1;
    if (onChangePage)
      onChangePage({
        page: nextPage,
      });
    setCurrPage({
      number: nextPage,
      maxCount:
        nextPage >= totalPages ? totalItemCount : nextPage * itemCountPerPage,
      minCount: (nextPage - 1) * itemCountPerPage + 1,
      previousDisabled: nextPage - 1 < 1,
      nextDisabled: nextPage + 1 > totalPages,
    });
  };

  const onPreviousPage = () => {
    const prevPage = currPage.number - 1 <= 1 ? 1 : currPage.number - 1;
    if (onChangePage)
      onChangePage({
        page: prevPage,
      });
    setCurrPage({
      number: prevPage,
      minCount: prevPage <= 1 ? 1 : (prevPage - 1) * itemCountPerPage + 1,
      maxCount:
        prevPage + 1 === totalPages
          ? totalItemCount
          : prevPage * itemCountPerPage,
      previousDisabled: prevPage - 1 < 1,
      nextDisabled: prevPage + 1 > totalPages,
    });
  };

  const onFirstPage = () => {
    if (onChangePage)
      onChangePage({
        page: 1,
      });
    setCurrPage({
      number: 1,
      minCount: 1,
      maxCount:
        totalItemCount < itemCountPerPage ? totalItemCount : itemCountPerPage,
      previousDisabled: true,
      nextDisabled: totalItemCount <= itemCountPerPage,
    });
  };

  const onLastPage = () => {
    if (onChangePage)
      onChangePage({
        page: totalPages,
      });
    setCurrPage({
      number: totalPages,
      minCount: totalPages <= 1 ? 1 : (totalPages - 1) * itemCountPerPage + 1,
      maxCount: totalItemCount,
      previousDisabled: totalPages <= 1,
      nextDisabled: true,
    });
  };

  return (
    <div className={clsx(classes.container, className)}>
      <Table {...tableProps}>
        <TableHead>
          <TableRow>
            {config.map((def) =>
              def.label ? (
                <TableCell
                  key={`${def.keyword}-header`}
                  className={classes.header}
                  style={def.styles}
                >
                  {def.label}
                </TableCell>
              ) : (
                <TableCell
                  key={`${def.keyword}-header`}
                  className={classes.header}
                  style={def.styles}
                />
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(isClientSide
            ? data.slice(
                Math.max(itemCountPerPage * (currPage.number - 1), 0),
                Math.min(currPage.number * itemCountPerPage, totalItemCount)
              )
            : data.slice(0, itemCountPerPage)
          ).map((row, index) =>
            onRow?.props ? (
              <TableRow
                // eslint-disable-next-line react/no-array-index-key
                key={`row-page-${currPage}-index-${index}`}
                className={classes.row}
                {...onRow.props({ rowIndex: index, row })}
              >
                {config.map((def) => (
                  <TableCell
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${def.keyword}-row-${index}`}
                    className={classes.cell}
                    style={def.styles}
                  >
                    {def.content(row, index)}
                  </TableCell>
                ))}
              </TableRow>
            ) : (
              <TableRow className={classes.row}>
                {config.map((def) => (
                  <TableCell
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${def.keyword}-row-${index}`}
                    className={classes.cell}
                    style={def.styles}
                  >
                    {def.content(row)}
                  </TableCell>
                ))}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      {showPagination && totalItemCount > itemCountPerPage && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          {...paginationProps}
          className={clsx(classes.pagination, paginationProps.className)}
        >
          <IconButton
            onClick={onFirstPage}
            disabled={currPage.number === 1}
            size="small"
            className={classes.paginationButton}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            onClick={onPreviousPage}
            disabled={currPage.previousDisabled}
            size="small"
            className={classes.paginationButton}
          >
            <PreviousPageIcon />
          </IconButton>
          <Heading5>
            {currPage.minCount}-{currPage.maxCount} of {totalItemCount}
          </Heading5>
          <IconButton
            onClick={onNextPage}
            disabled={currPage.nextDisabled}
            size="small"
            className={classes.paginationButton}
          >
            <NextPageIcon />
          </IconButton>
          <IconButton
            onClick={onLastPage}
            disabled={currPage.number === totalPages}
            size="small"
            className={classes.paginationButton}
          >
            <LastPageIcon />
          </IconButton>
        </Grid>
      )}
      {loading && <LoadingOverlay showLoadingIcon={showLoadingIcon} />}
    </div>
  );
};
