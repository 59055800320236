import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const EyeOff = (props) => (
  <SvgIcon {...props} viewBox="0 0 29 29">
    <svg width={29} height={29} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.008 16.616a3 3 0 1 1-4.24-4.24m-8.88-8.88 22 22m-5.06-5.06a10.07 10.07 0 0 1-5.94 2.06c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94l11.88 11.88Zm-8.04-13.7a9.12 9.12 0 0 1 2.1-.24c7 0 11 8 11 8a18.496 18.496 0 0 1-2.16 3.19l-10.94-10.95Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default EyeOff;
