import httpClient from '../client';
import local from '../client/local';

export default async (teamId) => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(`/api/teams/${teamId}/`);
    } else {
      response = await httpClient.get(`/api/teams/${teamId}/`);
    }
    return {
      id: response.id,
      name: response.name,
      country: response.country,
      photo: response.photo,
      allowExtraRequests: response.allow_extra_requests,
      extraRequestsLimit: response.extra_requests_limit,
      members: response.members.map((memb) => ({
        firstName: memb.first_name,
        lastName: memb.last_name,
        email: memb.email,
        role: memb.role.name,
        id: memb.id,
        photo: memb.photo,
      })),
      toBeDeleted: response.to_be_deleted,
      deleteTeam: {
        createdAt: response.deleteteam?.created_at,
        scheduledDeleteDate: response.deleteteam?.scheduled_delete_date,
      },
      hasUnpaidRequests: response.has_unpaid_requests,
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
