import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const EmptyResults = (props) => (
  <SvgIcon
    style={{ width: '110px', height: '90px' }}
    {...props}
    viewBox="0 0 79 93"
  >
    <svg
      width={79}
      height={93}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M63.042 21.333c-.2.723-.568 1.233-1.103 1.528-.523.288-1.16.328-1.914.12-.753-.208-1.28-.57-1.58-1.085-.301-.516-.35-1.145-.144-1.888.205-.743.563-1.255 1.073-1.536.52-.278 1.161-.311 1.924-.1.763.21 1.295.573 1.596 1.089.3.515.35 1.14.148 1.872Zm.554-4.823-3.6-.995.278-1.009c.239-.864.633-1.565 1.182-2.105.549-.54 1.402-1.029 2.56-1.466 1.169-.433 1.962-.82 2.38-1.158a2.54 2.54 0 0 0 .855-1.336c.152-.553.056-1.044-.29-1.475-.346-.43-.91-.754-1.694-.97-1.365-.377-3.034-.362-5.005.044l-.657-3.375c2.342-.574 4.638-.55 6.887.07 1.848.51 3.199 1.37 4.054 2.579.858 1.199 1.081 2.541.67 4.027-.274.995-.737 1.79-1.388 2.389-.652.598-1.735 1.174-3.25 1.729-1.08.426-1.775.774-2.088 1.044-.3.263-.523.66-.67 1.193l-.224.813ZM65.313 33.04c-.376.42-.793.638-1.251.658-.447.019-.889-.168-1.325-.559-.437-.391-.67-.81-.7-1.257-.03-.446.149-.885.535-1.315.386-.43.797-.655 1.233-.673.442-.013.884.179 1.326.575.442.397.678.818.708 1.265.03.446-.146.882-.526 1.306Zm1.983-3.054-2.085-1.87.524-.584c.448-.5.947-.84 1.496-1.02.55-.179 1.286-.222 2.21-.128.929.099 1.59.104 1.984.016a1.906 1.906 0 0 0 1.02-.611c.288-.32.387-.682.298-1.087-.088-.405-.36-.81-.813-1.217-.79-.71-1.916-1.257-3.376-1.642l.687-2.486c1.764.396 3.298 1.179 4.6 2.347 1.071.96 1.691 1.989 1.862 3.086.175 1.091-.123 2.068-.895 2.928-.516.576-1.093.956-1.73 1.14-.638.184-1.558.21-2.76.076-.867-.075-1.451-.073-1.751.004-.29.076-.572.269-.848.577l-.423.471Z"
        fill="#AEB8C2"
      />
      <path
        d="M39.114 92.59c13.87 0 25.113-2.594 25.113-5.794 0-3.201-11.244-5.796-25.113-5.796C25.244 81 14 83.595 14 86.796c0 3.2 11.244 5.795 25.114 5.795Z"
        fill="#F5F5F7"
      />
      <circle cx={27.689} cy={35.689} r={27.689} fill="#AEB8C2" />
      <circle cx={27.689} cy={35.69} r={22.538} fill="#F5F5F7" />
      <rect
        x={32.197}
        y={59.914}
        width={8.527}
        height={31.712}
        rx={4.264}
        transform="rotate(-30 32.197 59.914)"
        fill="#AEB8C2"
      />
      <circle cx={20.928} cy={30.218} r={2.898} fill="#AEB8C2" />
      <circle cx={33.929} cy={30.218} r={2.898} fill="#AEB8C2" />
      <path
        d="M19.318 45.349c7.807-4.184 12.05-4.401 19.318 0"
        stroke="#AEB8C2"
        strokeWidth={1.932}
        strokeLinecap="round"
      />
    </svg>
  </SvgIcon>
);

export default EmptyResults;
