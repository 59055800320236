import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  heading3: {
    color: theme.palette.text.primary,
  },
}));

const Heading3 = styled(({ className, children }) => {
  const classes = useStyles();
  return (
    <Typography variant="h3" className={clsx(classes.heading3, className)}>
      {children}
    </Typography>
  );
})``;

export default Heading3;
