import React from 'react';

import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  overlay: {
    zIndex: theme.zIndex.tooltip + 1,
  },
}));

const LoadingOverlay = styled(({ className, open = true }) => {
  const classes = useStyles();
  return (
    <Backdrop className={clsx(classes.overlay, className)} open={open}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
})``;

export default LoadingOverlay;
