import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const MoreVertical = (props) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 14a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM12 24.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM12 3.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
        fill="currentColor"
      />
      <path
        d="M12 14a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM12 24.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM12 3.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default MoreVertical;
