import httpClient from '../client';
import local from '../client/local';
import getSubscriptionPayments from './get_subscription_payments';

const transformChains = (chains) => {
  const chainsResult = {};
  if (chains)
    Object.keys(chains).forEach((key) => {
      chainsResult[key] = {
        number: chains[key].number,
        totalCost: chains[key].total_cost,
        transactions: chains[key].transactions.map((tx) => ({
          date: tx.date,
          txUrl: tx.tx_url,
          costType: tx.cost_type,
          costGbp: tx.cost_gbp,
        })),
      };
    });
  return chainsResult;
};

export default async (teamId) => {
  try {
    const { count } = await getSubscriptionPayments({ teamId });
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(
        `/api/teams/${teamId}/${count}/get_all_subscription_extra_payments/?status=PENDING&page=1`
      );
    } else {
      response = await httpClient.get(
        `/api/teams/${teamId}/${count}/get_all_subscription_extra_payments/?status=PENDING&page=1`
      );
    }

    return {
      count: response.count,
      items: response.items.map((item) => ({
        status: item.status,
        date: item.date,
        paymentBroker: item.payment_broker,
        value: item.value,
        invoice: item.invoice,
        extraCostsData: {
          totalCost: item.extra_costs_data?.total_cost,
          requests: {
            number: item.extra_costs_data?.requests?.number,
            pricePerRequest: item.extra_costs_data?.requests?.price_per_request,
            totalCost: item.extra_costs_data?.requests?.total_cost,
          },
          blockchain: {
            number: item.extra_costs_data?.blockchain?.number,
            totalCost: item.extra_costs_data?.blockchain?.total_cost,
            chains: transformChains(item.extra_costs_data?.blockchain?.chains),
          },
        },
      })),
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
