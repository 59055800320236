import React, { forwardRef } from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const MenuPolygon = forwardRef((props, ref) => (
  <SvgIcon {...props} viewBox="0 0 6 5" ref={ref}>
    <svg width={6} height={5} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.134.5a1 1 0 0 1 1.732 0l1.732 3A1 1 0 0 1 4.732 5H1.268a1 1 0 0 1-.866-1.5l1.732-3Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
));

export default MenuPolygon;
