import httpClient from '../client';
import local from '../client/local';

export default async () => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(`/api/plans/`);
    } else {
      response = await httpClient.get(`/api/plans/`);
    }

    return response.items.map((plan) => ({
      name: plan.name,
      displayName: plan.display_name,
      pricePounds: plan.price_pounds,
      pricePerExtraCall: plan.price_per_extra_call,
      maxRequests: plan.max_requests,
      validity: plan.validity,
      features: plan.features,
    }));
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
