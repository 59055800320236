import React, { forwardRef } from 'react';

import { TextField as MUITextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  helperText: {
    position: 'absolute',
    bottom: 0,
    transform: 'translateY(120%)',
  },
}));

const TextField = forwardRef(({ onChange, ...props }, ref) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const trimmed = event.target.value.trimStart();
    // eslint-disable-next-line no-param-reassign
    event.target.value = trimmed;
    if (onChange) onChange(event);
  };

  return (
    <MUITextField
      {...props}
      onChange={handleChange}
      className={clsx(classes.root, props.className)}
      FormHelperTextProps={{ className: classes.helperText }}
      ref={ref}
    />
  );
});

export default TextField;
