import React, { Children, useState, useEffect } from 'react';

import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SecondaryButtonLink, Heading5 } from 'components/atoms';
import Dialog from 'components/molecules/Dialog/Dialog';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    marginTop: '1.5rem',
    '& p': {
      margin: 0,
    },
    '& .emph': {
      color: theme.palette.secondary.dark,
    },
  },
  content: {
    marginTop: '1.5rem',
    ...theme.typography.body1,
    '& p': {
      margin: 0,
    },
    '& .emph': {
      color: theme.palette.secondary.dark,
    },
  },
  buttons: {
    marginTop: '3rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const RedirectDialog = ({
  title,
  open,
  onClose,
  redirectButtonProps = {},
  redirectButtonLabel = 'Confirm',
  children,
  loading,
}) => {
  const [internalState, setInternalState] = useState({});
  const classes = useStyles();

  useEffect(() => {
    if (open)
      setInternalState({
        title,
        redirectButtonLabel,
        children,
      });
  }, [open, loading]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Heading5>{internalState.title}</Heading5>
      {loading && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.content}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}
      {!loading && (
        <div>
          {Children.map(internalState.children, (item) =>
            item.type === 'div' ? (
              <div className={classes.paragraph}>{item.props.children}</div>
            ) : (
              <div className={classes.content}>{item}</div>
            )
          )}
        </div>
      )}
      {internalState.redirectButtonLabel && (
        <div className={classes.buttons}>
          <SecondaryButtonLink {...redirectButtonProps} color="secondary">
            {internalState.redirectButtonLabel}
          </SecondaryButtonLink>
        </div>
      )}
    </Dialog>
  );
};

export default RedirectDialog;
