import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Previous = (props) => (
  <SvgIcon {...props} viewBox="0 0 12 14">
    <svg width={12} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.917 7.778a1 1 0 0 1 0-1.556l4.909-3.968a1 1 0 0 1 1.629.778v7.936a1 1 0 0 1-1.629.778z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default Previous;
