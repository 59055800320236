import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  heading4: {
    color: theme.palette.text.primary,
  },
}));

const Heading4 = styled(({ className, children }) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={clsx(classes.heading4, className)}>
      {children}
    </Typography>
  );
})``;

export default Heading4;
