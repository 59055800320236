import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import shadows from 'theme/common/shadows';

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 20,
  },
  colorPrimary: {
    boxShadow: shadows.progressInsetShadow,
    backgroundColor: theme.palette.shades.secondary[800],
  },
  colorSecondary: {
    boxShadow: shadows.progressInsetShadow,
    backgroundColor: theme.palette.shades.secondary[800],
  },
  bar: {
    borderRadius: 20,
    backgroundColor: ({ color }) => {
      switch (color) {
        case 'primary':
          return theme.palette.primary.main;
        case 'secondary':
          return theme.palette.shades.secondary[400];
        default:
          return theme.palette.primary.main;
      }
    },
  },
}))(LinearProgress);

const ProgressBar = ({ value, className, ...props }) => (
  <StyledLinearProgress
    variant="determinate"
    value={value}
    className={className}
    {...props}
  />
);

export default ProgressBar;
