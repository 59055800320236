export const PLAN_VALIDITY_VALUES = {
  monthly: 'MON',
  annual: 'ANN',
};

export const PLAN_STATUS = {
  valid: {
    id: 'VALID',
    label: 'Valid',
  },
  suspended: {
    id: 'SUSPENDED',
    label: 'Suspended',
  },
  expired: {
    id: 'EXPIRED',
    label: 'Expired',
  },
  cancelled: {
    id: 'CANCELLED',
    label: 'Cancelled',
  },
};
