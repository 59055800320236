import { useEffect, useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';

const useAuth = () => {
  const { keycloak } = useKeycloak();
  const [isAuthenticated, setIsAuthenticated] = useState(
    keycloak.authenticated
  );

  const login = () =>
    keycloak.login().catch(() => {
      setTimeout(() => {
        if (!keycloak.authenticated) setIsAuthenticated(false);
      }, [3000]);
    });

  const logout = () => {
    keycloak.logout({
      redirectUri: encodeURI(process.env.REACT_APP_VDX_HOMEPAGE),
    });
  };

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.updateToken().catch(() => login());
    }
  }, [keycloak.authenticated]);

  useEffect(() => {
    if (!keycloak.token) {
      keycloak.updateToken().catch(() => login());
    }
  }, [keycloak.token]);

  return {
    isAuthenticated,
    login,
    logout,
  };
};

export default () => {
  if (process.env.NODE_ENV === 'test') {
    return { isAuthenticated: true };
  }
  return useAuth();
};
