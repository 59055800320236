import React from 'react';

import XCircleIcon from 'assets/icons/popular/x_circle';
import styled from 'styled-components';

const CloseButton = styled(({ className, onClick, disabled }) => (
  <div
    className={className}
    onClick={disabled ? null : onClick}
    role="button"
    tabIndex="-1" /* focusable */
    onKeyPress={
      disabled ? null : onClick
    } /* needs at least 1 keyboard listener */
    data-testid="close-dialog-button"
  >
    <XCircleIcon />
  </div>
))`
  height: 3rem;
  width: 3rem;
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: ${({ onClick }) => (onClick ? 'flex' : 'none')};
  stroke-width: 2px;
`;

export default CloseButton;
