import capitalize from 'lodash/capitalize';

export const REQUIRED_ERROR = 'Cannot be empty';
export const INVALID_EMAIL = 'Invalid email';
export const MAX_CHARS_ERROR = (num) =>
  `Limited to a maximum of ${num} characters`;
export const ATTR_MAX_CHARS_ERROR = (attr, num) =>
  `${capitalize(attr)} is limited to a maximum of ${num} characters`;
export const MIN_BLOCKCHAIN_ERROR = `Select at least one blockchain type`;
export const INVALID_POSTAL_CODE = 'Invalid code for specified country';
export const INVALID_VAT_ID = 'Invalid VAT for specified country';
export const REQUIRED_IF_ADDRESS_ERROR = 'Required when address info is filled';
export const REQUIRED_FOR_COUNTRY_ERROR = 'Required for specified country';
export const EMOJI_CHAR_NOT_ALLOWED = 'Emoji characters are not allowed';

export const MAX_FIRST_NAME_LENGTH = 20;
export const MAX_LAST_NAME_LENGTH = 20;
export const MAX_USER_NAME_LENGTH = 50;
export const MAX_EMAIL_LENGTH = 60;

export const MAX_TEAM_NAME_LENGTH = 40;

export const MAX_PARTNER_ID_LENGTH = 20;

export const MAX_BILLING_STREET_LENGTH = 255;
export const MAX_BILLING_CITY_LENGTH = 255;
export const MAX_BILLING_COUNTRY_LENGTH = 255;
export const MAX_BILLING_CODE_LENGTH = 50;
export const MAX_BILLING_VAT_LENGTH = 50;
