import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { RESOURCES_FAQ_ROUTE } from 'routes/paths';

const useBeacon = () => {
  const location = useLocation();

  const init = () => {
    window.Beacon('init', process.env.REACT_APP_HELPSCOUT_BEACON_ID);
  };

  const config = (user) => {
    window.Beacon('identify', {
      email: user.email,
      name: user.fullName,
    });
  };

  const destroy = () => {
    window.Beacon('logout');
    window.Beacon('destroy');
  };

  const open = () => {
    window.Beacon('open');
  };

  const close = () => {
    window.Beacon('close');
  };

  useEffect(() => {
    close();
  }, [location.pathname]);

  /* Support message when viewing FAQ page */
  useEffect(() => {
    if (location.pathname === RESOURCES_FAQ_ROUTE)
      window.Beacon('show-message', 'b7f78256-0182-4099-b96b-56ae7945bdc0', {
        force: true,
      });
  }, [location.pathname]);

  return { init, config, destroy, open, close };
};

export default () => {
  if (process.env.NODE_ENV === 'test') {
    return {
      init: () => {
        /* empty */
      },
      config: () => {
        /* empty */
      },
      destroy: () => {
        /* empty */
      },
      open: () => {
        /* empty */
      },
      close: () => {
        /* empty */
      },
    };
  }
  return useBeacon();
};
