import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import SuccessDialog from 'components/molecules/Dialog/SuccessDialog';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import {
  ACCOUNT_INVITATIONS_ROUTE,
  ACCOUNT_TEAM_CREATE_ROUTE,
} from 'routes/paths';
import getInvitationsService from 'services/api/invitations/get_invitations';
import profile from 'stores/profile';
import teams from 'stores/teams';

const WelcomeDialog = ({ open, onRedirect }) => {
  const { user } = profile.useStoreState((st) => st);
  const { data: invites, isLoading } = useQuery(
    ['invites', 1],
    getInvitationsService,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  let buttonProps = { to: '' };
  let buttonLabel;
  let content;

  if (!isLoading && invites.count) {
    buttonProps = { to: ACCOUNT_INVITATIONS_ROUTE };
    buttonLabel = `Check ${invites.count > 1 ? 'Invites' : 'Invite'}`;
    content = (
      <>
        <p>
          There{' '}
          {invites.count > 1 ? 'are some team invites' : 'is a new team invite'}{' '}
          waiting for you.
        </p>
        <p>Join a team in order to get started!</p>
      </>
    );
  }
  if (!isLoading && !invites.count) {
    buttonProps = { to: ACCOUNT_TEAM_CREATE_ROUTE };
    buttonLabel = 'Create Team';
    content = (
      <>
        <p>Thank you for joining VDXapi!</p>
        <p>To get started, you need to be on a team.</p>
      </>
    );
  }

  return (
    <SuccessDialog
      open={open}
      title={`Welcome, ${user.firstName}`}
      buttonProps={{
        component: NavLink,
        onClick: onRedirect,
        ...buttonProps,
      }}
      buttonLabel={buttonLabel}
      loading={isLoading}
    >
      <Grid container alignItems="center" direction="column">
        {content}
      </Grid>
    </SuccessDialog>
  );
};

const Welcome = () => {
  const { teams: allTeams } = teams.useStoreState((st) => st);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (allTeams.count === 0) setOpenDialog(true);
  }, [allTeams.count]);

  const closeDialog = () => setOpenDialog(false);

  if (openDialog) {
    return <WelcomeDialog open={openDialog} onRedirect={closeDialog} />;
  }
  return null;
};

export default Welcome;
