import { actionOn, thunk, action } from 'easy-peasy';
import capitalize from 'lodash/capitalize';

export default (actionName, thunkAction) => {
  const getName = `get${actionName
    .split('-')
    .map((i) => capitalize(i))
    .join('')}`;
  const listenerName = `${actionName
    .split('-')
    .map((i) => capitalize(i))
    .join('')}`;
  const subject = `${actionName
    .split('-')
    .map((i, index) => (index === 0 ? i : capitalize(i)))
    .join('')}`;

  return {
    [getName]: thunk(thunkAction),
    [`onGet${listenerName}Success`]: actionOn(
      (actions) => actions[getName].successType,
      (state, { result }) => {
        state[subject] = result;
        state.fetched = true;
        state.fetching = false;
        state.error = null;
      }
    ),
    [`onGet${listenerName}Start`]: actionOn(
      (actions) => actions[getName].startType,
      (state) => {
        state.fetching = true;
      }
    ),
    [`onGet${listenerName}Fail`]: actionOn(
      (actions) => actions[getName].failType,
      (state, { error }) => {
        state.fetched = false;
        state.fetching = false;
        state.error = error;
      }
    ),
    [`set${listenerName}`]: action((state, payload) => {
      state[subject] = payload;
      state.fetching = false;
      state.fetched = true;
      state.error = null;
    }),
  };
};
