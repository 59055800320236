import React, { Suspense } from 'react';

import { Paper } from '@material-ui/core';
import Header from 'layouts/Header/Header';
import Sidebar from 'layouts/Menu/Sidebar/Sidebar';
import ScreenTips from 'layouts/Poppers/Onboarding/Onboarding';
import styled from 'styled-components';

const MainContent = styled(({ className, children }) => (
  <Paper className={className} component="main">
    <ScreenTips />
    {children}
  </Paper>
))`
  position: relative;
  ${ScreenTips} {
    position: fixed;
    bottom: ${({ theme }) => `calc(${theme.main.paddingBottom} - 5rem)`};
    right: 2.5rem;
    width: 3rem;
    height: 3rem;
    z-index: ${({ theme }) => theme.zIndex.tooltip};
  }
`;

const Desktop = styled(({ className, children }) => (
  <div className={className}>
    <Sidebar />
    <Header />
    <Suspense>
      <MainContent>{children}</MainContent>
    </Suspense>
  </div>
))`
  display: grid;
  grid-template-areas:
    'menu menu header header header header'
    'menu menu main main main main';
  grid-template-columns: auto auto repeat(4, 1fr);
  grid-template-rows: 10rem 1fr;
  min-height: 100vh;
  grid-row-gap: 2rem;

  ${Sidebar} {
    grid-area: menu;
  }
  ${Header} {
    grid-area: header;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ${MainContent} {
    grid-area: main;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) =>
      `${theme.main.paddingTop} ${theme.main.paddingRight} ${theme.main.paddingBottom} ${theme.main.paddingLeft}`};
    & > * {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }
    width: 100%;
  }
`;

export default Desktop;
