export const PRICE_UNITS = '£';
export const CURRENCY = 'GBP';

export const PERIODS = {
  MINUTE: {
    id: 'MINUTE',
    label: '1 Minute',
    valueInSeconds: 60,
  },
  HOUR: {
    id: 'HOUR',
    label: '1 Hour',
    valueInSeconds: 3600,
  },
  DAY: {
    id: 'DAY',
    label: '1 Day',
    valueInSeconds: 86400,
  },
  WEEK: {
    id: 'WEEK',
    label: '1 Week',
    valueInSeconds: 604800,
  },
  MONTH: {
    id: 'MONTH',
    label: '1 Month',
    valueInSeconds: 2592000, // avg 30 day month,
  },
  YEAR: {
    id: 'YEAR',
    label: '1 Year',
    valueInSeconds: 31104000,
  },
};

export const TIME_RANGES = [
  {
    id: 'TWELVE_HOURS',
    label: 'Last 12 hours',
  },
  {
    id: 'DAY',
    label: 'Last day',
  },
  {
    id: 'WEEK',
    label: 'Last week',
  },
  {
    id: 'MONTH',
    label: 'Last month',
  },
  {
    id: 'SIX_MONTHS',
    label: 'Last 6 months',
  },
  {
    id: 'YEAR',
    label: 'Last year',
  },
];

export const DEFAULT_TIME_RANGE_ID = 'DAY';

export const ALLOWED_INTERVALS_BY_TIME_RANGE = {
  TWELVE_HOURS: {
    id: 'TWELVE_HOURS',
    label: 'Last 12 hours',
    periods: [PERIODS.HOUR, PERIODS.MINUTE],
  },
  DAY: {
    id: 'DAY',
    label: 'Last day',
    periods: [PERIODS.HOUR],
  },
  WEEK: {
    id: 'WEEK',
    label: 'Last week',
    periods: [PERIODS.DAY, PERIODS.HOUR],
  },
  MONTH: {
    id: 'MONTH',
    label: 'Last month',
    periods: [PERIODS.WEEK, PERIODS.DAY],
  },
  SIX_MONTHS: {
    id: 'SIX_MONTHS',
    label: 'Last 6 months',
    periods: [PERIODS.MONTH, PERIODS.WEEK, PERIODS.DAY],
  },
  YEAR: {
    id: 'YEAR',
    label: 'Last year',
    periods: [PERIODS.MONTH, PERIODS.WEEK, PERIODS.DAY],
  },
};
