import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const HelpCircle = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.47 18.507c-.236.249-.566.374-.99.374-.425 0-.756-.122-.992-.364-.229-.243-.343-.587-.343-1.032 0-.916.444-1.375 1.334-1.375.438 0 .772.118 1.001.354.23.236.344.573.344 1.011 0 .438-.118.782-.354 1.032zm-.172-3.934h-1.78v-.576c0-.667.122-1.22.365-1.658.242-.445.694-.927 1.355-1.446.66-.52 1.095-.937 1.304-1.254.216-.317.324-.711.324-1.183 0-.479-.172-.853-.516-1.122-.337-.277-.823-.415-1.456-.415-.897 0-1.911.276-3.044.83L8.082 6.15a8.013 8.013 0 0 1 3.963-1.032c1.187 0 2.127.294 2.822.88.7.58 1.051 1.382 1.051 2.406 0 .863-.266 1.608-.799 2.235-.296.344-.765.762-1.405 1.254-.573.458-.954.829-1.143 1.112-.182.283-.273.67-.273 1.163z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default HelpCircle;
