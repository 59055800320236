import React, { forwardRef, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRight from 'assets/icons/chevrons/chevron_right';
import clsx from 'clsx';
import { Body1 } from 'components/atoms';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.typography.body1,
    padding: '0 0 0 0.5rem',
  },
  expandIcon: {
    padding: 0,
    margin: 0,
    color: theme.palette.shades.secondary[700],
    transition: 'transform 0.2s ease-in-out',
    width: '2rem',
    height: '2rem',
  },
  summaryContent: {
    '&.Mui-expanded': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.dark,
        transform: 'rotate(90deg)',
      },
    },
    '& > .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center',
      '& p': {
        fontWeight: 'bold',
      },
    },
  },
  titleContent: { display: 'flex', alignItems: 'center' },
  reverseExpandIcon: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    '& > p': {
      padding: '0 0.5rem 0 0',
    },
  },
}));

const Expandable = forwardRef(
  (
    {
      expanded = false,
      onChange,
      heading,
      arrowAfterTitle = false,
      topRightComponent,
      children,
      headerClass,
      detailsClass,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const [internalExpanded, setInternalExpanded] = useState(expanded);

    const toggleExpand = () => {
      if (onChange) onChange(!internalExpanded);
      setInternalExpanded(!internalExpanded);
    };

    useEffect(() => {
      setInternalExpanded(expanded);
    }, [expanded]);

    return (
      <Accordion
        ref={ref}
        expanded={internalExpanded}
        onChange={toggleExpand}
        {...props}
      >
        <AccordionSummary className={clsx(classes.summaryContent, headerClass)}>
          <div
            className={clsx(
              classes.titleContent,
              arrowAfterTitle && classes.reverseExpandIcon
            )}
          >
            <ChevronRight className={classes.expandIcon} />
            <Body1 className={classes.title}>{heading}</Body1>
          </div>
          {topRightComponent}
        </AccordionSummary>
        <AccordionDetails classes={{ root: detailsClass }}>
          {children}
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default Expandable;
