import { PLAN_VALIDITY_VALUES } from 'consts';
import { subDays, parseISO } from 'date-fns';
import { prettifyDate, prettifyTimestamp } from 'utils/dates';

export const blockDowngrade = ({ currPlan, newPlan }) =>
  currPlan.validity === PLAN_VALIDITY_VALUES.annual &&
  newPlan.validity === PLAN_VALIDITY_VALUES.monthly;

export const isUpgradeNew = ({ currPlan, newPlan, planDisplayNames }) =>
  (currPlan.validity === PLAN_VALIDITY_VALUES.monthly &&
    newPlan.validity === PLAN_VALIDITY_VALUES.monthly &&
    Number(currPlan.pricePounds) < Number(newPlan.pricePounds)) ||
  (currPlan.validity === PLAN_VALIDITY_VALUES.annual &&
    newPlan.validity === PLAN_VALIDITY_VALUES.annual &&
    Number(currPlan.pricePounds) < Number(newPlan.pricePounds)) ||
  (planDisplayNames[currPlan.name] === planDisplayNames[newPlan.name] &&
    currPlan.validity === PLAN_VALIDITY_VALUES.monthly &&
    newPlan.validity === PLAN_VALIDITY_VALUES.annual) ||
  (planDisplayNames[currPlan.name] !== planDisplayNames[newPlan.name] &&
    Number(currPlan.pricePounds) < Number(newPlan.pricePounds));

export const isDowngradeNew = ({ currPlan, newPlan, planDisplayNames }) =>
  (currPlan.validity === PLAN_VALIDITY_VALUES.monthly &&
    newPlan.validity === PLAN_VALIDITY_VALUES.monthly &&
    Number(currPlan.pricePounds) > Number(newPlan.pricePounds)) ||
  (currPlan.validity === PLAN_VALIDITY_VALUES.annual &&
    newPlan.validity === PLAN_VALIDITY_VALUES.annual &&
    Number(currPlan.pricePounds) > Number(newPlan.pricePounds)) ||
  (planDisplayNames[currPlan.name] === planDisplayNames[newPlan.name] &&
    currPlan.validity === PLAN_VALIDITY_VALUES.annual &&
    newPlan.validity === PLAN_VALIDITY_VALUES.monthly) ||
  (planDisplayNames[currPlan.name] !== planDisplayNames[newPlan.name] &&
    Number(currPlan.pricePounds) > Number(newPlan.pricePounds));

// to be replaced
export const isUpgrade = ({ currPlan, newPlan }) =>
  (currPlan.validity === PLAN_VALIDITY_VALUES.monthly &&
    newPlan.validity === PLAN_VALIDITY_VALUES.monthly &&
    Number(currPlan.pricePounds) < Number(newPlan.pricePounds)) ||
  (currPlan.validity === PLAN_VALIDITY_VALUES.annual &&
    newPlan.validity === PLAN_VALIDITY_VALUES.annual &&
    Number(currPlan.pricePounds) < Number(newPlan.pricePounds)) ||
  (currPlan.validity === PLAN_VALIDITY_VALUES.monthly &&
    newPlan.validity === PLAN_VALIDITY_VALUES.annual);

// to be replaced
export const isDowngrade = ({ currPlan, newPlan }) =>
  (currPlan.validity === PLAN_VALIDITY_VALUES.monthly &&
    newPlan.validity === PLAN_VALIDITY_VALUES.monthly &&
    Number(currPlan.pricePounds) > Number(newPlan.pricePounds)) ||
  (currPlan.validity === PLAN_VALIDITY_VALUES.annual &&
    newPlan.validity === PLAN_VALIDITY_VALUES.annual &&
    Number(currPlan.pricePounds) > Number(newPlan.pricePounds)) ||
  (currPlan.validity === PLAN_VALIDITY_VALUES.annual &&
    newPlan.validity === PLAN_VALIDITY_VALUES.monthly);

export const hasNoPriceValue = (plan) => {
  if (Number(plan.pricePounds) === 0) return true;
  return false;
};

export const isStarterPlan = (plan) => {
  if (hasNoPriceValue(plan)) return true;
  return false;
};

export const hasExpirationDate = (plan) => {
  if (!plan.name) return false;
  if (!isStarterPlan(plan)) return false;
  return true;
};

export const getExpirationDate = (plan) => {
  if (!plan.name) return null;
  if (plan.endSubscription) return prettifyDate(plan.endSubscription);
  return 'Never';
};

export const getRenewalDate = (plan) => {
  if (!plan.name) return null;
  if (isStarterPlan(plan)) return null;
  return prettifyDate(plan.endSubscription);
};

export const cancelDowngradeUntil = (plan) => {
  if (!plan.endSubscription) return null;
  return prettifyTimestamp(subDays(parseISO(plan.endSubscription), 1));
};

export const allowsLIVEPartner = (plan) => {
  if (hasNoPriceValue(plan)) return false;
  if (!plan.maxRequests) return false;
  if (plan.maxRequests <= 0) return false;
  return true;
};

export const allowsExtraAPIRequests = (plan) => allowsLIVEPartner(plan);

export const formatPlans = (plans) =>
  plans.map((p, index) => ({
    ...p,
    isStarter: hasNoPriceValue(p) && index === 0,
    isCustom: hasNoPriceValue(p) && index > 0,
  }));

export const getMonthlyPlans = (plans) =>
  plans
    .filter((i) => i.validity === PLAN_VALIDITY_VALUES.monthly)
    .map((i, index) => ({
      ...i,
      isStarter: hasNoPriceValue(i) && index === 0,
      isCustom: hasNoPriceValue(i) && index > 0,
    }));

export const getAnnualPlans = (plans, monthlyPlans) => {
  const annualPlansOnly = plans.filter(
    (i) => i.validity === PLAN_VALIDITY_VALUES.annual
  );
  const annualPlansNames = annualPlansOnly.map((i) => i.displayName);

  const result = [];
  annualPlansOnly.forEach((p, index) => {
    if (
      index === 0 &&
      !annualPlansNames.includes(monthlyPlans[index].displayName)
    ) {
      result.push({
        ...monthlyPlans[index],
        isPlaceholder: true,
      });
    }
    result.push({
      ...p,
      isCustom: hasNoPriceValue(p) && index > 0,
    });
  });
  return result;
};

export const getPlanMaxRequests = (plan) => {
  if (hasNoPriceValue(plan) && plan.isStarter) return 0;
  if (hasNoPriceValue(plan) && plan.isCustom) return 'Custom';
  return plan.maxRequests;
};
