import React, { useEffect, Suspense } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AuthProvider from 'auth/AuthProvider';
import useAuth from 'auth/useAuth';
import AppAlerts from 'components/app/Alerts';
import { LoadingPage } from 'components/molecules';
import useAppRedirects from 'hooks/useAppRedirects';
import useBeacon from 'hooks/useBeacon';
import DeviceLayout from 'layouts';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router, { Routes } from 'routes';
import { ProfileStoreProvider, PortalStoresProvider } from 'stores';
import plans from 'stores/plans';
import profile from 'stores/profile';
import teams from 'stores/teams';
import AppTheme from 'theme';

const Portal = ({ children }) => {
  const { user } = profile.useStoreState((st) => st);
  const { role: hasUserRole } = teams.useStoreState((st) => st.teamUser);
  const { fetched: fetchedTeams } = teams.useStoreState((st) => st);
  const { setTeamUser } = teams.useStoreActions((act) => act);
  const { getPlans } = plans.useStoreActions((act) => act);
  const { config: configBeacon } = useBeacon();

  useEffect(() => {
    if (user.email) {
      setTeamUser({ email: user.email }); // fetches all team info
      getPlans();
      configBeacon(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.email]);

  if (hasUserRole || fetchedTeams) {
    return (
      <Suspense fallback={<LoadingPage />}>
        {children || (
          <DeviceLayout>
            <Routes />
            <AppAlerts />
          </DeviceLayout>
        )}
      </Suspense>
    );
  }
  return <LoadingPage />;
};

export const queryClient = new QueryClient();

const Gatekeeper = ({ children }) => {
  const { fetched: loginSuccessful } = profile.useStoreState((st) => st);
  const { getProfile } = profile.useStoreActions((act) => act);
  const { isAuthenticated } = useAuth();
  const { init: initBeacon, destroy: destroyBeacon } = useBeacon();

  useEffect(() => {
    if (isAuthenticated) {
      getProfile();
      initBeacon();
    }
    return () => destroyBeacon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loginSuccessful) {
    return (
      <>
        {!isAuthenticated && <LoadingPage />}
        <PortalStoresProvider>
          <QueryClientProvider client={queryClient}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Portal>{children}</Portal>
            </MuiPickersUtilsProvider>
          </QueryClientProvider>
        </PortalStoresProvider>
      </>
    );
  }
  return <LoadingPage />;
};

const App = ({ children }) => {
  const { redirected } = useAppRedirects();
  if (!redirected)
    return (
      <AppTheme>
        <AuthProvider>
          <Router>
            <ProfileStoreProvider>
              <Gatekeeper>{children}</Gatekeeper>
            </ProfileStoreProvider>
          </Router>
        </AuthProvider>
      </AppTheme>
    );
  return null;
};

export default App;
