import React, { Fragment } from 'react';

import { Paper, Grid, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRight from 'assets/icons/chevrons/chevron_right';
import SettingsIcon from 'assets/icons/popular/settings';
import clsx from 'clsx';
import Heading2 from 'components/atoms/Typography/Heading2';
import { motion } from 'framer-motion';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useNotifications from 'hooks/useNotifications';
import Notifications from 'layouts/Poppers/Notifications';
import PopperProfile from 'layouts/Poppers/Profile';
import { NavLink, useLocation } from 'react-router-dom';
import {
  ACCOUNT_SETTINGS_ROUTE,
  ACCOUNT_TEAM_CREATE_ROUTE,
} from 'routes/paths';
import profile from 'stores/profile';
import styled from 'styled-components';
import ThemeSwitcher from 'theme/ThemeSwitch';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: '3rem',
    paddingRight: '4rem',
  },
  overview: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginLeft: '1.5rem',
    },
  },
  iconButton: {
    width: '4rem',
    height: '4rem',
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  breadcrumbArrow: {
    width: '2.4rem',
    height: '2.4rem',
    color: theme.header.breadcrumbs.arrowColor,
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    '& h2': {
      color: theme.header.breadcrumbs.idleColor,
      textTransform: 'capitalize',
      '&:last-child': {
        color: theme.header.breadcrumbs.activeColor,
        fontWeight: 'bold',
      },
    },
  },
  profile: {
    '&:hover': {
      boxShadow: `0 0 0 6px ${theme.palette.primary.main}`,
    },
  },
}));

const AppShortcuts = () => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = profile.useStoreState((st) => st);
  const notificationsActions = useNotifications({ userId: user.uid });

  return (
    <div className={classes.overview}>
      {process.env.REACT_APP_SHOW_THEME_TOGGLE === 'true' && <ThemeSwitcher />}
      <Tooltip title="Account Settings" arrow>
        <IconButton
          className={clsx(
            classes.iconButton,
            (location.pathname.includes(ACCOUNT_SETTINGS_ROUTE) ||
              location.pathname.includes(ACCOUNT_TEAM_CREATE_ROUTE)) &&
              classes.active
          )}
          component={NavLink}
          to={ACCOUNT_SETTINGS_ROUTE}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Notifications" arrow>
        <Notifications
          className={classes.iconButton}
          notificationsActions={notificationsActions}
        />
      </Tooltip>
      <Tooltip title="Profile" arrow>
        <PopperProfile className={classes.profile} />
      </Tooltip>
    </div>
  );
};

const Header = styled(({ className }) => {
  const classes = useStyles();
  const breadcrumbs = useBreadcrumbs();

  return (
    <Paper className={className} component="header" data-testid="header">
      <div className={classes.container}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs className={classes.breadcrumbs}>
            {breadcrumbs.map((bc, index, arr) => (
              <Fragment key={bc}>
                <motion.span
                  style={{ display: 'flex', alignItems: 'center' }}
                  initial={{ opacity: 0, zIndex: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{
                    opacity: 0,
                    zIndex: 0,
                  }}
                >
                  <Heading2>{bc}</Heading2>
                  {index + 1 < arr.length && (
                    <ChevronRight className={classes.breadcrumbArrow} />
                  )}
                </motion.span>
              </Fragment>
            ))}
          </Grid>
          <Grid item>
            <AppShortcuts />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
})``;

export default Header;
