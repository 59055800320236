import React, { Children, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  SecondaryButton,
  PrimaryDangerButton,
  Heading5,
} from 'components/atoms';
import Dialog from 'components/molecules/Dialog/Dialog';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    marginTop: '1.5rem',
    ...theme.typography.body1,
    '& p': {
      margin: 0,
    },
    '& .emph': {
      color: theme.palette.secondary.dark,
    },
  },
  content: {
    marginTop: '1.5rem',
    ...theme.typography.body1,
    '& p': {
      margin: 0,
    },
    '& .emph': {
      color: theme.palette.secondary.dark,
    },
  },
  buttons: {
    marginTop: '3rem',
    '& > button:not(:last-child)': {
      marginRight: '1.4rem',
    },
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
  },
}));

const ConfirmDialog = ({
  title,
  open,
  onClose,
  confirmButtonProps = {},
  confirmButtonLabel = 'Confirm',
  cancelButtonProps = {},
  cancelButtonLabel = 'Cancel',
  children,
  alignButtons = 'right',
  useInternalState = true,
  disabled,
}) => {
  const [internalState, setInternalState] = useState({});
  const classes = useStyles();

  useEffect(() => {
    if (open)
      setInternalState({
        title,
        confirmButtonLabel,
        cancelButtonLabel,
        children,
      });
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      disabled={disabled}
    >
      <Heading5>{useInternalState ? internalState.title : title}</Heading5>
      <div>
        {useInternalState &&
          Children.map(internalState.children, (item) =>
            item.type === 'div' ? (
              <div className={classes.paragraph}>{item.props.children}</div>
            ) : (
              <div className={classes.content}>{item}</div>
            )
          )}
        {!useInternalState &&
          Children.map(children, (item) =>
            item.type === 'div' ? (
              <div className={classes.paragraph}>{item.props.children}</div>
            ) : (
              <div className={classes.content}>{item}</div>
            )
          )}
      </div>
      <div
        className={clsx(
          classes.buttons,
          alignButtons === 'left' && classes.alignLeft,
          alignButtons === 'right' && classes.alignRight
        )}
      >
        <SecondaryButton {...confirmButtonProps} color="secondary">
          {useInternalState
            ? internalState.confirmButtonLabel
            : confirmButtonLabel}
        </SecondaryButton>
        <PrimaryDangerButton
          {...cancelButtonProps}
          onClick={cancelButtonProps.onClick || onClose}
        >
          {useInternalState
            ? internalState.cancelButtonLabel
            : cancelButtonLabel}
        </PrimaryDangerButton>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
