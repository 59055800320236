import React, { Children, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from 'assets/icons/animated/check';
import ErrorIcon from 'assets/icons/animated/error';
import AlertTriangleIcon from 'assets/icons/notifications/alert_triangle';
import InfoIcon from 'assets/icons/notifications/info';
import { PrimaryButton, Body1 } from 'components/atoms';
import styled from 'styled-components';

import Dialog from './Dialog';

const defaultIcons = {
  error: ErrorIcon,
  warning: AlertTriangleIcon,
  success: CheckIcon,
  info: InfoIcon,
};

const DefaultIcon = styled.svg``;

const useStyles = makeStyles((theme) => ({
  container: {
    '& > *:not(:first-child)': {
      marginTop: '1.8rem',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  title: {
    fontWeight: 600,
  },
  icon: {
    marginLeft: '0.7rem',
    width: '2rem',
    height: 'auto',
    '& svg': {
      color: ({ severity }) =>
        theme.palette[severity]?.main || theme.palette.info.main,
    },
  },
  textContainer: {
    flex: '1 1 100%',
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:first-child)': {
      marginTop: '1rem',
    },
  },
  text: {
    '& .emph': {
      color: ({ severity }) =>
        theme.palette[severity]?.main || theme.palette.info.main,
    },
  },
  buttonContainer: {
    marginTop: '3.5rem !important',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const FeedbackMessage = ({
  open,
  severity,
  onClose,
  children,
  title,
  buttonText,
  buttonProps,
  ...props
}) => {
  const classes = useStyles({ severity });
  const [internalState, setInternalState] = useState({});

  useEffect(() => {
    if (open) setInternalState({ children, buttonText }); // avoid flickering of data when being reset when closing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth {...props}>
      <Grid container direction="column" className={classes.container}>
        <Grid item className={classes.header}>
          <Body1 className={classes.title}>{title}</Body1>
          <DefaultIcon as={defaultIcons[severity]} className={classes.icon} />
        </Grid>
        <Grid item className={classes.textContainer}>
          {Children.map(internalState.children, (item) => (
            <Body1 className={classes.text}>{item}</Body1>
          ))}
        </Grid>
        {buttonText && (
          <Grid item className={classes.buttonContainer}>
            <PrimaryButton color="secondary" {...buttonProps}>
              {internalState.buttonText}
            </PrimaryButton>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default FeedbackMessage;
