import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const First = (props) => (
  <SvgIcon {...props} viewBox="0 0 12 14">
    <svg width={12} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.962 7.778a1 1 0 0 1 0-1.556l4.91-3.968a1 1 0 0 1 1.628.778v7.936a1 1 0 0 1-1.629.778L2.962 7.778Z"
        fill="currentColor"
      />
      <rect
        x={2}
        y={12}
        width={2}
        height={10}
        rx={1}
        transform="rotate(-180 2 12)"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default First;
