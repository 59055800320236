import React, { forwardRef, useEffect } from 'react';

import { Dialog as MUIDialog, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CloseButton from 'components/atoms/Buttons/CloseButton';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  paper: {
    margin: 0,
    maxHeight: '90vh',
    position: 'relative',
    overflow: 'visible',
    padding: '4rem',
  },
  thin: {
    padding: 0,
  },
}));

const DialogCloseButton = styled((props) => <CloseButton {...props} />)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`;

const PaperContainer = forwardRef(({ children, disabled, ...props }, ref) => {
  const classes = useStyles();
  return (
    <Paper
      ref={ref}
      {...props}
      className={clsx(props.className, classes.paper)}
    >
      {children}
      <DialogCloseButton onClick={props.onClose} disabled={disabled} />
    </Paper>
  );
});

const Dialog = ({ children, open = false, disabled, PaperProps, ...props }) => {
  useEffect(() => {
    if (open) {
      const root = document.documentElement;
      root.style.overflow = 'hidden';
    } else {
      const root = document.documentElement;
      root.style.removeProperty('overflow');
    }
    return () => {
      const root = document.documentElement;
      root.style.removeProperty('overflow');
    };
  }, [open]);

  return (
    <MUIDialog
      PaperComponent={PaperContainer}
      PaperProps={{ onClose: props.onClose, disabled, ...PaperProps }}
      open={open}
      {...props}
      onClose={disabled ? null : props.onClose}
      disableEscapeKeyDown={disabled}
    >
      {children}
    </MUIDialog>
  );
};

export const ThinDialog = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Dialog
      {...props}
      PaperProps={{
        ...props.PaperProps,
        className: clsx(classes.thin, props.PaperProps?.className),
      }}
    >
      {children}
    </Dialog>
  );
};

export default Dialog;
