export const roles = {
  Owner: {
    id: 'OWN',
    label: 'Owner',
    priority: 3,
  },
  Manager: {
    id: 'MNG',
    label: 'Manager',
    priority: 2,
  },
  Member: {
    id: 'MBR',
    label: 'Member',
    priority: 1,
  },
};

export default roles;

const mustHaveAtLeast1Team = ({ teamCount }) => teamCount > 1;
const ableToPerformActions = ({ activeTeamInDeletion }) =>
  !activeTeamInDeletion;

export const permissions = {
  Owner: {
    members: {
      canRemove: (props) => ableToPerformActions(props),
      canAdd: (props) => ableToPerformActions(props),
      canChangeRoles: (props) => ableToPerformActions(props),
      canTransferOwnership: (props) => ableToPerformActions(props),
    },
    teams: {
      canCreate: () => true,
      canLeave: (props) =>
        ableToPerformActions(props) && mustHaveAtLeast1Team(props),
      canDelete: (props) =>
        ableToPerformActions(props) && mustHaveAtLeast1Team(props),
      canCancelDelete: () => true,
      canViewBilling: () => true,
      canEditBilling: (props) => ableToPerformActions(props),
      canViewActivePlan: () => true,
      canEditData: (props) => ableToPerformActions(props),
      canEditAvatar: (props) => ableToPerformActions(props),
      canViewExtraRequests: () => true,
      canEditExtraRequests: (props) => ableToPerformActions(props),
      canUnsuspend: (props) => ableToPerformActions(props),
      canUpgrade: (props) => ableToPerformActions(props),
      canDowngrade: (props) => ableToPerformActions(props),
      canCancelSubscription: (props) => ableToPerformActions(props),
      canRetryPayment: (props) => ableToPerformActions(props),
    },
    partner: {
      canCreate: (props) => ableToPerformActions(props),
      canEditPartner: (props) => ableToPerformActions(props),
    },
  },
  Manager: {
    members: {
      canRemove: (props) => ableToPerformActions(props),
      canAdd: (props) => ableToPerformActions(props),
      canChangeRoles: (props) => ableToPerformActions(props),
      canTransferOwnership: () => false,
    },
    teams: {
      canCreate: () => true,
      canLeave: (props) => mustHaveAtLeast1Team(props),
      canDelete: () => false,
      canCancelDelete: () => false,
      canViewBilling: () => true,
      canEditBilling: (props) => ableToPerformActions(props),
      canViewActivePlan: () => true,
      canEditData: () => false,
      canEditAvatar: (props) => ableToPerformActions(props),
      canViewExtraRequests: () => true,
      canEditExtraRequests: (props) => ableToPerformActions(props),
      canUnsuspend: (props) => ableToPerformActions(props),
      canUpgrade: (props) => ableToPerformActions(props),
      canDowngrade: (props) => ableToPerformActions(props),
      canCancelSubscription: (props) => ableToPerformActions(props),
      canRetryPayment: (props) => ableToPerformActions(props),
    },
    partner: {
      canCreate: (props) => ableToPerformActions(props),
      canEditPartner: (props) => ableToPerformActions(props),
    },
  },
  Member: {
    members: {
      canRemove: () => false,
      canAdd: () => false,
      canChangeRoles: () => false,
      canTransferOwnership: () => false,
    },
    teams: {
      canCreate: () => true,
      canLeave: (props) => mustHaveAtLeast1Team(props),
      canDelete: () => false,
      canCancelDelete: () => false,
      canViewBilling: () => false,
      canEditBilling: () => false,
      canViewActivePlan: () => true,
      canEditData: () => false,
      canEditAvatar: () => false,
      canViewExtraRequests: () => false,
      canEditExtraRequests: () => false,
      canUnsuspend: () => false,
      canUpgrade: () => false,
      canDowngrade: () => false,
      canCancelSubscription: () => false,
      canRetryPayment: () => false,
    },
    partner: {
      canCreate: (props) => ableToPerformActions(props),
      canEditPartner: (props) => ableToPerformActions(props),
    },
  },
};

export const getPermissions = ({ currRole, currTeam, teamCount }) => {
  const subjectObj = {};
  Object.keys(permissions[currRole]).forEach((subject) => {
    subjectObj[subject] = {};
    Object.keys(permissions[currRole][subject]).forEach((rule) => {
      subjectObj[subject][rule] = permissions[currRole][subject][rule]({
        currRole,
        teamCount,
        activeTeamInDeletion: currTeam.toBeDeleted,
      });
    });
  });
  return subjectObj;
};
