import React, { useMemo, useState } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeContextProvider, useThemeContext } from 'theme/context';

import getTheme from './get_theme';

const ThemeProvider = ({ children }) => {
  const { isDarkMode } = useThemeContext();
  const theme = useMemo(() => getTheme(isDarkMode), [isDarkMode]);

  return (
    <MUIThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </StyledThemeProvider>
    </MUIThemeProvider>
  );
};

export default ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const setLightMode = () => setIsDarkMode(false);
  const setDarkMode = () => setIsDarkMode(true);

  return (
    <ThemeContextProvider value={{ isDarkMode, setLightMode, setDarkMode }}>
      <ThemeProvider>{children}</ThemeProvider>
    </ThemeContextProvider>
  );
};
