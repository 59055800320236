import { useEffect, useState } from 'react';

import { VDX_CORE_API_ENVIRONMENTS } from 'consts';
import capitalize from 'lodash/capitalize';
import { useLocation, matchPath } from 'react-router-dom';
import {
  ACCOUNT_SETTINGS_ROUTE,
  ACCOUNT_TEAM_CREATE_ROUTE,
  TEAM_PAYMENT_ID_ROUTE,
  SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES,
  SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES,
  RESOURCES_FAQ_ROUTE,
} from 'routes/paths';

const exceptions = {};

exceptions[ACCOUNT_SETTINGS_ROUTE] = 'account-settings';
exceptions[ACCOUNT_TEAM_CREATE_ROUTE] = 'account-settings/create-new-team';
VDX_CORE_API_ENVIRONMENTS.forEach((env) => {
  exceptions[
    SERVICES_VDX_CORE_API_DASHBOARD_ENV_ROUTES[env.id]
  ] = `VDX-Core-API/${capitalize(env.id)}`;
  exceptions[
    SERVICES_VDX_CORE_API_CREATE_PARTNER_ENV_ROUTES[env.id]
  ] = `VDX-Core-API/${capitalize(env.id)}/create-partner`;
});
exceptions[RESOURCES_FAQ_ROUTE] = 'frequently-asked-questions';
exceptions[TEAM_PAYMENT_ID_ROUTE] = 'payments/history/payment-#:id';

const extractBreadcrumbs = (path, removeFromHead = 0) => {
  const tokens = path.split('/');
  return tokens
    .filter((i) => i && i.length !== 0)
    .map((i) => i.split('-').join(' '))
    .slice(removeFromHead);
};

const getParams = (matchedRoute, pathname) => {
  const routeTokens = matchedRoute.split('/');
  const pathTokens = pathname.split('/');
  const params = {};
  routeTokens.forEach((key, index) => {
    if (key.includes(':')) {
      params[key] = pathTokens[index];
    }
  });
  return params;
};

const useBreadcrumbs = () => {
  const { pathname } = useLocation();
  const [crumbs, setCrumbs] = useState([]);
  const foundException = Object.keys(exceptions).find((path) =>
    matchPath(pathname, { path, exact: true })
  );
  const params = foundException ? getParams(foundException, pathname) : null;

  const getCrumbs = () => {
    if (foundException) {
      if (params) {
        let excWithParams = exceptions[foundException];
        Object.keys(params).forEach((key) => {
          excWithParams = excWithParams.replace(key, params[key]);
        });
        return extractBreadcrumbs(excWithParams);
      }
      return extractBreadcrumbs(exceptions[foundException]);
    }
    return extractBreadcrumbs(pathname, 1);
  };

  useEffect(() => {
    setCrumbs(getCrumbs());
  }, [pathname]);

  return crumbs;
};

export default useBreadcrumbs;
