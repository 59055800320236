export default {
  /* used on poppers */
  popperShadow: '0 0 15px 0 rgba(0, 0, 0, 0.11)',
  /* used on floating action buttons */
  fabShadow: '0 15px 15px -5px rgba(0, 0, 0, 0.11)',
  /* used on dialogs */
  dialogShadow: '0px 2px 30px rgba(0, 0, 0, 0.15)',
  /* used on cards */
  paperShadow: '0px 4px 15px rgba(0, 0, 0, 0.11)',
  /* used on some progress bars (dashboard only, so far) */
  progressInsetShadow: 'inset 0px 0px 5px rgba(12, 0, 0, 0.25)',
  /* used on the navigation menu, specifically on sub entries list */
  menuInsetShadow: 'inset 0 4px 4px rgba(0, 0, 0, 0.25)',
};
