import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import BriefcaseIcon from 'assets/icons/business/briefcase';
import clsx from 'clsx';

import Avatar from './AvatarCommon';

const useStyles = makeStyles((theme) => ({
  colors: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.primary.main,
  },
}));

const TeamAvatar = forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <Avatar
      {...props}
      className={clsx(props.className, classes.colors)}
      ref={ref}
    >
      <BriefcaseIcon />
    </Avatar>
  );
});

export default TeamAvatar;
