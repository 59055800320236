import React from 'react';

import { Grid as MUIGrid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmptyResultsIllust from 'assets/illustrations/empty_results';
import clsx from 'clsx';
import { Heading5, Body1 } from 'components/atoms';
import { AnimatePresence, motion } from 'framer-motion';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  grid: {
    display: 'grid',
    grid: 'auto-flow dense / repeat(1, 1fr)',
    [theme.breakpoints.up('md')]: {
      grid: 'auto-flow dense / repeat(2, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      grid: 'auto-flow dense / repeat(3, 1fr)',
    },
    [theme.breakpoints.up('xl')]: {
      grid: 'auto-flow dense / repeat(4, 1fr)',
    },
    columnGap: '1rem',
    rowGap: '1rem',
  },
  item: {
    backgroundColor: theme.palette.shades.secondary[200],
    borderRadius: '0.5rem',
    padding: '1rem 2rem 1rem 2rem',
  },
  emptyIllust: {
    margin: 'auto',
    textAlign: 'center',
  },
  emptyTitle: {
    marginTop: '1rem',
    color: theme.palette.shades.secondary[500],
    textTansform: 'capitalize',
  },
  emptyDesc: {
    marginTop: '0.6rem',
    color: theme.palette.shades.secondary[500],
  },
}));

const EmptyResults = () => {
  const classes = useStyles();
  return (
    <motion.div
      key="no-items"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={classes.emptyIllust}
    >
      <EmptyResultsIllust />
      <Heading5 className={classes.emptyTitle}>No results found</Heading5>
      <Body1 className={classes.emptyDesc}>
        We couldn&apos;t find what you are looking for.
      </Body1>
    </motion.div>
  );
};

const MotionItem = motion(MUIGrid);

const CustomGridItem = ({ item, config }) => {
  const classes = useStyles();
  return (
    <MUIGrid container className={classes.item}>
      {config.map((conf) => (
        <MotionItem
          item
          key={conf.keyword}
          style={conf.styles}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {conf.content(item)}
        </MotionItem>
      ))}
    </MUIGrid>
  );
};

const Grid = ({ data = [], config = [], className }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <AnimatePresence>
        <div className={clsx(classes.grid, className)}>
          {data.length > 0 &&
            data.map((item) => (
              <CustomGridItem key={item.id} item={item} config={config} />
            ))}
        </div>
        {data.length === 0 && <EmptyResults />}
      </AnimatePresence>
    </div>
  );
};

export default Grid;
