import React from 'react';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Body1 } from 'components/atoms';
import styled from 'styled-components';

import BasicSwith from './Basic';

const useStyles = makeStyles((theme) => ({
  switch: {
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
    },
    '& .MuiSwitch-colorSecondary.Mui-disabled': {
      color: theme.palette.common.white,
    },
    '& .MuiSwitch-switchBase.Mui-disabled': {
      color: theme.palette.common.white,
    },
  },
}));

const Values = styled(
  ({ className, left, right, value, onChange, ...props }) => {
    const classes = useStyles();
    const handleChange = (checked) => {
      if (onChange) {
        if (checked) onChange(right);
        else onChange(left);
      }
    };
    return (
      <Grid container alignItems="center" spacing={1} className={className}>
        <Grid item>
          <Body1>{left}</Body1>
        </Grid>
        <Grid item>
          <BasicSwith
            on={value === right}
            onChange={handleChange}
            {...props}
            className={clsx(classes.switch, props.className)}
          />
        </Grid>
        <Grid item>
          <Body1>{right}</Body1>
        </Grid>
      </Grid>
    );
  }
)``;

export default Values;
