import { action, createContextStore, actionOn } from 'easy-peasy';
import getPlans from 'services/api/plans/get_plans';
import { getMonthlyPlans, getAnnualPlans, formatPlans } from 'utils/plans';

import fetchThunkHelper from './utils/fetch_thunk_helper';

const model = () => ({
  plans: [] /* all */,
  monthlyPlans: [],
  annualPlans: [],
  planDisplayNames: {},
  interval: {
    monthly: true,
    annual: false,
  },
  active: undefined,
  fetching: true,

  ...fetchThunkHelper('plans', (actions, params, { fail }) =>
    getPlans().catch((err) => fail(err))
  ),
  onGetPlansSuccess: actionOn(
    (actions) => actions.getPlans.successType,
    (state, { result }) => {
      state.plans = formatPlans(result);
      state.fetched = false;
      state.fetching = true;
    }
  ),

  setMonthlyInterval: action((state) => {
    state.interval.monthly = true;
    state.interval.annual = false;
  }),
  setAnnualInterval: action((state) => {
    state.interval.monthly = false;
    state.interval.annual = true;
  }),
  onAfterGetPlans: actionOn(
    (actions) => actions.getPlans.successType,
    (state, { result }) => {
      state.monthlyPlans = getMonthlyPlans(result);
      state.annualPlans = getAnnualPlans(result, state.monthlyPlans);
      state.planDisplayNames = result.reduce((obj, item) => {
        obj[item.name] = item.displayName;
        return obj;
      }, {});
      state.fetched = true;
      state.fetching = false;
    }
  ),
});

export default createContextStore(
  {
    ...model(),
  },
  {
    name: 'Plans',
    devTools: process.env.REACT_APP_REDUX_DEV_TOOLS_ENABLED === 'true',
  }
);
