import localStorage from 'local-storage';

const HAS_VISITED_PAGE = 'has_visited_page';

export const remove = () => localStorage.remove(HAS_VISITED_PAGE);

export const get = (path) => {
  if (!localStorage.get(HAS_VISITED_PAGE)) {
    localStorage.set(HAS_VISITED_PAGE, {});
    return false;
  }
  return localStorage.get(HAS_VISITED_PAGE)[path];
};

export const set = (path, value) =>
  localStorage.set(HAS_VISITED_PAGE, {
    ...(localStorage.get(HAS_VISITED_PAGE) || {}),
    [path]: value,
  });
