import { useEffect } from 'react';

import keycloak from 'auth/keycloak';
import notifications from 'stores/notifications';

let socketClient;
const useNotifications = ({ userId }) => {
  const { notifications: list } = notifications.useStoreState((st) => st);
  const { add, read, readAll } = notifications.useStoreActions((act) => act);

  const connect = () => {
    socketClient = new WebSocket(process.env.REACT_APP_NOTIFICATIONS_WS_URL);
    socketClient.onopen = () => {
      const parameter = {
        userId,
        operation: 'CONNECT',
        token: keycloak.token,
      };
      socketClient.send(JSON.stringify(parameter));
    };
    socketClient.onmessage = (evt) => {
      const message = JSON.parse(evt.data);
      add(message);
    };
    socketClient.onerror = () => {
      // reconnect
      const parameter = {
        userId,
        operation: 'CONNECT',
        token: keycloak.token,
      };
      keycloak.updateToken().then(() => {
        if (socketClient) {
          socketClient.send(JSON.stringify(parameter));
        } else {
          socketClient = new WebSocket(
            process.env.REACT_APP_NOTIFICATIONS_WS_URL
          );
          socketClient.send(JSON.stringify(parameter));
        }
      });
    };
  };

  const markAllAsRead = () => {
    socketClient.send(
      JSON.stringify({
        messageIdList: list.map(({ uuid }) => uuid),
        operation: 'MARKASREADFORALL',
      })
    );
    readAll();
  };

  const markAsRead = (uuid) => {
    socketClient.send(
      JSON.stringify({
        messageIdList: [uuid],
        operation: 'MARKASREADFORALL',
      })
    );
    read(uuid);
  };

  useEffect(() => {
    connect();
    return () => {
      if (socketClient) socketClient.close();
    };
  }, []);

  return { markAllAsRead, markAsRead };
};

export default (props) => {
  if (process.env.NODE_ENV === 'test') {
    return {
      markAllAsRead: () => {
        /* empty */
      },
      markAsRead: () => {
        /* empty */
      },
    };
  }
  return useNotifications(props);
};
