import React, { forwardRef, useEffect, useState } from 'react';

import {
  ClickAwayListener,
  Popper,
  IconButton,
  Badge,
  List,
  ListItem,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BellIcon from 'assets/icons/notifications/bell';
import MoreHorizontalIcon from 'assets/icons/popular/more_horizontal';
import clsx from 'clsx';
import {
  Heading3,
  Heading5,
  TertiaryButton,
  SmallTitle,
  Body1,
} from 'components/atoms';
import {
  usePopupState,
  bindTrigger,
  bindPopper,
} from 'material-ui-popup-state/hooks';
import { NavLink } from 'react-router-dom';
import notifications from 'stores/notifications';
import shadows from 'theme/common/shadows';
import { prettifyIntoRelativeWords } from 'utils/dates';

const useStyles = makeStyles((theme) => ({
  popper: {
    boxShadow: shadows.popperShadow,
    backgroundColor: theme.palette.shades.secondary[100],
    borderRadius: '0.5rem',
    top: '1rem !important',
    padding: '3rem 0.8rem 3rem 1.7rem',
    maxHeight: '70vh',
    width: '40rem',
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    flex: '0 0 auto',
    padding: '0 1.5rem 0 2.2rem',
    marginBottom: '1rem',
  },
  body: {
    flex: '0 1 auto',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  list: {
    '& > *:not(:last-child)': {
      marginBottom: '1rem',
    },
  },
  entry: {
    padding: '1.5rem',
    cursor: 'pointer',
    marginRight: '0.9rem',
    position: 'relative',
    borderRadius: '0.5rem',
  },
  entryHeader: {
    position: 'relative',
  },
  title: {
    maxWidth: '80%',
  },
  more: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  link: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: '15%',
    bottom: 0,
  },
  time: {
    marginTop: '0.3rem',
    textTransform: 'none',
  },
  markAsRead: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '-0.9rem',
    '& .MuiButton-label': {
      ...theme.typography.smallTitle,
      textTransform: 'none',
      top: 0,
    },
  },
  unread: {
    backgroundColor: theme.palette.shades.secondary[200],
    border: `1px solid ${theme.palette.shades.secondary[200]}`,
  },
  read: {
    backgroundColor: theme.palette.shades.secondary[100],
    border: `1px solid ${theme.palette.shades.secondary[300]}`,
  },
  message: {
    marginTop: '1.5rem',
  },
  optionsPopper: {
    boxShadow: shadows.popperShadow,
    backgroundColor: theme.palette.shades.secondary[100],
    borderRadius: '0.5rem',
    top: '1rem !important',
  },
  optionsList: {
    padding: 0,
  },
}));

const MoreOptions = ({ state, uuid, markAsRead }) => {
  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={state.close}>
      <Popper
        {...bindPopper(state)}
        placement="bottom-end"
        transition
        className={classes.optionsPopper}
      >
        <List className={classes.optionsList}>
          <ListItem
            button
            onClick={() => {
              markAsRead(uuid);
              state.close();
            }}
          >
            <Body1>Mark as read</Body1>
          </ListItem>
        </List>
      </Popper>
    </ClickAwayListener>
  );
};

const NotificationEntry = ({
  uuid,
  title,
  subTitle,
  read,
  timestamp,
  markAsRead,
  path,
}) => {
  const classes = useStyles();
  const optionsPopperState = usePopupState({
    variant: 'popper',
    popupId: uuid,
  });
  return (
    <>
      <div
        className={clsx(
          classes.entry,
          read && classes.read,
          !read && classes.unread
        )}
      >
        <div>
          <Grid
            container
            justifyContent="space-between"
            className={classes.entryHeader}
          >
            <Heading5 className={classes.title}>{title}</Heading5>
            <IconButton
              size="small"
              className={classes.more}
              {...bindTrigger(optionsPopperState)}
              disabled={read}
            >
              <MoreHorizontalIcon />
            </IconButton>
          </Grid>
          <SmallTitle className={classes.time}>
            {prettifyIntoRelativeWords(timestamp)}
          </SmallTitle>
          <Body1 className={classes.message}>{subTitle}</Body1>
        </div>
        <NavLink to={path} className={classes.link} />
      </div>
      {optionsPopperState.isOpen && (
        <MoreOptions
          state={optionsPopperState}
          markAsRead={markAsRead}
          uuid={uuid}
        />
      )}
    </>
  );
};

const NotificationsPopper = ({ state, markAsRead, markAllAsRead }) => {
  const classes = useStyles();
  const { notifications: list, unreadCount } = notifications.useStoreState(
    (st) => st
  );
  return (
    <ClickAwayListener onClickAway={state.close}>
      <Popper
        {...bindPopper(state)}
        placement="bottom-end"
        transition
        className={classes.popper}
      >
        <div className={classes.container}>
          <div className={classes.header}>
            <Heading3>Notifications</Heading3>
            <div className={classes.markAsRead}>
              <TertiaryButton disabled={!unreadCount} onClick={markAllAsRead}>
                Mark all as read
              </TertiaryButton>
            </div>
          </div>
          <div className={classes.body}>
            <div className={classes.list}>
              {list.map((notif) => (
                <NotificationEntry {...notif} markAsRead={markAsRead} />
              ))}
            </div>
          </div>
        </div>
      </Popper>
    </ClickAwayListener>
  );
};

const Notifications = forwardRef(({ notificationsActions, ...props }, ref) => {
  const popperState = usePopupState({
    variant: 'popper',
    popupId: 'notificationsPopper',
  });
  const { unreadCount, notifications: list } = notifications.useStoreState(
    (st) => st
  );
  const [displayBadge, setDisplayBadge] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => setDisplayBadge(true), 1000);
    return () => clearTimeout(t);
  }, []);

  return (
    <>
      <IconButton {...props} {...bindTrigger(popperState)} ref={ref}>
        <Badge
          badgeContent={<Body1>{unreadCount}</Body1>}
          color="error"
          invisible={!displayBadge || !unreadCount}
          overlap="rectangular"
        >
          <BellIcon />
        </Badge>
      </IconButton>
      {popperState.isOpen && list.length > 0 && displayBadge && (
        <NotificationsPopper state={popperState} {...notificationsActions} />
      )}
    </>
  );
});

export default Notifications;
