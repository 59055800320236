import {
  format,
  parseISO,
  formatDistanceToNow,
  differenceInDays,
} from 'date-fns';
import { isEqual } from 'lodash';
import isString from 'lodash/isString';

export const parseDate = (date) => (isString(date) && parseISO(date)) || date;

export const prettifyDate = (date) =>
  date && format(parseDate(date), 'do MMMM yyyy');

export const prettifyTimestamp = (timestamp) =>
  timestamp && format(parseDate(timestamp), 'do MMMM yyyy, h:mm aaa');

export const prettifyShortDate = (datetime) =>
  datetime && format(parseDate(datetime), 'MMM d, yyyy');

export const prettifyIntoRelativeWords = (date) =>
  date && formatDistanceToNow(date);

export const prettifyShortMonthYearDate = (date) =>
  date && format(parseDate(date), 'MMM yyyy');

export const daysUntil = (date1) =>
  date1 && differenceInDays(parseDate(date1), parseDate(new Date()));

export const isSameDate = (date1, date2) =>
  (date1 && date2 && isEqual(parseDate(date1), parseDate(date2))) || false;
