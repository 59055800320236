import httpClient from '../client';
import local from '../client/local';

export default async (params) => {
  try {
    const page = params?.page || 1;

    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(`/api/teams/?page=${page}`);
    } else {
      response = await httpClient.get(`/api/teams/?page=${page}`);
    }

    return {
      count: response.count,
      items: response.items.map((item) => ({
        id: item.id,
        name: item.name,
        country: item.country,
        photo: item.photo,
        allowExtraRequests: item.allow_extra_requests,
        extraRequestsLimit: item.extra_requests_limit,
        members: item.members.map((memb) => ({
          firstName: memb.first_name,
          lastName: memb.last_name,
          email: memb.email,
          role: memb.role.name,
          id: memb.id,
          photo: memb.photo,
        })),
        toBeDeleted: item.to_be_deleted,
        deleteTeam: {
          createdAt: item.deleteteam?.created_at,
          scheduledDeleteDate: item.deleteteam?.scheduled_delete_date,
        },
        hasUnpaidRequests: item.has_unpaid_requests,
      })),
    };
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
