import httpClient from '../client';
import local from '../client/local';

export default async () => {
  try {
    let response;
    if (process.env.NODE_ENV === 'local') {
      response = await local.get(`/api/notification_types/`);
    } else {
      response = await httpClient.get(`/api/notification_types/`);
    }

    return response.items.map((entry) => ({
      key: entry.name,
      label: entry.display_name,
    }));
  } catch (error) {
    if (error.data) return Promise.reject(error.data);
    return Promise.reject(error);
  }
};
