import React from 'react';

import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const Body1 = styled(({ className, children, ...props }) => (
  <Typography variant="body1" className={className} {...props}>
    {children}
  </Typography>
))``;

export default Body1;
